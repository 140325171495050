import { connect } from 'react-redux';

import CausaAction from "../../actions/CausaAction";

import CausaNewComponent from '../../components/Causa/CausaNew';

const mapStateToProps = (state) => {
  return {
    item: state.lwo_causa.item,
    formData: state.lwo_causa.formData,
    mainTab: state.lwo_causa.mainTab
  }
}
const mapDispatchToProps = (dispatch) => {
  let action = new CausaAction();
  return {
    initialize: (params) => {
      dispatch(action.initialize(params));
    },
    setFormData: (formData) => {
      dispatch(action.setFormData(formData));
    },
    setMainTab: (index) => {
      dispatch(action.setMainTab(index));
    },
    setItem: (object) => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: (id) => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.createCustomerPost(postData, ownProps));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CausaNewComponent);