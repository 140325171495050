import React from 'react'
import { connect } from 'react-redux'
import Clock from 'components/Home/Clock'

import indexImg from 'index.png'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid
} from '@material-ui/core'

import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
	DeleteForever as DeleteForeverIcon,
	Assignment as AssignmentIcon, CenterFocusStrong
} from '@material-ui/icons'
// import classes from '*.module.css';

import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  wrapSx: {
    textAlign: 'center'
  },
  title: {
    // textAlign: 'center'
    // marginBottom: 48
  },
  homeCard: {
    // backgroundColor: theme.palette.secondary.main,
    marginBottom: '24px',
    '& .button': {
      display: 'flex',
      textAlign: 'center',
      width: '100%',
      color: theme.palette.primary.main,
      fontWeight: 700,
      // color: theme.palette.white.main,
      '& .MuiButton-label': {
        flexDirection: 'column',
        // justifyContent: 'space-between'
      },
      [theme.breakpoints.down('md')]: {
        // fontSize: 16,
        // height: 'calc(50vw - 30px)',
        height: 120,
      },
      [theme.breakpoints.up('md')]: {
        // fontSize: 22,
        // height: 'calc(30vw - 90px)',
        height: 120,
      }
    },
  },
}), 'Home')

const Home = props => {
  const classes = useStyles()

  return(
    <React.Fragment>
      <div className="page-title">
        Home
      </div>
      {/* <p>
        <Clock />
      </p> */}

      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <div style={{marginBottom: 48}}>
            <h1 className={classes.title}>Benvenuto nel portale clienti</h1>
            <p>
              Puoi gestire le tue pratiche in un unico posto.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={3} style={{textAlign: 'right'}}>
          <Clock />
        </Grid>
      </Grid>


      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <h3>Contravvenzioni</h3>

          <Card className={classes.homeCard}>
            <CardActions>
              <Button size="small" className="button" color="primary" href={`${process.env.REACT_APP_URL}/lawyer-one/contravvenzioni?archived=0`}>
                <AssignmentIcon />
                Contravvenzioni
              </Button>
            </CardActions>
          </Card>

          <Card className={classes.homeCard}>
            <CardActions>
            <Button 
                size="small" 
                className="button" 
                color="primary" 
                href={`${process.env.REACT_APP_URL}/lawyer-one/contravvenzioni/0?archived=0`}
              >
                <AddCircleIcon />
                Carica contravvenzione
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <h3>Cause</h3>

          <Card className={classes.homeCard}>
            <CardActions>
              <Button size="small" className="button" color="primary" href={`${process.env.REACT_APP_URL}/lawyer-one/cause?archived=0`}>
                <AssignmentIcon />
                Cause
              </Button>
            </CardActions>
          </Card>

          <Card className={classes.homeCard}>
            <CardActions>
              <Button 
                size="small" 
                className="button" 
                color="primary" 
                href={`${process.env.REACT_APP_URL}/lawyer-one/cause/0?archived=0`}
              >
                <AddCircleIcon />
                Carica causa
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>


      {/* <Grid container spacing={3}>
        <Grid item md={6}>
          
        </Grid>
        <Grid item md={6}>
          <div style={{textAlign: 'center', marginTop: '48px'}}>
            <img src={indexImg} style={{maxWidth: '100%'}}/>
          </div>
        </Grid>
      </Grid> */}

      
      
		</React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);