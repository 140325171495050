import React from 'react';
import { connect } from 'react-redux';
import UserAction from 'packages/User/actions/UserAction';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import { appConfig } from 'config/appConfig';

// import SidebarPostTypes from './SidebarPostTypes';


import {
  Home as HomeIcon,
  AccountBox as AccountBoxIcon,
  AccountCircle,
  ArrowForwardIos as ArrowForwardIosIcon,
  Settings as SettingsIcon,
  Person as PersonIcon,
  SupervisorAccount as SupervisorAccountIcon,
  NavigateNext as NavigateNextIcon,
  Explore as ExploreIcon,
  Assignment as AssignmentIcon
} from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  userBoxButton: {
    borderRadius: '4px'
  },
  userBox: {
    display: 'flex',
    alignItems: 'center',
    height: '200px',
    color: '#fff',
    background: theme.palette.primary.main,
  },
  userBoxContent: {
    textAlign: 'left'
  }
}));


function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}


const Sidebar = (props) => {
  const classes = useStyles();

  const [userMenu, setUserMenu] = React.useState(false);
  const [settingsMenu, setSettingsMenu] = React.useState(false);
  const [postMenu, setPostMenu] = React.useState(false);
  const [lawyerContravvenzioniMenu, setLawyerContravvenzioniMenu] = React.useState(false);
  const [lawyerCauseMenu, setLawyerCauseMenu] = React.useState(false);

  const handleClick = (state, fState) => {
    eval(fState)(!state);
  };

  const sideList = (props) => (
    <div
      className={classes.list}
      role="presentation"
      // onClick={() => props.toggleSidebar(false)}
      // onKeyDown={() => props.toggleSidebar(false)}
    >

      <div className={classes.userBox}>
        { props.authenticated &&
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            className={classes.userBoxButton}
          >
            <div className={classes.userBoxContent}>
              <AccountCircle />
              <Typography variant="subtitle2" gutterBottom>
                {props.user && (props.user.first_name + ' ' + props.user.last_name)}
                <br/>
                {props.user && props.user.role && props.user.role.name}
                <br/>
                {props.user && props.user.email}
              </Typography>
            </div>
          </IconButton>
        }
      </div>

      <Divider />

      <ListItemLink href={`${process.env.REACT_APP_URL}/home`}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemLink>

      <ListItem button onClick={(state, fState) => handleClick(lawyerContravvenzioniMenu, 'setLawyerContravvenzioniMenu')}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Contravvenzioni" />
        {lawyerContravvenzioniMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={lawyerContravvenzioniMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink href={`${process.env.REACT_APP_URL}/lawyer-one/contravvenzioni?archived=0`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Elenco" />
          </ListItemLink>
          <ListItemLink href={`${process.env.REACT_APP_URL}/lawyer-one/contravvenzioni?archived=1`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Archivio" />
          </ListItemLink>
          <ListItemLink href={`${process.env.REACT_APP_URL}/lawyer-one/contravvenzioni/0?archived=0`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Nuova" />
          </ListItemLink>
        </List>
      </Collapse>

      <ListItem button onClick={(state, fState) => handleClick(lawyerCauseMenu, 'setLawyerCauseMenu')}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Cause" />
        {lawyerCauseMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={lawyerCauseMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink href={`${process.env.REACT_APP_URL}/lawyer-one/cause?archived=0`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Elenco" />
          </ListItemLink>
          <ListItemLink href={`${process.env.REACT_APP_URL}/lawyer-one/cause?archived=1`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Archivio" />
          </ListItemLink>
          <ListItemLink href={`${process.env.REACT_APP_URL}/lawyer-one/cause/0?archived=0`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Nuova" />
          </ListItemLink>
        </List>
      </Collapse>

      <Divider />

      {/* <SidebarPostTypes /> */}

      {/* <ListItem button onClick={(state, fState) => handleClick(postMenu, 'setPostMenu')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Imp. Web" />
        {postMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={postMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink href={`${process.env.REACT_APP_URL}/post/sliders`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Slider" />
          </ListItemLink>
          <ListItemLink href={`${process.env.REACT_APP_URL}/post/menu`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Menu" />
          </ListItemLink> 
          <ListItemLink href={`${process.env.REACT_APP_URL}/post/widgets`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Widget" />
          </ListItemLink> 
        </List>
      </Collapse>

      <Divider /> */}

      {props.user && props.user.roles && props.user.roles[0] && (props.user.roles[0].name == 'Super Admin' || props.user.roles[0].name == 'Admin') &&
      <>
      <ListItem button onClick={(state, fState) => handleClick(userMenu, 'setUserMenu')}>
        <ListItemIcon>
          <SupervisorAccountIcon />
        </ListItemIcon>
        <ListItemText primary="Utenti" />
        {userMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={userMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink href={`${process.env.REACT_APP_URL}/lawyer-one/customers`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Anagrafiche" />
          </ListItemLink>
          <ListItemLink href={`${process.env.REACT_APP_URL}/user/users`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Account Utenti" />
          </ListItemLink>
          <ListItemLink href={`${process.env.REACT_APP_URL}/user/roles`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Ruoli" />
          </ListItemLink>
          <ListItemLink href={`${process.env.REACT_APP_URL}/user/permissions`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Permessi" />
          </ListItemLink>
        </List>
      </Collapse>
      </>
      }

      {props.user && props.user.roles && props.user.roles[0] && props.user.roles[0].name == 'Customer' &&
      <>
        <ListItemLink href={`${process.env.REACT_APP_URL}/user/users/${props.user.id}`}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItemLink>
        {props.currCustomerId > 0 &&
        <ListItemLink href={`${process.env.REACT_APP_URL}/lawyer-one/customers/${props.currCustomerId}`}>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Anagrafica" />
        </ListItemLink>
        }
      </>
      }


      {/* <ListItem button onClick={(state, fState) => handleClick(settingsMenu, 'setSettingsMenu')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Sistema" />
        {settingsMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={settingsMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink href={`${process.env.REACT_APP_URL}/email/emails`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Email" />
          </ListItemLink>
          { appConfig.multisite &&
          <ListItemLink href={`${process.env.REACT_APP_URL}/website/websites`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Webiste" />
          </ListItemLink>
          }
          <ListItemLink href={`${process.env.REACT_APP_URL}/locales`} className={classes.nested}>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary="Locale" />
          </ListItemLink>
        </List>
      </Collapse> */}

      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> 
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <ArrowForwardIosIcon /> : <ArrowForwardIosIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>*/}
    </div>
  );

  return (
    <React.Fragment>
      <Drawer open={props.side} onClose={() => props.toggleSidebar(false)}>
        {sideList(props)}
      </Drawer>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    currCustomerId: state.lwo_init.currCustomerId
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      let action = new UserAction();
      dispatch( action.logout() );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);