import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField
} from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    marginTop: 8,
    marginBottom: 4,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const renderOptions = (props) => {
  let menuItems = [];
  
  if (props.placeholder) {
    menuItems.push(
      <MenuItem key={-1} value="">-</MenuItem>
    );	
  }
  
  if (props.data) {
    let optionKey = (props.dataOptions && props.dataOptions.dataKey) ? props.dataOptions.dataKey : 'key';
    let optionValue = (props.dataOptions && props.dataOptions.dataValue) ? props.dataOptions.dataValue : 'value';
    let isObject = (props.dataOptions && props.dataOptions.isObject) ? props.dataOptions.isObject : null;
    
    if( !isObject && typeof props.dataOptions === 'undefined' || props.dataOptions == null || props.dataOptions.length <= 0) {
      for (let i in props.data) {
        menuItems.push(
          <MenuItem key={props.data[i]} value={props.data[i]}>{props.data[i]}</MenuItem>
        );	
      }
    }
    // else if( isObject && typeof props.dataOptions === 'undefined' || props.dataOptions == null || props.dataOptions.length <= 0) {
    else if( isObject ) {
      for (let [key, value] of Object.entries(props.data)) {
        menuItems.push(
          <MenuItem key={key} value={key}>{value}</MenuItem>
        );	
      }
    }
    else {
      props.data.map((option, index) => {
        menuItems.push(
          <MenuItem key={index} value={option[optionKey]}>{option[optionValue]}</MenuItem>
        );
      });
    }
  }

  return menuItems;
}


const SelectComponent = (props) => {
  const classes = useStyles(props);
  // const ref = useRef();
  
  const [state, setState] = React.useState({
    value: props.value || "",
    updated: false,
  });
  
  // React.useEffect(() => {
  //   setState({
  //     value: props.value || "",
  //     updated: false
  //   });
  // });

  console.log('SELECT STATE', state);

  const handleChange = event => {
    event.persist();
    setState({
      value: event.target.value,
      updated: true
    });
    props.onChange(event);
  }

  // let optionKey = (props.dataOptions && props.dataOptions.dataKey) ? props.dataOptions.dataKey : 'key';
  // let inputProps = {};
  // inputProps[optionKey] = state.updated ? state.value : props.value;

  return(
    <FormControl 
      className={classes.formControl}
      style={{width: (props.fullWidth) ? '100%' : 'auto'}}
      >
      <InputLabel id={'labelFor_' + props.id}>{props.label}</InputLabel>
      <Select
        labelId={'labelFor_' + props.id}
        id={props.id || ""}
        name={props.name || ""}
        value={state.updated ? state.value : props.value}
        // value={state.value}
        onChange={(e) => handleChange(e)}
        // inputProps={inputProps}
      >
        {renderOptions(props)}
      </Select>
    </FormControl>

    // <TextField
    //   id={props.id || ""}
    //   name={props.id || ""}
    //   select
    //   label={props.label}
    //   value={state.updated ? state.value : props.value}
    //   onChange={(e)=>this.handleChange(e)}
    //   // helperText="Categoria"
    //   margin="dense"
    //   fullWidth
    // >
    //   {renderOptions(props)}
    // </TextField>
  );
}

export default SelectComponent;