import React from "react";

import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField
} from '@material-ui/core';

import { 
  Save as SaveIcon, 
  Cancel as CancelIcon
} from '@material-ui/icons';

import SimpleReactValidator from 'simple-react-validator';

import RevisionComponent from 'components/UI/RevisionComponent';

import RolePermissions from '../../containers/Role/RolePermissions';

class RoleDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemId: this.props.match.params.itemId,
      path: `${process.env.REACT_APP_URL}/user/roles`
    };

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
			element: message => <div className="text-danger">{message}</div>,
			messages: {
        default: "campo obbligatorio"
			}
    });
  }

  componentDidMount() {
    this.props.initialize();

		if (this.state.itemId > 0) 
			this.axiosGetItem();
		else 
			this.resetItem();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({itemId: nextProps.item.id});
  }

  /**
   * Axios
   */
  resetItem() {
    this.props.setItem({});
    window.scroll(0, 0);
  }

  axiosGetItem() {
    this.props.load(this.state.itemId);
    window.scroll(0, 0);
  }

  axiosCreateItem() {
    this.props.create(this.props.item, this.props);
    window.scroll(0, 0);
  }

  axiosUpdateItem() {
    this.props.update(this.props.item.id, this.props.item, this.props);
    window.scroll(0, 0);
  }

  /**
   * Handle
   */
  handleChange(e) {
    let item = Object.assign({}, this.props.item);
    switch (e.target.name) {
      default:
        item[e.target.name] = e.target.value;
        break;
    }
    this.props.setItem(item);
  }

  handleSubmit(e) {
    e.preventDefault();

    if( !this.validator.allValid() ) {
      this.validator.showMessages();
      return false;
    }

    if (parseInt(this.state.itemId) > 0)
      this.axiosUpdateItem();
    else 
      this.axiosCreateItem();
    window.scrollTo(0, 0);
  }

  handleRemove(e) {
    if( window.confirm("Sicuro di voler eliminare il numero " + this.state.itemId + "?") ) {
      this.props.remove(this.state.itemId);
      this.props.router.push("/admin" + this.state.path);
    }
  }

  /**
   * Render
   */
  render() {
    return (
      <form
        onSubmit={e => this.handleSubmit(e)}
        className={this.state.displayErrors ? "displayErrors" : ""}
      >
        <div className="page-title">
          {this.state.itemId == 0 ? 'Nuovo Ruolo' : 'Modifica Ruolo' }
        </div>

        <Grid container className="row rowSpacing">
          <Grid item md={6}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Generale - ID:{" "}
                  {this.props.item.id || "0"}
                </Typography>

                <TextField 
                  id=""
                  ref="name"
                  id="name"
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  value={this.props.item.name || ""}
                  onChange={e => this.handleChange(e)}
                  onBlur={() => this.validator.showMessageFor("name")}
                  required
                  margin="dense"
                  fullWidth
                />
                {this.validator.message('name', this.props.item.name || '', 'required')}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <RolePermissions />

        { this.props.item && this.props.item.id > 0 &&
        <RevisionComponent
          createdUser={this.props.item.createdUser}
          createdAt={this.props.item.created_at}
          updatedUser={this.props.item.updatedUser}
          updatedAt={this.props.item.updated_at}
          />
        }

        <Grid container>
          <Grid item md={12}>
            <Button variant="contained" color="primary" type="submit">
              <SaveIcon /> Salva
            </Button>
            &nbsp;&nbsp;
            <Button variant="contained" href={this.state.path}>
              <CancelIcon /> Chiudi
            </Button>
          </Grid>
        </Grid>

      </form>
    );
  }
}

export default RoleDetail;
