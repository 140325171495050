import BaseAction from 'packages/Base/actions/BaseAction';
import UserService from '../services/UserService';
import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';
import { identifier } from '@babel/types';

import { useHistory } from "react-router-dom";

export const userConsts = {
	AUTHENTICATION_REQUEST: 'AUTHENTICATION_REQUEST',
	AUTHENTICATED: 'AUTHENTICATED_USER',
	AUTHENTICATED_USER_INFO: 'AUTHENTICATED_USER_INFO',
	UNAUTHENTICATED: 'UNAUTHENTICATED_USER',
	AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
	UNAUTHORIZED: 'UNAUTHORIZED',

	INITIALIZE: 'USER_INITIALIZE',
	RESET: 'USER_RESET',
	SET_SEARCH: 'USER_SET_SEARCH',
	SEARCHED: 'USER_SEARCH',
	LOADED: 'USER_LOADED',
	CREATED: 'USER_CREATED',
	UPDATED: 'USER_UPDATED',
	FIELDS_UPDATED: 'USER_FIELDS_UPDATED',
	REMOVED: 'USER_REMOVED',
	LIST_ERROR: 'USER_LIST_ERROR',
	ITEM_ERROR: 'USER_ITEM_ERROR',
}

export default class UserAction extends BaseAction {

	constructor() {
		super();
		this.service = new UserService();
		this.consts = userConsts;
		this.redirect = `/user/users`; //${process.env.REACT_APP_URL}
	}

	/**
	 * Initialize
	 */
	initialize(params) {
    return (dispatch) => {
      this.service.initialize(params)
      .then(response => {
        dispatch({
          type: this.consts.INITIALIZE,
          roles: response.data.roles,
          permissions: response.data.permissions,
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }

	/**
	 * Login 
	 */

	authSuccess(response) {
		return {
				type: this.consts.AUTHENTICATED,
				access_token: response.data.access_token,
				user: response.data.user
		};
	}
	
	authError(message) {
		return {
				type: this.consts.AUTHENTICATION_ERROR,
				message: message
		}
	}
	
	authUser(user) {
		return {
				type: this.consts.AUTHENTICATED_USER_INFO,
				user: user
		};
	}
	
	noauthSuccess() {
		return {
				type: this.consts.UNAUTHENTICATED,
				access_token: null,
				user: null
		};
	}

	checkAuthentication() {
		let now = new Date();
		let nowTimestamp = Math.round(now.getTime() / 1000);
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
		let access_token = localStorage.getItem('access_token') ? true : false;
		let access_expires = localStorage.getItem('access_expires') ? parseInt(localStorage.getItem('access_expires')) : null;
		// console.log('AUTH TOKEN checkAuthentication', access_token, access_expires, nowTimestamp);

		let authenticated = access_token;
		if( access_token && access_expires && nowTimestamp < access_expires )
			authenticated = true;
		else {
			authenticated = false;
			user = null;
		}

		return {
			authenticated,
			user
		}
	}
	
	login(credentials, ownProps) {
		return (dispatch) => {
			let service = new UserService();
			service.login(credentials)
			.then( response => {
				if( response.data.error && response.data.error !== '' ) {
					dispatch(AlertMessageActions.alertMessageError('Email e/o password non validi!'));
					dispatch( this.authError('Errore servizio') );
				}
				else {
					console.log('ownProps', ownProps)
					// return

					localStorage.setItem('access_token', response.data.access_token);
					localStorage.setItem('access_expires', response.data.expires_at);
					localStorage.setItem('user', JSON.stringify(response.data.user));
					dispatch(AlertMessageActions.alertMessageSuccess('Accesso effettuato con successo!'));
					dispatch(this.authSuccess(response));
					let check = this.checkAuthentication();

					// let history = useHistory();
					if( check.authenticated ) {
						let url = `/home`
						if( ownProps.location && ownProps.location.state && ownProps.location.state.from)
							url = `${ownProps.location.state.from.pathname}${ownProps.location.state.from.search}`;
						ownProps.history.replace(url);
						// window.location = `${process.env.REACT_APP_URL}/home`;
					}
					else
						ownProps.history.replace("/signin");
						// window.location = `${process.env.REACT_APP_URL}/signin`;
						// ownProps.history.push(`${process.env.REACT_APP_URL}/signin`);
				}
			})
			.catch( error => {
				console.log('Errore di autenticazione', error);
				let _message = "Email e/o password non validi!";
				// if( error.data.error !== 'Unauthorised' )
				// 	_message = error.data.error;
				dispatch(AlertMessageActions.alertMessageError(_message));
				dispatch( this.authError('Errore servizio') );
			});
		}
	}
	
	getAuthUser() {
		return (dispatch) => {
			UserService.user()
			.then( response => {
				localStorage.setItem('user', JSON.stringify(response.data));
				// sessionStorage.setItem('user', response.data);
				dispatch(this.authUser(response.data));
			});
		}
	}
	
	logout() {
		return (dispatch) => {
			let service = new UserService();
			service.logout()
			.then(response => {
				localStorage.removeItem('access_token');
				localStorage.removeItem('access_expire');
				localStorage.removeItem('user');
				dispatch(this.noauthSuccess());
				dispatch(AlertMessageActions.alertMessageSuccess('Logout eseguito con successo!'));
			})
			.catch(error => {
				localStorage.removeItem('access_token');
				localStorage.removeItem('access_expire');
				localStorage.removeItem('user');
				dispatch(this.noauthSuccess());
				dispatch(AlertMessageActions.alertMessageError('Errore durante il Logout!'));
			});
			
		}
	}



	signin(params) {
		return (dispatch) => {
			// dispatch(PageLoaderActions.show());
			let service = new UserService();
			service.signin(params)
			.then( response => {
				// dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
			})
			.catch( error => {
				// dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	signinWithConfirmation(params) {
		return (dispatch) => {
			// dispatch(PageLoaderActions.show());
			let service = new UserService();
			service.signinWithConfirmation(params)
			.then( response => {
				// dispatch(PageLoaderActions.hide());
				// dispatch({
				// 	type: this.consts.SIGNIN,
				// 	user: response.data.data,
				// 	nextStep: 1
				// });
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
			})
			.catch( error => {
				// dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}



	/**
	 * Password Reset 
	 */
	// passwordReset_sendEmail(email, ownProps) {
	// 	return (dispatch) => {
	// 		dispatch(PageLoaderActions.show());

	// 		let service = new UserService();
	// 		service.passwordReset_sendEmail(email)
	// 		.then( response => {
	// 			dispatch(AlertMessageActions.alertMessageError(response.data.message));
	// 			dispatch(PageLoaderActions.hide());
	// 			return {
	// 				type: this.consts.RESETPASSWORD_SENDEMAIL
	// 			};
	// 		})
	// 		.catch( error => {
	// 			dispatch(AlertMessageActions.alertMessageError(error.data.message));
	// 			dispatch(PageLoaderActions.hide());
	// 		});
	// 	}
	// }
}