import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import alertMessage from './reducers/AlertMessageReducer';
import pageLoader from './reducers/PageLoaderReducer';
import media from './packages/Base/reducers/MediaReducer';
import auth from './packages/User/reducers/AuthReducer';
import role from './packages/User/reducers/RoleReducer';
import permission from './packages/User/reducers/PermissionReducer';
import user from './packages/User/reducers/UserReducer';

import lwo_init from './packages/LawyerOne/reducers/InitReducer';
import lwo_notification from './packages/LawyerOne/reducers/NotificationReducer';
import lwo_signup from './packages/LawyerOne/reducers/SignupReducer';
import lwo_customer from './packages/LawyerOne/reducers/CustomerReducer';
import lwo_contravvenzione from './packages/LawyerOne/reducers/ContravvenzioneReducer';
import lwo_causa from './packages/LawyerOne/reducers/CausaReducer';
import lwo_practice_record from './packages/LawyerOne/reducers/PracticeRecordReducer';
import lwo_practice_conversation from './packages/LawyerOne/reducers/PracticeConversationReducer';

export default createStore(
  combineReducers({
    alertMessage: alertMessage,
    pageLoader: pageLoader,
    media: media,
    auth: auth,
    role: role,
    permission: permission,
    user: user,
    
    lwo_init: lwo_init,
    lwo_notification: lwo_notification,
    lwo_signup: lwo_signup,
    lwo_customer: lwo_customer,
    lwo_contravvenzione: lwo_contravvenzione,
    lwo_causa: lwo_causa,
    lwo_practice_record: lwo_practice_record,
    lwo_practice_conversation: lwo_practice_conversation,
  }),
  {},
  applyMiddleware(reduxThunk)
);