import BaseAction from './BaseAction';
import MediaService from '../services/MediaService';

import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';

export const mediaConsts = {
	SET_SEARCH: 'MEDIA_SET_SEARCH',
	SEARCHED: 'MEDIA_SEARCH',
	LOADING: 'MEDIA_LOADING',	
	LOADED: 'MEDIA_LOADED',
	CREATED: 'MEDIA_CREATED',
	UPDATED: 'MEDIA_UPDATED',
	FIELDS_UPDATED: 'MEDIA_FIELDS_UPDATED',
	REMOVED: 'MEDIA_REMOVED',
	LIST_ERROR: 'MEDIA_LIST_ERROR',
	ITEM_ERROR: 'MEDIA_ITEM_ERROR',
}

export default class MediaAction extends BaseAction {

	constructor() {
		super();
		this.service = new MediaService();
		this.consts = mediaConsts;
		this.redirect = '/media';
	}

	setUrl(url) {
		this.service = new MediaService((url != null) ? url : null);
	}
  
  mediaRemove(itemId, mediaId) {
		return (dispatch) => {
			this.service.mediaRemove(itemId, mediaId)
			.then(response => {
				if (response.data.error == 0) {
					dispatch({
						type: this.consts.REMOVED,
						item: response.data.item
					});
					dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
				} else {
					dispatch(AlertMessageActions.alertMessageError(response.data.message));
				}
	
			}).catch(error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error));
			});
		}
	}

	mediaMultiAll(itemId, params) {
		return (dispatch) => {
			this.service.mediaMultiAll(itemId, params)
			.then(response => {
				dispatch({
					type: this.consts.LOADED,
					collections: response.data.items,
					alert: {
						message: "",
						error: 0
					}
				})
			})
			.catch(error => {
				dispatch({
					type: this.consts.LOADED,
					collections: {
						files: [],
						gallery: []
					},
					alert: {
						message: "Errore: " + error,
						error: 1
					}
				})
			});
		}
	}

	mediaSort(collections) {
		return (dispatch) => {
			this.service.mediaMultiSort(collections)
			.then(response => {
				if (response.data.error == 0) {
					dispatch({
						type: this.consts.REMOVED,
						item: response.data.item
					});
					dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
				} else {
					dispatch(AlertMessageActions.alertMessageError(response.data.message));
				}
	
			}).catch(error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error));
			});
		}
	}
}