import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';

import BaseAction from 'packages/Base/actions/BaseAction';
import PracticeConversationService from '../services/PracticeConversationService';

export const practiceConversationConsts = {
	SET_LOADER: 'PRACTICE_CONVERSATIONS_SET_LOADER',
  INITIALIZE: 'PRACTICE_CONVERSATIONS_INITIALIZE',
	SET_SEARCH: 'PRACTICE_CONVERSATIONS_SET_SEARCH',
	SEARCHED: 'PRACTICE_CONVERSATIONS_SEARCH',
	RESET: 'PRACTICE_CONVERSATIONS_RESET',
	LOADED: 'PRACTICE_CONVERSATIONS_LOADED',
	CREATED: 'PRACTICE_CONVERSATIONS_CREATED',
	UPDATED: 'PRACTICE_CONVERSATIONS_UPDATED',
	FIELDS_UPDATED: 'PRACTICE_CONVERSATIONS_FIELDS_UPDATED',
	REMOVED: 'PRACTICE_CONVERSATIONS_REMOVED',
	// SORT: 'PRACTICE_CONVERSATIONS_SORT',
	// SORTED: 'PRACTICE_CONVERSATIONS_SORTED',
	LIST_ERROR: 'PRACTICE_CONVERSATIONS_LIST_ERROR',
	ITEM_ERROR: 'PRACTICE_CONVERSATIONS_ITEM_ERROR',
	// SYNC_CATEGORIES: 'PRACTICE_CONVERSATIONS_SYNC_CATEGORIES',
	// SYNC_TAGS: 'PRACTICE_CONVERSATIONS_SYNC_TAGS'
}

export default class PracticeConversationAction extends BaseAction {

	constructor() {
		super();
		this.service = new PracticeConversationService();
		this.consts = practiceConversationConsts;
		this.redirect = '/lawyer-one/practice-conversations';
  }

	// initialize() {
	// 	return (dispatch) => {
	// 		this.service.initialize()
	// 		.then(response => {
	// 			dispatch({
  //         type: this.consts.INITIALIZE,
  //         shops: response.data.shops,
  //         locales: response.data.locales,
  //         frontendTypes: response.data.frontend_types
	// 			})
	// 		})
	// 		.catch(error => {
	// 			if( error.response && error.response.data && error.response.data.meta )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
	// 			else if( error.response && error.response.data && error.response.data.message )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
	// 			else
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
	// 		});
	// 	}
	// }

	setLoader(value) {
		return (dispatch) => {
			dispatch({
				type: this.consts.SET_LOADER,
				loader: value,
			});
		}
	}
	
	search( filter, count ) {
		return (dispatch) => {
			dispatch(this.setLoader(true));
			this.service.search(filter, count).then( response => {
				dispatch({
					type: this.consts.SEARCHED,
					items: response.data.data,
					totItems: response.data.meta.total
				});
				dispatch(this.setLoader(false));
			}).catch( error => {
				dispatch(this.setLoader(false));
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}
  
  create( postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service
			.create(postData).then( response => {
				dispatch({
					type: this.consts.CREATED,
					item: response.data.data
        });
        dispatch(this.search({
          conversationable_id: {
            key: 'conversationable_id',
            compare: '=',
            value: response.data.data.conversationable_id
          },
          conversationable_type: {
            key: 'conversationable_type',
            compare: '=',
            value: response.data.data.conversationable_type
          }
        }))
        dispatch(this.setItem({
          id: 0,
          conversationable_id: response.data.data.conversationable_id,
          conversationable_type: response.data.data.conversationable_type,
					description: ""
        }))
				dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
  }
  
  update( id, postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.update(id, postData)
			.then( response => {
				dispatch({
					type: this.consts.UPDATED,
					item: response.data.data,
        });
        dispatch(this.search({
          conversationable_id: {
            key: 'conversationable_id',
            compare: '=',
            value: response.data.data.conversationable_id
          },
          conversationable_type: {
            key: 'conversationable_type',
            compare: '=',
            value: response.data.data.conversationable_type
          }
        }))
        dispatch(this.setItem({
          id: 0,
          conversationable_id: response.data.data.conversationable_id,
          conversationable_type: response.data.data.conversationable_type,
					description: ""
        }))
				dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
  }
  
  removeOnList( id, searchParams, searchCount ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.remove(id)
			.then( response => {
				dispatch({
					type: this.consts.REMOVED,
					// item: response.data.item
				});
				dispatch(this.search(searchParams, searchCount));
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				dispatch(PageLoaderActions.hide());
			});
		}
	}
}