import axios from 'axios';
import qs from 'qs';
import BaseService from 'packages/Base/services/BaseService';

export default class InitService extends BaseService {

  constructor() {
    super('/lawyer-one/init');
    this.api_url = process.env.REACT_APP_API_URL + '/lawyer-one/init';
  }

  init(params) {
    let config = this.checkAuthApi();
    let axiosConfig = {}

    if( params != null ) {
      axiosConfig = {
        params,
        paramsSerializer: function(params) {
          return qs.stringify(params, {
            encode: false
          });
        },
        ...config
      }
    }
    else {
      axiosConfig = {
        ...config
      }
    }

    return axios.get(this.api_url, axiosConfig)
    .then(response => {
      return response;
    }).catch(error => {
      return Promise.reject(error);
    });
  }

}