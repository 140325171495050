import { mediaConsts } from '../actions/MediaAction';

const MediaReducer = (state = {
  collections: {
    files: "",
    gallery: "",
  },
  thumbnails: "",
  alert: {
    message: "",
    error: 0
  }
}, action) => {
switch (action.type) {
  case mediaConsts.LOADED:
    return {
      collections: action.collections,
      alert: action.alert
    }
  case mediaConsts.REMVOED:
    return {
      type: "show",
      display: action.display        
    }
  case mediaConsts.HIDE:
    return {
      type: "hide",
      display: action.display
    }
  default:
    return state;
}
}

export default MediaReducer;