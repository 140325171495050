import { userConsts as consts } from '../actions/UserAction';

let initialState = {
  items: [],
  totItems: 0,
  filter: {
    id: {
      key: 'id',
      compare: '=',
      value: ''
    },
    first_name: {
      key: 'first_name',
      compare: 'like',
      value: ''
    },
    last_name: {
      key: 'last_name',
      compare: 'like',
      value: ''
    },
    email: {
      key: 'email',
      compare: 'like',
      value: ''
    },
    status: {
      key: 'status',
      compare: '=',
      value: ''
    },
    role: {
      key: 'role',
      compare: '=',
      value: ''
    },
    order: ['id', 'asc'],
    page: 1,
    num_per_page: 25,
  },
  item: {},
};

const UserReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case consts.INITIALIZE:
      const defaultSelectValue = {
        text: "-",
        id: ""
      }

      let selectRoles = new Array();
      selectRoles.push(defaultSelectValue);
      for( let i in action.roles ) {
        selectRoles.push({
          text: action.roles[i].name,
          id: action.roles[i].id
        });
      }

      let selectPermissions = new Array();
      selectPermissions.push(defaultSelectValue);
      for( let i in action.permissions ) {
        selectPermissions.push({
          text: action.permissions[i].name,
          id: action.permissions[i].id
        });
      }
      return {
        ...state,
        roles: action.roles,
        selectRoles,
        permissions: action.permissions,
        selectPermissions
      }

    case consts.RESET:
      return {
        ...state,
        item: initialState.item,
      };
    case consts.SET_SEARCH:
      return {
        ...state,
        filter: action.filter
      };
    case consts.SEARCHED:
      return {
        ...state,
        items: action.items,
        totItems: action.totItems
      };
    case consts.LOADED:
      return {
        ...state,
        item: action.item,
      };
    case consts.CREATED:
      return {
        ...state,
        item: action.item,
      };
    case consts.UPDATED:
      return {
        ...state,
        item: action.item,
      };
    case consts.FIELDS_UPDATED:
      return {
        ...state,
        items: action.items,
      };
    case consts.REMOVED:
      return {
        ...state,
        item: initialState.item,
      };
    default:
      return state
  }
}

export default UserReducer;
