import React from 'react';
import { connect } from 'react-redux';

import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/routes';

import ContravvenzioneList from './ContravvenzioneList';
import ContravvenzioneDetail from './ContravvenzioneDetail';
import ContravvenzioneNew from './ContravvenzioneNew';

class ContravvenzionePage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { authenticated } = this.props;
		return(
			<Switch>
				<ProtectedRoute exact path="/lawyer-one/contravvenzioni" authenticated component={ContravvenzioneList} />
				{/* <ProtectedRoute path="/lawyer-one/contravvenzioni/sort" authenticated={authenticated} component={HotelSort}/> */}
        {this.props.user && this.props.user.role && this.props.user.role.id > 2 &&
          <ProtectedRoute path="/lawyer-one/contravvenzioni/0" authenticated component={ContravvenzioneNew}/>
        }
        <ProtectedRoute path="/lawyer-one/contravvenzioni/:itemId" authenticated component={ContravvenzioneDetail}/>
			</Switch>
		);
	}

}

const mapStateToProps = (state) => {
	return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
	}
}

export default connect(mapStateToProps)(ContravvenzionePage);