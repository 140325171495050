import { connect } from 'react-redux';

import CustomerAction from '../../actions/CustomerAction';

import CustomerListComponent from '../../components/Customer/CustomerList';

const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		pageLoader: state.pageLoader.display,
		filter: state.lwo_customer.filter,
		items: state.lwo_customer.items,
    totItems: state.lwo_customer.totItems,
    types: state.lwo_customer.types,
    customers: state.lwo_customer.customers,
    states: state.lwo_customer.states
	}
}
const mapDispatchToProps = (dispatch) => {
  let action = new CustomerAction();
	return {
		initialize: (params) => {
      dispatch(action.initialize(params));
    },
    search: (params) => {
      dispatch(action.search(params));
    },
    setSearch: (params) => {
      dispatch(action.setSearch(params));
    },
    remove: (id, filter, count) => {
      dispatch(action.removeOnList(id, filter, count));
    },
    updateFields: (id, params, filter, count) => {
      dispatch(action.updateFields(id, params, filter, count));
    },
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerListComponent);