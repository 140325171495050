import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  InputLabel,
  TextField,
} from '@material-ui/core';

const editorConfiguration = {
  // plugins: [ Essentials, Paragraph, Bold, Italic, CodeBlock ],
  // plugins: [Essentials],
  // codeBlock: {
  //   languages: [
  //       { language: 'css', label: 'CSS' },
  //       { language: 'html', label: 'HTML' }
  //   ]
  // },
  // toolbar: [ 
  //   'Heading', 'Bold', 'Italic', '|', 
  //   'bulletedList',
  //   'numberedList',
  //   '|',
  //   'outdent',
  //   'indent',
  //   '|',
  //   'Link', 'InsertTable', '|', 
  //   'MediaEmbed',
  //   'blockQuote', 'undo', 'redo'
  // ]
};


const CK5Editor = props => {
  // const [state, setState] = React.useState({
  //   id: props.id,
  //   value: props.value
  // })

  const onChange = ( event, editor ) => {
    // event.preventDefault()
    // setState( {
    //   value: editor.getData()
    // } );

    event.target = {
      name: props.name,
      value: editor.getData()
    }
    
    props.onEditorChange(event);
  }


  return(
    <React.Fragment>
      <InputLabel>{props.label || ""}</InputLabel>
      <CKEditor
        editor={ ClassicEditor }
        data={props.value}
        config={{
          ...editorConfiguration, 
          isReadOnly: props.readOnly || false
        }}
        onChange={( event, editor ) => onChange(event, editor)}
        disabled={props.disabled || false}
      />

      {/* <textarea
        id={props.id}
        name={props.name}
        defaultValue={props.value}
        // onChange={(e)=> {}}
        style={{height: '1px', width: '1px', visibility: 'hidden'}}
      /> */}

    </React.Fragment>
    
  )
}

export default CK5Editor