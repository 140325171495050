import React from 'react';

import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

import {
  Button,
  TextField
} from '@material-ui/core';

import AutocompleteComponent from 'components/UI/Input/AutocompleteComponent';
import SelectComponent from 'components/UI/Input/SelectComponent';

const RenderTableHead = (props) => {
  const orderBy = props.orderBy;
  const order = props.order.toLowerCase();

  const headCells = props.headCells;

  const handleFilterChange = event => {
    event.persist();
    props.onFilterChange(event);
  }

  return(
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <React.Fragment key={headCell.id}>
          { !headCell.isSort && headCell.visible !== false &&
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            >
            {headCell.label}
          </TableCell>
          }
          { headCell.isSort && headCell.visible !== false &&
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            >
            <TableSortLabel
              active={orderBy === headCell.id}
              // direction={orderBy === headCell.id ? order.toLowerCase() : 'asc'}
              // onClick={(orderBy, orderHow) => props.onOrderChange(headCell.id, (order.toUpperCase() == 'ASC') ? 'DESC' : 'ASC')}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={(orderBy, orderHow) => props.onOrderChange(headCell.id, (order == 'asc') ? 'desc' : 'asc')}
              >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                // <span className={classes.visuallyHidden}>
                <span>
                  {order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
          }
          </React.Fragment>
        ))}
      </TableRow>

      {props.renderFilter && 
      <TableRow>
        {headCells.map( (headCell, index) => (
          <React.Fragment key={index}>
            <TableCell key={index}>
              { !headCell.type &&
                <React.Fragment />
              }
              { headCell.type && headCell.type == "text" &&
                <TextField 
                  id={headCell.id}
                  name={headCell.id}
                  label={headCell.label}
                  onChange={handleFilterChange} 
                  defaultValue={ props.filter[headCell.id].value }
                  />
              }
              { headCell.type && headCell.type == "date" &&
                <TextField 
                  id={headCell.id}
                  name={headCell.id}
                  label={headCell.label}
                  type="date"
                  onChange={handleFilterChange} 
                  defaultValue={ props.filter[headCell.id].value }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
              }
              { headCell.type && headCell.type == "select" &&
                <SelectComponent 
                  id={headCell.id}
                  name={headCell.id}
                  label={headCell.label}
                  onChange={handleFilterChange} 
                  value={ props.filter[headCell.id].value }
                  data={headCell.data || []}
                  dataOptions={headCell.dataOptions || []}
                  placeholder={true}
                  />
              }
              { headCell.type && headCell.type == "autocomplete" &&
                <AutocompleteComponent 
                  id={headCell.id}
                  name={headCell.id}
                  label={headCell.label}
                  onChange={handleFilterChange} 
                  value={ props.filter[headCell.id].value }
                  data={headCell.data || []}
                  dataOptions={headCell.dataOptions || []}
                  />
              }
              { headCell.id == "last" &&
                <Button 
                  size="small" 
                  variant="contained" 
                  color="primary"
                  onClick={() => props.onSearch()} 
                  >
                  Cerca
                </Button>
              }
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
      }
    </TableHead>
  );
}

export default RenderTableHead;