import { connect } from 'react-redux';

import UserAction from '../../actions/UserAction';

import UserDetailComponent from '../../components/User/UserDetail';



const mapStateToProps = state => {
  return {
    authUser: state.auth.user,
    item: state.user.item,
    roles: state.user.roles,
    selectRoles: state.user.selectRoles
  };
};
const mapDispatchToProps = dispatch => {
  let action = new UserAction();
  return {
    initialize: (params) => {
			dispatch(action.initialize(params));
		},
    setItem: object => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: id => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.create(postData, ownProps));
    },
    update: (id, postData, ownProps) => {
      dispatch(action.update(id, postData, ownProps));
    },
    remove: id => {
      dispatch(action.remove(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailComponent);