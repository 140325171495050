import React, { Component } from 'react';
import { appConfig } from 'config/appConfig';

import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

const revisions = (props) => {
  let revisions = '';
  if( props.revisions ) {
    let parse_revisions = JSON.parse(props.revisions);
    parse_revisions.reverse()

    for( let i in parse_revisions )
      revisions += parse_revisions[i].data + ': ' + parse_revisions[i].author + '\n';
  }
  return revisions;
}

const RevisionComponent = (props) => {
  // const revisions = revisions(props);

  return(
    <Grid container className="row rowSpacing">
      <Grid item sm={12}>
        <Card>
          <CardContent>
            <Grid container className="row rowSpacing">
              <Grid item sm={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  Revisione
                </Typography>
              </Grid>
              
              
              <Grid item sm={6}>
                {props.createdUser &&
                <div>
                <TextField 
                  id="revision_created_user"
                  name="revision_created_user"
                  label="Creato da"
                  placeholder="Creato da"
                  value={(props.createdUser) ? props.createdUser.first_name + ' ' + props.createdUser.last_name : ''}
                  fullWidth
                  margin="dense"
                  variant={appConfig.inputVariant}
                  disabled
                />
                </div>
                }
              
                <div>
                <TextField 
                  id="revision_created_at"
                  name="revision_created_at"
                  label="Creato il"
                  placeholder="Creato il"
                  value={(props.createdAt) ? props.createdAt : ''}
                  fullWidth
                  margin="dense"
                  variant={appConfig.inputVariant}
                  disabled
                />
                </div>
              </Grid>

              <Grid item sm={6}>
                {props.updatedUser &&
                <div>
                <TextField 
                  id="revision_updated_user"
                  name="revision_updated_user"
                  label="Aggirnato da"
                  placeholder="Aggirnato da"
                  value={(props.updatedUser) ? props.updatedUser.first_name + ' ' + props.updatedUser.last_name : ''}
                  fullWidth
                  margin="dense"
                  variant={appConfig.inputVariant}
                  disabled
                />
                </div>
                }

                <div>
                <TextField 
                  id="revision_updated_at"
                  name="revision_updated_at"
                  label="Aggirnato da"
                  placeholder="Aggirnato il"
                  value={(props.updatedAt) ? props.updatedAt : ''}
                  fullWidth
                  margin="dense"
                  variant={appConfig.inputVariant}
                  disabled
                />
                </div>
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </Grid>
    
  );
}

export default RevisionComponent;