import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Switch } from 'react-router-dom';

// import { createMuiTheme} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles'

import AlertMessageActions from 'actions/AlertMessageActions';

import { AuthRoute, MainRoute, FrontendRoute } from './components/routes';
import Index from './components/Frontend/Index';
import PrivacyPolicy from './components/Frontend/PrivacyPolicy';
import Signup from './packages/LawyerOne/containers/Signup/Signup';
import Home from './components/Home/Home';
import Login from './packages/User/components/Login/Login';
import PasswordReset from './packages/User/components/Login/PasswordReset';
import PasswordResetChange from './packages/User/components/Login/PasswordResetChange';
import RolePage from './packages/User/containers/Role/RolePage';
import PermissionPage from './packages/User/containers/Permission/PermissionPage';
import UserPage from './packages/User/containers/User/UserPage';

import CustomerPage from './packages/LawyerOne/containers/Customer/CustomerPage';
import ContravvenzionePage from './packages/LawyerOne/containers/Contravvenzione/ContravvenzionePage';
import CausaPage from './packages/LawyerOne/containers/Causa/CausaPage';


import theme from './components/Layouts/theme';
import InitAction from './packages/LawyerOne/actions/InitAction';
import NotificationAction from './packages/LawyerOne/actions/NotificationAction';


const App = (props) => {
  const authenticated = props.authenticated;

  React.useEffect(() => {
    if(authenticated) {
      props.lwoInit()
      props.lwoNotifications()
    }
  }, [])

  return(
    <ThemeProvider theme={theme}>
      <Router basename={`${process.env.REACT_APP_URL}/`}>
        <Switch>
          <FrontendRoute exact path="/" component={Index} />
          <FrontendRoute exact path="/privacy-policy" component={PrivacyPolicy} />
          <AuthRoute exact path="/signup" component={Signup} />
          
          {!authenticated && 
            <AuthRoute exact path="/signin" component={Login} authenticated={authenticated} />
          }
          {authenticated && 
            <MainRoute exact path="/signin" component={Home} authenticated={authenticated} />
          }
          
          <AuthRoute exact path="/password/reset" component={PasswordReset} />
          <AuthRoute exact path="/password/reset/change" component={PasswordResetChange} />
          <MainRoute path="/home" component={Home} authenticated={authenticated} />
          <MainRoute path="/user/permissions" component={PermissionPage} authenticated={authenticated} />
          <MainRoute path="/user/roles" component={RolePage} authenticated={authenticated} />
          <MainRoute path="/user/users" component={UserPage} authenticated={authenticated} />

          <MainRoute path="/lawyer-one/customers" component={CustomerPage} authenticated={authenticated} />
          <MainRoute path="/lawyer-one/contravvenzioni" component={ContravvenzionePage} authenticated={authenticated} />
          <MainRoute path="/lawyer-one/cause" component={CausaPage} authenticated={authenticated} />

          {/* <Route exact path="/" component={Login} /> */}
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    // errorMessage: state.auth.message
  }
}
const mapDispatchToProps = (dispatch) => {
  let lwoInitAction = new InitAction()
  let lwoNotificationAction = new NotificationAction()
	return {
    lwoInit: () => {
      dispatch(lwoInitAction.init());
    },
    lwoNotifications: () => {
      dispatch(lwoNotificationAction.index());
    },
    resetAlertMessage: () => {
      dispatch(AlertMessageActions.reset());
    }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);