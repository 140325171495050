import { connect } from 'react-redux';

import PracticeRecordAction from "../actions/PracticeRecordAction";

import PracticeRecordsComponent from '../components/PracticeRecords';

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    items: state.lwo_practice_record.items,
    loader: state.lwo_practice_record.loader,
    filter: state.lwo_practice_record.filter,
    item: state.lwo_practice_record.item,
    practiceRecordTypes: state.lwo_practice_record.types,
  }
}
const mapDispatchToProps = (dispatch) => {
  let action = new PracticeRecordAction();
  return {
    initialize: () => {
      dispatch(action.initialize());
    },
    search: (params) => {
      dispatch(action.search(params));
    },
    setSearch: (params) => {
      dispatch(action.setSearch(params));
    },
    setItems: (items, itemsCount) => {
      dispatch(action.setItems(items, itemsCount));
    },
    setItem: (object) => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: (id) => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.create(postData, ownProps));
    },
    update: (id, postData, ownProps) => {
      dispatch(action.update(id, postData, ownProps));
    },
    removeOnList: (id, filter, count) => {
      dispatch(action.removeOnList(id, filter, count));
    },
    updateFields: (id, params, filter, count) => {
      dispatch(action.updateFields(id, params, filter, count));
    },
    // /**
    //  * Items
    //  */
    // setItemsItem: (object) => {
    //   dispatch(action.setItemsItem(object));
    // },
    // resetItemsItem: () => {
    //   dispatch(action.resetItemsItem());
    // },
    // itemsAddOrUpdate: (id, data) => {
    //   dispatch(action.itemsAddOrUpdate(id, data));
    // },
    // itemsRemove: (id, data) => {
    //   dispatch(action.itemsRemove(id, data));
    // },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeRecordsComponent);