import axios from 'axios';
import qs from 'qs';
import BaseService from 'packages/Base/services/BaseService';

export default class PermissionService extends BaseService {

  constructor() {
    super('/permissions');
    this.api_url = process.env.REACT_APP_API_URL + '/permissions';
  }
}