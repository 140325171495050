import axios from 'axios';
import qs from 'qs';
import BaseService from 'packages/Base/services/BaseService';

export default class CausaService extends BaseService {

  constructor() {
    super('/lawyer-one/cause');
    this.api_url = process.env.REACT_APP_API_URL + '/lawyer-one/cause';
  }

  /**
   * Items
   */
  itemsAddOrUpdate(id, data) {
    let config = this.checkAuthApi('json');
    // data.append('_method', 'PUT');

    return axios.post(this.api_url + '/' + id + '/items', data, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }
  itemsRemove(id, data) {
    let config = this.checkAuthApi('json');
    // data.append('_method', 'PUT');

    return axios.post(this.api_url + '/' + id + '/items/delete', data, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }
}