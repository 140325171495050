import React from "react";

import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField
} from '@material-ui/core';

import { 
  Save as SaveIcon, 
  Cancel as CancelIcon
} from '@material-ui/icons';

import SimpleReactValidator from 'simple-react-validator';
import RevisionComponent from 'components/UI/RevisionComponent';

class UserDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemId: this.props.match.params.itemId,
      path: `${process.env.REACT_APP_URL}/user/users`
    };

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
			element: message => <div className="text-danger">{message}</div>,
			messages: {
        default: "campo obbligatorio",
        email: "email non valida"
			}
    });
  }

  componentDidMount() {
    this.props.initialize();

		if (this.state.itemId > 0) 
			this.axiosGetItem();
		else 
      this.resetItem();
  }
  
  componentWillReceiveProps(nextProps) {
    this.setState({itemId: nextProps.item.id});
  }


  /**
   * Axios
   */
  resetItem() {
    this.props.setItem({});
    window.scroll(0, 0);
  }

  axiosGetItem() {
    this.props.load(this.state.itemId);
    window.scroll(0, 0);
  }

  axiosCreateItem() {
    this.props.create(this.props.item, this.props);
    window.scroll(0, 0);
  }

  axiosUpdateItem() {
    this.props.update(this.props.item.id, this.props.item, this.props);
    window.scroll(0, 0);
  }

  /**
   * Handle
   */
  handleChange(e) {
    let item = Object.assign({}, this.props.item);

    switch (e.target.name) {
      // case "password":
      //   if (e.target.value != "") item[e.target.name] = e.target.value;
      //   break;
      case "role_id":
        for(let i in this.props.roles){
          if( this.props.roles[i].id == e.target.value ){
            item.role_id = e.target.value;
            item.roles = [this.props.roles[i]];
            item.role = this.props.roles[i];
            break;
          }
        }
        break;
      case "status":
        if (e.target.checked) item[e.target.name] = "1";
        else item[e.target.name] = "0";
        break;
      default:
        item[e.target.name] = e.target.value;
        break;
    }
    this.props.setItem(item);
  }

  handleSubmit(e) {
    e.preventDefault();

    if( !this.validator.allValid() ) {
      this.validator.showMessages();
      return false;
    }

    if (parseInt(this.state.itemId) > 0) this.axiosUpdateItem();
    else this.axiosCreateItem();
    window.scrollTo(0, 0);
  }

  handleRemove(e) {
    if( window.confirm("Sicuro di voler eliminare il numero " + this.state.itemId + "?") ) {
      this.props.remove(this.state.itemId);
      this.props.router.push("/admin/users");
    }
  }

  /**
   * Render
   */
  render() {
    let roles = new Array();
    let selectRoles = this.props.roles || [];
    for ( let i in selectRoles ) {
      if( this.props.authUser && this.props.authUser.role && this.props.authUser.role.id !== 1 && selectRoles[i].id === 1 ) {
        continue;
      }
      roles.push(selectRoles[i]);
    }
    
    return (
      <form
        onSubmit={e => this.handleSubmit(e)}
        className={this.state.displayErrors ? "displayErrors" : ""}
      >
        <div className="page-title">
        {this.state.itemId == 0 ? (
          <span>Nuovo Utente</span>
        ) : (
          <span>Modifica Utente</span>
        )}
        </div>


        <Grid container className="row rowSpacing">
          <Grid item md={6}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Generale - ID:{" "}
                  {this.props.item.id || "0"}
                </Typography>

                <TextField 
                  ref="first_name"
                  id="first_name"
                  name="first_name"
                  label="Nome"
                  placeholder="Nome"
                  value={this.props.item.first_name || ""}
                  onChange={e => this.handleChange(e)}
                  onBlur={() => this.validator.showMessageFor("first_name")}
                  required
                  margin="dense"
                  fullWidth
                />
                {this.validator.message('first_name', this.props.item.first_name || '', 'required')}

                <TextField 
                  ref="last_name"
                  id="last_name"
                  name="last_name"
                  label="Cognome"
                  placeholder="Cognome"
                  value={this.props.item.last_name || ""}
                  onChange={e => this.handleChange(e)}
                  onBlur={() => this.validator.showMessageFor("last_name")}
                  required
                  margin="dense"
                  fullWidth
                />
                {this.validator.message('last_name', this.props.item.last_name || '', 'required')}

                <TextField 
                  ref="email"
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  value={this.props.item.email || ""}
                  onChange={e => this.handleChange(e)}
                  onBlur={() => this.validator.showMessageFor("email")}
                  required
                  margin="dense"
                  fullWidth
                />
                {this.validator.message('email', this.props.item.email || '', 'required|email')}
                
                <TextField
                  id="role_id"
                  name="role_id"
                  ref="role_id"
                  select
                  label="Ruolo"
                  value={(this.props.item && this.props.item.role_id ) ? this.props.item.role_id : ''}
                  onChange={(e)=>this.handleChange(e)}
                  onBlur={() => this.validator.showMessageFor('role_id')}
                  margin="dense"
                  fullWidth
                >
                  <MenuItem value="">
                    -
                  </MenuItem>
                  {this.props.roles && this.props.roles.map((obj,index) => (
                    <MenuItem key={index} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </TextField>
                {this.validator.message('role_id', this.props.item.role_id, 'required')}

                <FormControlLabel
                  control={
                    <Checkbox
                      id="status"
                      name="status"
                      checked={this.props.item.status == "1" ? true : false}
                      onChange={e => this.handleChange(e)}
                      value="1"
                    />
                  }
                  label="Attivo?"
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={6}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Autenticazione
                </Typography>

                <TextField 
                  ref="email"
                  type="password"
                  id="password"
                  name="password"
                  label="Nuova password (vuoto per non modificare)"
                  placeholder="Password"
                  value={this.props.item.password || ""}
                  onChange={e => this.handleChange(e)}
                  margin="dense"
                  fullWidth
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        { this.props.item && this.props.item.id > 0 &&
        <RevisionComponent
          createdUser={this.props.item.createdUser}
          createdAt={this.props.item.created_at}
          updatedUser={this.props.item.updatedUser}
          updatedAt={this.props.item.updated_at}
          />
        }

        <Grid container>
          <Grid item md={12}>
            <Button variant="contained" color="primary" type="submit">
              <SaveIcon /> Salva
            </Button>
            &nbsp;&nbsp;
            <Button variant="contained" href={this.state.path}>
              <CancelIcon /> Chiudi
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default UserDetail;
