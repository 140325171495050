import React from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';

import logo from 'logo.svg'

import {
  AppBar,
  Badge,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
} from '@material-ui/core'

import {
  AccountCircle,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  DesktopWindowsTwoTone,
} from '@material-ui/icons'


import { connect } from 'react-redux';
import UserAction from 'packages/User/actions/UserAction';
import NotificationAction from 'packages/LawyerOne/actions/NotificationAction';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  appbar: {
    color: theme.palette.primary.main,
    background: theme.palette.white.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuNotifications: {
    '& .MuiMenu-list': {
      maxHeight: '60vh',
      overflowY: 'scroll',
      '& li': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '0.9rem'
      }
    }
  },
  buttonWithLabel: {
    '& .MuiIconButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .sublabel': {
      fontSize: '0.7rem'
    }
  }
}), {name: 'Header'});


const Header = (props) => {
  const classes = useStyles();
  
  /**
   * Render
   */
  return (
    <div className={classes.root}>
      <AppBar position="relative" className={classes.appbar}>
        <Toolbar>
          <Typography className={classes.title}>
            <a href="/">
            <img src={logo} style={{width: '100px'}} title={process.env.REACT_APP_NAME || 'Material-UI'}/>
            </a>
          </Typography>
          {/* <Typography variant="h6" className={classes.title}>
            {process.env.REACT_APP_NAME || 'Material-UI'}
          </Typography> */}

          <div>
            <IconButton
              href="/signin"
              color="inherit"
              className={classes.buttonWithLabel}
            >
              <AccountCircle />
              <span className="sublabel">accedi</span>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

// export default Header;
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    notifications: state.lwo_notification.notifications,
    errorMessage: state.auth.message,
    alertMessage: state.alertMessage.message
  }
}

const mapDispatchToProps = (dispatch) => {
  let userAction = new UserAction();
  let notificationAction = new NotificationAction();
  return {
    markAsReadNotification: (id) => {
      dispatch( notificationAction.markAsRead(id) );
    },
    logout: () => {
      dispatch( userAction.logout() );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);