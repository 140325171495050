import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Header from './Header';
import Footer from './Footer';
import PageLoader from '../../UI/PageLoader';
import AlertMessage from '../../UI/AlertMessage';

import { GtagScript, GtagNoscript } from 'components/gtag'

const useStyles = makeStyles(theme => ({
  "@global": {
    '.pulsantiera': {
      display: 'flex',
      margin: '0 -6px',
      padding: 0,
      '& li': {
        listStyle: 'none',
        padding: '0 6px',
        marginBottom: 12
      }
    },
  },
  app: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    overflowX: 'hidden',
    // color: theme.palette.white.main,
    // backgroundColor: theme.palette.secondary.main,
    // '&::before': {
    //   display: 'block',
    //   content: '""',
    //   position: 'absolute',
    //   top: '0',
    //   left: '0',
    //   right: '0',
    //   width: '100%',
    //   height: '300px',
    //   background: theme.palette.background.main,
    //   // zIndex: -1
    // }
  },
  main: {
    position: 'relative',
    padding: 0,
    // padding: '3rem 0',
    flexGrow: 1,
  },
}), {name: 'FrontendLayout'});

const FrontendLayout = (props) => {
  const classes = useStyles();

  return(
    <React.Fragment>
      <PageLoader />
      <AlertMessage />
      <div className={classes.app}>
        <Header />
        <main className={classes.main}>
          {props.children}
        </main>
        <Footer/>
      </div>
    </React.Fragment>
  );
}
export default FrontendLayout;