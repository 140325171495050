import { connect } from 'react-redux';

import UserAction from '../../actions/UserAction';

import UserListComponent from '../../components/User/UserList';


const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		authUser: state.auth.user,
		pageLoader: state.pageLoader.display,
		filter: state.user.filter,
		items: state.user.items,
		totItems: state.user.totItems,
		roles: state.user.roles,
		selectRoles: state.user.selectRoles
	}
}
const mapDispatchToProps = (dispatch) => {
  let action = new UserAction();
	return {
		initialize: (params) => {
			dispatch(action.initialize(params));
		},
		search: (params, count) => {
			dispatch(action.search(params, count));
		},
		setSearch: (params) => {
			dispatch(action.setSearch(params));
		},
		remove: (id, searchParams, searchCount) => {
			dispatch(action.removeOnList(id, searchParams, searchCount));
		},
		updateFields: (id, params, searchParams, searchCount) => {
			dispatch(action.updateFields(id, params, searchParams, searchCount));
			// dispatch(search(searchParams, searchCount));
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(UserListComponent);