import { connect } from 'react-redux';

import ContravvenzioneAction from '../../actions/ContravvenzioneAction'

import VerbaleMediaComponent from '../../components/VerbaleMedia';

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    item: state.lwo_contravvenzione.item
  }
}
const mapDispatchToProps = (dispatch) => {
  let action = new ContravvenzioneAction();
  return {
    load: (id) => {
      dispatch(action.load(id));
    },
    mediaSingleUpload: (itemId, postData) => {
      dispatch(action.mediaSingleUpload(itemId, postData));
    },
    mediaRemove: (itemId, mediaId) => {
      dispatch(action.mediaRemove(itemId, mediaId));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerbaleMediaComponent);