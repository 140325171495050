import React from 'react';
import qs from 'qs';
import * as yup from 'yup'
import { useFormik } from 'formik'

import UserService from 'packages/User/services/UserService';

import PageLoader from 'components/UI/PageLoader';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
		alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  messageBox: {
    width: '100%',
    padding: '15px',
    color: 'white',
    textAlihn: 'center',
    fontSize: '0.875rem',
    borderRadius: '4px',
    '&.error': {
      backgroundColor: 'red',
    },
    '&.success': {
      backgroundColor: 'green',
    }
  },
  messageBoxSuccess: {
    backgroundColor: 'green',
  },
  messageBoxError: {
    backgroundColor: 'red',
  }
}));

const getQueryStringValue = ( key, queryString = window.location.search ) => { 
  const values = qs.parse(queryString.replace('?','')); console.log(values);
  return values[key];
};


const PasswordResetChange = (props) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    email: getQueryStringValue('email', props.location.search),
    token: getQueryStringValue('token', props.location.search),
    password: "",
    password2: "",
    message: "",
    code: 200,
    loading: false
  });

  /**
   * Hanlde
   */
  const handleChange = (e) => {
    setState({ 
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = () => {
    let message = "";
    let code = 200;
    if( state.password != state.password2 ){
      message = "le password sono diverse";
      code = 500;
      setState({ 
        ...state,
        message,
        code
      });
      return;
    }
    

    setState({
      ...state,
      loading: true
    });

    let userService = new UserService();
    userService.passwordReset_passwordSend({
      email: state.email,
      password: state.password,
      token: state.token 
    })
    .then(response => {
      setState({
        ...state,
        password: "",
        password2: "",
        message: response.data.message,
        code: 200,
        loading: false
      });
    })
    .catch(error => {
      setState({
        ...state,
        password: "",
        password2: "",
        message: error.response.data.message,
        code: 500,
        loading: false
      });
    });
  }

  /**
   * Validate
   */
  const formik =  useFormik({
    initialValues: {
      password: state.password || "",
      password2: state.password2 || "",
    },
    validationSchema: yup.object().shape({
      password: yup.string().required('Campo non valido'),
      password2: yup.string().required('Campo non valido').oneOf([yup.ref('password'), null], 'Le password non coincidono'),
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  
  return (
    <React.Fragment>
      <PageLoader display={state.loading ? 'block' : 'none'}/>
      
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Inserisci una nuova password
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="password"
            label="Nuova Password"
            name="password"
            type="password"
            autoFocus
            value={formik.values.password}
            onChange={(e)=>{
              handleChange(e)
              formik.handleChange(e)
            }}
            error={formik.errors.password ? true : false}
            helperText={formik.errors.password}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password2"
            label="Conferma Password"
            name="password2"
            type="password"
            value={formik.values.password2}
            onChange={(e)=>{
              handleChange(e)
              formik.handleChange(e)
            }}
            error={formik.errors.password2 ? true : false}
            helperText={formik.errors.password2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
          {state.message && state.message != "" &&
          <Typography component="h1" variant="h5" className={classes.messageBox + ' ' + ((state.code == 200) ? classes.messageBoxSuccess : classes.messageBoxError)}>
            {state.message}
          </Typography>
          }
          {state.message && state.message != "" && state.code && state.code == 200 &&
          <Button variant="contained" color="primary" href={process.env.REACT_APP_URL + "/signin"}>
            Esegui il login
          </Button>
          }
        </form>
      </div>
    </Container>
  </React.Fragment>
  );

}

export default PasswordResetChange;