import axios from 'axios';
import qs from 'qs';
import BaseService from 'packages/Base/services/BaseService';

export default class PracticeRecordService extends BaseService {

  constructor() {
    super('/lawyer-one/practice-records');
    this.api_url = process.env.REACT_APP_API_URL + '/lawyer-one/practice-records';
  }
}