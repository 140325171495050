import { connect } from 'react-redux';

import RoleAction from '../../actions/RoleAction';

import RoleListComponent from '../../components/Role/RoleList';


const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		pageLoader: state.pageLoader.display,
		filter: state.role.filter,
		items: state.role.items,
		totItems: state.role.totItems
	}
}
const mapDispatchToProps = (dispatch) => {
  let action = new RoleAction();
	return {
		initialize: (params) => {
			dispatch(action.initialize(params));
		},
		search: (params, count) => {
			dispatch(action.search(params, count));
		},
		setSearch: (params) => {
			dispatch(action.setSearch(params));
		},
		remove: (id, searchParams, searchCount) => {
			dispatch(action.removeOnList(id, searchParams, searchCount));
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleListComponent);