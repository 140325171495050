import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';
import BaseAction from 'packages/Base/actions/BaseAction';
import CausaService from '../services/CausaService';

export const causaConsts = {
	INITIALIZE: 'LWO_CAUSA_INITIALIZE',
	SET_FORM_DATA: 'LWO_CAUSA_SET_FORM_DATA',
	SET_MAIN_TAB: 'LWO_CAUSA_SET_MAIN_TAB',
	SET_SEARCH: 'LWO_CAUSA_SET_SEARCH',
	SEARCHED: 'LWO_CAUSA_SEARCH',
	RESET: 'LWO_CAUSA_RESET',
	LOADING: 'LWO_CAUSA_LOADING',	
	LOADED: 'LWO_CAUSA_LOADED',
	CREATED: 'LWO_CAUSA_CREATED',
	CREATED_CUSTOMER: 'LWO_CAUSA_CREATED_CUSTOMER',
	UPDATED: 'LWO_CAUSA_UPDATED',
	UPDATED_CUSTOMER: 'LWO_CAUSA_UPDATED_CUSTOMER',
	FIELDS_UPDATED: 'LWO_CAUSA_FIELDS_UPDATED',
  REMOVED: 'LWO_CAUSA_REMOVED',
  SORT: 'LWO_CAUSA_SORT',
	SORTED: 'LWO_CAUSA_SORTED',
	LIST_ERROR: 'LWO_CAUSA_LIST_ERROR',
  ITEM_ERROR: 'LWO_CAUSA_ITEM_ERROR',
	MEDIA_SINGLE_UPLOADED: 'LWO_CAUSA_MEDIA_SINGLE_UPLOADED',
	MEDIA_REMOVED: 'LWO_CAUSA_MEDIA_REMOVED',
	EXPORTED: 'LWO_CAUSA_EXPORTED',

	ITEMS_LOADED: 'LWO_CAUSA_ITEMS_LOADED',
	ITEMS_RESET: 'LWO_CAUSA_ITEMS_RESET',
  ITEMS_ADD_OR_UPDATE_SUCCESS: 'LWO_CAUSA_ITEMS_ADD_OR_UPDATE_SUCCESS',
  ITEMS_ADD_OR_UPDATE_ERROR: 'LWO_CAUSA_ITEMS_ADD_OR_UPDATE_ERROR',
  ITEMS_REMOVE_SUCCESS: 'LWO_CAUSA_ITEMS_REMOVE_SUCCESS',
  ITEMS_REMOVE_ERROR: 'LWO_CAUSA_ITEMS_REMOVE_ERROR',
}

export default class CausaAction extends BaseAction {

	constructor() {
		super();
		this.service = new CausaService();
		this.consts = causaConsts;
		this.redirect = '/lawyer-one/cause';
  }
  
  initialize(params) {
    return (dispatch) => {
      this.service.initialize(params)
      .then(response => {
        dispatch({
          type: this.consts.INITIALIZE,
          status: response.data.status,
          autorita_giudiziaria: response.data.autorita_giudiziaria,
          customers: response.data.customers
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }


	setMainTab(index){
		return (dispatch) => {
			dispatch({
				type: this.consts.SET_MAIN_TAB,
				index: index
			});
		}
	}

	setFormData(formData){
		return dispatch => {
			dispatch({
				type: this.consts.SET_FORM_DATA,
				formData: formData
			})
		}
	}

  createCustomerPost( postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service
			.createPost(postData).then( response => {
				dispatch({
					type: this.consts.CREATED_CUSTOMER,
					item: response.data.data,
					nextStep: 1
				});
				dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
  }
  
  createCustomer( postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service
			.create(postData).then( response => {
				dispatch({
					type: this.consts.CREATED_CUSTOMER,
					item: response.data.data,
					nextStep: 1
				});
				dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}



	/**
   * Items
   */
	setItemsItem(item) {
		return (dispatch) => {
			dispatch({
				type: this.consts.ITEMS_LOADED,
				item
			});
		}
	}
	resetItemsItem() {
		return (dispatch) => {
			dispatch({
				type: this.consts.ITEMS_RESET
			});
		}
	}
  itemsAddOrUpdate(id, data) {
    return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service
			.itemsAddOrUpdate(id, data).then( response => {
				dispatch({
					type: this.consts.ITEMS_ADD_OR_UPDATE_SUCCESS,
          item: response.data.data,
          meta: response.data.meta
				});
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				var meta = {};
        
        if( error.data && error.data.meta && error.data.meta.message)
          meta = {
            message: error.data.meta.message,
            code: error.data.meta.code,
          }
        else if( error.data && error.data.message )
        meta = {
          message: error.data.message,
          code: error.data.code,
        }
        else
          meta = {
            message: "Error",
            code: error.status,
          }
        
        dispatch({
					type: this.consts.ITEMS_ADD_OR_UPDATE_ERROR,
          meta
				});
			});
		}
  }

  itemsRemove(id, data) {
    return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service
			.itemsRemove(id, data).then( response => {
				dispatch({
					type: this.consts.ITEMS_REMOVE_SUCCESS,
          item: response.data.data,
          meta: response.data.meta
				});
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
        dispatch(PageLoaderActions.hide());
        var meta = {};
        
        if( error.data && error.data.meta && error.data.meta.message)
          meta = {
            message: error.data.meta.message,
            code: error.data.meta.code,
          }
        else if( error.data && error.data.message )
        meta = {
          message: error.data.message,
          code: error.data.code,
        }
        else
          meta = {
            message: "Error",
            code: error.status,
          }
        
        dispatch({
					type: this.consts.ITEMS_REMOVE_ERROR,
          meta
				});
			});
		}
  }
}