import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';
import { exportDefaultSpecifier } from '@babel/types';

export default class BaseAction {

	constructor() {
		this.service = null;
		this.consts = null;
		this.redirect = null;
	}


	/** 
	 * Set
	 */
	setSearch(filter) {
		return (dispatch) => {
			dispatch({
				type: this.consts.SET_SEARCH,
				filter
			});
		}
	}
	setItems(items, totItems) {
		return (dispatch) => {
			dispatch({
				type: this.consts.SEARCHED,
				items,
				totItems
			});
		}
	}
	setItem(item) {
		return (dispatch) => {
			dispatch({
				type: this.consts.LOADED,
				item
			});
		}
	}
	resetItem() {
		return (dispatch) => {
			dispatch({
				type: this.consts.RESET
			});
		}
	}

	/**
	 * Initialize
	 */
	initialize(params) {
		return (dispatch) => {
			this.service.initialize(params)
			.then(response => {
				return Promise.resolve(response);
			})
			.catch(error => {
				return Promise.reject(error)
			})
		}
	}

	getQueryString(location, key) {
    if (location.search != "") {
      let queryString = location.search.substring(1);
      queryString = queryString.split("&");
      let query = new Array();
      for (let i in queryString) {
        let tmp = queryString[i].split('=');
        query[tmp[0]] = tmp[1];
      }
			if (query.hasOwnProperty(key)) 
				return query[key];
    }
    return null;
  }


	/** 
	 * Api
	 */
  search( filter, count ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.search(filter, count).then( response => {
				dispatch({
					type: this.consts.SEARCHED,
					items: response.data.data,
					totItems: response.data.meta.total
				});
				dispatch(PageLoaderActions.hide());
			}).catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	load( id ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.load(id)
			.then( response => {
				dispatch({
					type: this.consts.LOADED,
					item: response.data.data
				});
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	createPost( postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service
			.createPost(postData).then( response => {
				dispatch({
					type: this.consts.CREATED,
					item: response.data.data
				});
				dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
				
				let postype = this.getQueryString(ownProps.location, 'post_type');
				let redirect = this.redirect + '/' + response.data.data.id + ((postype) ? '?post_type='+postype : '');
				ownProps.history.push(redirect);
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	create( postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service
			.create(postData).then( response => {
				dispatch({
					type: this.consts.CREATED,
					item: response.data.data
				});
				dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
				
				let postype = this.getQueryString(ownProps.location, 'post_type');
				let redirect = this.redirect + '/' + response.data.data.id + ((postype) ? '?post_type='+postype : '');
				ownProps.history.push(redirect);
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	updatePost( id, postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.updatePost(id, postData)
			.then( response => {
				dispatch({
					type: this.consts.UPDATED,
					item: response.data.data,
				});
				dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
				
				// let postype = this.getQueryString(ownProps.location, 'post_type');
				// let redirect = this.redirect + '/' + response.data.item.id + ((postype) ? '?post_type='+postype : '');
				// ownProps.history.push(redirect);
				dispatch(this.load(id));
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	update( id, postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.update(id, postData)
			.then( response => {
				dispatch({
					type: this.consts.UPDATED,
					item: response.data.data,
				});
				dispatch(PageLoaderActions.hide());
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
				dispatch(this.load(id));
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}
	
	remove( id ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.remove(id)
			.then( response => {
				dispatch({
					type: this.consts.REMOVED,
					// item: response.data.item
				});
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
				dispatch(PageLoaderActions.hide());
				// history.push(this.redirect);
			})
			.catch( error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				dispatch(PageLoaderActions.hide());
			});
		}
	}

	removeOnList( id, searchParams, searchCount ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.remove(id)
			.then( response => {
				dispatch({
					type: this.consts.REMOVED,
					// item: response.data.item
				});
				dispatch(this.search(searchParams, searchCount));
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				dispatch(PageLoaderActions.hide());
			});
		}
	}
	
	updateFields( id, postData, searchParams, searchCount) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.updateFields(id, postData)
			.then( response => {
				dispatch({
					type: this.consts.UPDATED,
					item: response.data.data
				});
				dispatch(this.search(searchParams, searchCount));
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
				dispatch(PageLoaderActions.hide());
			});
		}
	}

	/** 
	 * Api Media
	 */
	mediaSingleUpload(itemId, postData) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.mediaSingleUpload(itemId, postData)
			.then(response => {
				dispatch({
					type: this.consts.MEDIA_SINGLE_UPLOADED,
					item: response.data.data
				});
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
				// dispatch(this.load(itemId));
				dispatch(PageLoaderActions.hide());
			}).catch(error => {
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
				dispatch(PageLoaderActions.hide());
			});
		}
	}

	mediaRemove(itemId, mediaId) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.mediaRemove(itemId, mediaId)
			.then(response => {
				if (response.data.code == 200) {
					dispatch({
						type: this.consts.MEDIA_REMOVED,
						item: response.data.data
					});
					dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
					dispatch(this.load(itemId));
				} else {
					dispatch(AlertMessageActions.alertMessageError(response.data.message));
				}
				dispatch(PageLoaderActions.hide());
			}).catch(error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error));
				dispatch(PageLoaderActions.hide());
			});
		}
	}

	/** 
	 * Api Sort
	 */
	sortList(postData) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.sortList(postData)
			.then(response => {
				dispatch({
					type: this.consts.SORT,
					sortedItems: response.data
				});
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
				dispatch(PageLoaderActions.hide());
			})
			.catch(error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error));
				dispatch(PageLoaderActions.hide());
			});
		}
	}
	sortItems(postData) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.sortItems(postData)
			.then(response => {
				dispatch({
					type: this.consts.SORTED,
					sortedItems: postData
				});
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
				dispatch(PageLoaderActions.hide());
			})
			.catch(error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error));
				dispatch(PageLoaderActions.hide());
			});
		}
	}


	/**
	 * Export
	 */
	export(params) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.export(params)
			.then( response => {
				dispatch({
					type: this.consts.EXPORTED,
					exportUrl: response.data.url,
				});
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}
}