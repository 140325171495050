import { connect } from 'react-redux'

import CausaAction from "../../actions/CausaAction";
import CausaDetailComponent from '../../components/Causa/CausaDetail';


const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    item: state.lwo_causa.item,
    customers: state.lwo_causa.customers,
    autoritaGiudiziariaList: state.lwo_causa.autorita_giudiziaria,
    statusList: state.lwo_causa.status
  }
}
const mapDispatchToProps = (dispatch) => {
  let action = new CausaAction();
  return {
    initialize: (params) => {
      dispatch(action.initialize(params));
    },
    setItem: (object) => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: (id) => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.create(postData, ownProps));
    },
    update: (id, postData, ownProps) => {
      dispatch(action.update(id, postData, ownProps));
    },
    remove: (id) => {
      dispatch(action.remove(id));
    },
    mediaSingleUpload: (itemId, postData) => {
      dispatch(action.mediaSingleUpload(itemId, postData));
    },
    mediaRemove: (itemId, mediaId) => {
      dispatch(action.mediaRemove(itemId, mediaId));
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CausaDetailComponent);