import UserService from '../services/UserService';
import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';
import { push } from 'react-router-dom'

export const userConsts = {
  AUTHENTICATION_REQUEST: 'AUTHENTICATION_REQUEST',
  AUTHENTICATED: 'AUTHENTICATED_USER',
  AUTHENTICATED_USER_INFO: 'AUTHENTICATED_USER_INFO',
  UNAUTHENTICATED: 'UNAUTHENTICATED_USER',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  UNAUTHORIZED: 'UNAUTHORIZED',

  SET_SEARCH: 'USER_SET_SEARCH',
  SEARCHED: 'USER_SEARCH',
  LOADED: 'USER_LOADED',
  CREATED: 'USER_CREATED',
  UPDATED: 'USER_UPDATED',
  FIELDS_UPDATED: 'USER_FIELDS_UPDATED',
  REMOVED: 'USER_REMOVED',
  LIST_ERROR: 'USER_LIST_ERROR',
  ITEM_ERROR: 'USER_ITEM_ERROR',
}

/**
 * Auth
 */
export function authSuccess(response) {
  return {
      type: userConsts.AUTHENTICATED,
      access_token: response.data.access_token,
      user: response.data.user
  };
}

export function authError(message) {
  return {
      type: userConsts.AUTHENTICATION_ERROR,
      message: message
  }
}

export function authUser(user) {
  return {
      type: userConsts.AUTHENTICATED_USER_INFO,
      user: user
  };
}

export function noauthSuccess() {
  return {
      type: userConsts.UNAUTHENTICATED,
      access_token: null,
      user: null
  };
}

export function login(credentials) {
  return (dispatch) => {
    let service = new UserService();
    service.login(credentials)
    .then( response => {
      if( response.data.error && response.data.error !== '' ) {
        dispatch(AlertMessageActions.alertMessageError('Email e/o password non validi!'));
        dispatch( authError('Errore servizio') );
      }
      else {
        let now = new Date();
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('access_expire', parseInt(response.data.expires_at) + now.getTime());
        localStorage.setItem('user', JSON.stringify(response.data.user));
        dispatch(AlertMessageActions.alertMessageSuccess('Accesso effettuato con successo!'));
        dispatch(authSuccess(response));
      }
    })
    .catch( error => {
      dispatch(AlertMessageActions.alertMessageError('Email e/o password non validi!'));
      dispatch( authError('Errore servizio') );
      console.log('Errore di autenticazione', error);
    });
  }
}

export function getAuthUser() {
  return (dispatch) => {
    UserService.user()
    .then( response => {
      localStorage.setItem('user', JSON.stringify(response.data));
      // sessionStorage.setItem('user', response.data);
      dispatch(authUser(response.data));
    });
  }
}

export function logout() {
  return (dispatch) => {
    let service = new UserService();
    service.logout()
    .then(response => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('access_expire');
      localStorage.removeItem('user');
      dispatch(noauthSuccess());
      dispatch(AlertMessageActions.alertMessageSuccess('Logout eseguito con successo!'));
    })
    .catch(error => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('access_expire');
      localStorage.removeItem('user');
      dispatch(noauthSuccess());
      dispatch(AlertMessageActions.alertMessageError('Errore durante il Logout!'));
    });
    
  }
}


/** 
 * User set
 */
export function setSearch(filter) {
  return (dispatch) => {
    dispatch({
      type: userConsts.SET_SEARCH,
      filter
    });
  }
}
export function setItems(items, totItems) {
  return (dispatch) => {
    dispatch({
      type: userConsts.SEARCHED,
      items,
      totItems
    });
  }
}
export function setItem(item) {
  return (dispatch) => {
    dispatch({
      type: userConsts.LOADED,
      item
    });
  }
}


/**
 * User
 */
export function search( filter, count ) {
  return (dispatch) => {
    let service = new UserService();
    service.search(filter, count).then( response => {
      dispatch({
        type: userConsts.SEARCHED,
        items: response.data.items,
        totItems: response.data.tot_items
      });
    }).catch( error => {
      dispatch(AlertMessageActions.alertMessageError('Errore: ' + error));
      // dispatch(AlertMessageActions.alertMessageError(error));
    });
  }
}

export function load( id ) {
  return (dispatch) => {
    let service = new UserService();
    service.load(id).then( response => {
      dispatch({
        type: userConsts.LOADED,
        item: response.data.item
      });
    }).catch( error => {
      dispatch(AlertMessageActions.alertMessageError(error));
    });
  }
}

export function create( postData ) {
  return (dispatch) => {
    let service = new UserService();
    service.create(postData).then( response => {
      dispatch({
        type: userConsts.CREATED,
        item: response.data.item
      });
      // dispatch(search(load, respoonse.data.item.id));
    }).catch( error => {
      dispatch(AlertMessageActions.alertMessageError(error));
    });
  }
}

export function update( id, postData ) {
  return (dispatch) => {
    let service = new UserService();
    service.update(id, postData).then( response => {
      dispatch({
        type: userConsts.UPDATED,
        item: response.data.item
      });
    }).catch( error => {
      dispatch(AlertMessageActions.alertMessageError(error));
    });
  }
}

export function remove( id ) {
  return (dispatch) => {
    let service = new UserService();
    service.remove(id).then( response => {
      dispatch({
        type: userConsts.REMOVED,
        item: response.data.item
      });
    }).catch( error => {
      dispatch(AlertMessageActions.alertMessageError(error));
    });
  }
}

export function updateFields( id, postData, searchParams, searchCount) {
  return (dispatch) => {
    let service = new UserService();
    dispatch(PageLoaderActions.show());
    service.updateFields(id, postData).then( response => {
      dispatch({
        type: userConsts.UPDATED,
        item: response.data.item
      });
      dispatch(search(searchParams, searchCount));
      dispatch(PageLoaderActions.hide());
    }).catch( error => {
      dispatch(AlertMessageActions.alertMessageError(error));
    });
  }
}