import React from 'react'
import moment from 'moment'
import * as yup from 'yup'
import { useFormik } from 'formik'

import spinner from 'spinner.gif';

import { appConfig } from 'config/appConfig';
import CK5Editor from 'components/UI/Input/CK5Editor'

// import PostLocaleComponent from 'components/UI/Post/PostLocaleComponent';
import { makeStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Tabs,
  Tab,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,

  Modal,
  Backdrop,
  Fade,
} from '@material-ui/core';

import { 
  Comment as CommentIcon,
  Save as SaveIcon,
  Edit as EditIcon, 
  Cancel as CancelIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons';
import { render } from '@testing-library/react';

const useStyles = makeStyles((theme) => ({
  conversationWrap: {
    position: 'relative'
  },
  conversation: {
    width: 'calc(100% - 24px)',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 12,
      display: 'block',
      width: 0,
      height: 0,
    },
    '&.customer': {
      marginLeft: 24,
      backgroundColor: 'green',
      color: 'white',
      '&::after': {
        left: 0,
        borderTop: '30px solid transparent',
        borderBottom: '30px solid transparent',
        borderRight: '30px solid green',
      }
    },
    '&.admin': {
      backgroundColor: theme.palette.secondary.main,
      '& .MuiAvatar-colorDefault': {
        backgroundColor: 'green'
      },
      '&::after': {
        right: 0,
        borderTop: '30px solid transparent',
        borderBottom: '30px solid transparent',
        borderLeft: '30px solid ' + theme.palette.secondary.main,
      }
    }
  }
}))


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box>{children}</Box>} */}
      <Box>{children}</Box>
    </Typography>
  );
}


const Detail = (props) => {
  /**
   * ComponentDidMount
   */
  React.useEffect(() => {
    if( !props.practice || !props.practice.id || props.practice.id <= 0 )
      return

    let newItem = Object.assign({},props.item)
    newItem.conversationable_id = props.practice.id
    newItem.conversationable_type = props.practice.className
    props.setItem(newItem)
  },[])

  /**
   * Handle
   */
  const handleChangeItem = (e) => {
    // e.persist();
    const newItem = Object.assign({},props.item)
    newItem[e.target.name] = e.target.value
    props.setItem(newItem)
  }

  const handleSubmit = () => {
    if( !props.item.description || props.item.description == "" ) {
      alert('compilare il campo descrizione')
      return;
    }
    if( parseInt(props.item.id) <= 0 )
      props.create(props.item)
    else
      props.update(props.item.id, props.item)
  }

  // /**
  //  * Validate
  //  */
  // const formik =  useFormik({
  //   initialValues: {
  //     item_date: props.item.item_date || "",
  //     item_description: props.item.item_description || "",
  //   },
  //   validationSchema: yup.object().shape({
  //     item_date: yup.string().required('Campo non valido'),
  //     item_description: yup.string().required('Campo non valido'),
  //   }),
  //   enableReinitialize: true,
  //   onSubmit: handleSubmit
  // })

  return(
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Rispondi
        </Typography>

        {/* {((props.match.params && props.match.params.itemId > 0 && props.item.id > 0) || (props.match.params && props.match.params.itemId == 0 && props.item.id == 0)) &&
        <Box mb={3}>
          <CK5Editor 
            id="item_description"
            name="item_description"
            label="Descrizione"
            placeholder="Descrizione"
            value={props.item.item_description || ""}
            onEditorChange={(e) => handleChangeItem(e)}
            disabled={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}
            readOnly={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}
            />
        </Box>
        } */}
        <TextField
          id="description"
          name="description"
          label="Descrizione"
          value={props.item.description || ""}
          onChange={(e) => handleChangeItem(e)}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          multiline
          rows={4}
          // InputProps={{
          //   readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
          // }}
        />

        <Button size="small" variant="contained" color="primary" type="button" onClick={()=>handleSubmit()}>
          <CommentIcon /> Rispondi
        </Button>
      </CardContent>
    </Card>
  )
}



const List = (props) => {
  const classes = useStyles();

  React.useEffect(() => {
    if( !props.practice || !props.practice.id || props.practice.id <= 0 )
      return

    let newFilter = Object.assign({},props.filter)
    newFilter.conversationable_id = {
      key: 'conversationable_id',
      compare: '=',
      value: props.practice.id,
    }
    newFilter.conversationable_type = {
      key: 'conversationable_type',
      compare: '=',
      value: props.practice.className,
    }
    props.setSearch(newFilter)
    // props.search(newFilter)
    props.setItems(props.practice.conversations, props.practice.conversations.length)
  },[])


  return(
    <>
      {/* <Typography gutterBottom variant="h5" component="h2">
        Elenco
      </Typography> */}

      {props.loader && 
        <img src={spinner} style={{width: 50}}/>
      }
      {!props.loader && props.items && props.items.map((obj) => 
        <Box mb={3} key={obj.id} className="conversationWrap">
          <Card className={"conversation" + ((obj.ownerUser.role.name == 'Customer') ? ' customer' : ' admin')}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  {obj.ownerUser.first_name.substring(0,1)}
                </Avatar>
              }
              // action={
              //   <IconButton aria-label="settings">
              //     <MoreVertIcon />
              //   </IconButton>
              // }
              title={obj.ownerUser.first_name + (obj.ownerUser.role.name == 'Customer' ? ' (Cliente)' : '(Amministratore)')}
              subheader={'ciao'+obj.creted_at ? obj.created_at : ""}
            />
            <CardContent>
              <div dangerouslySetInnerHTML={{ __html: obj.description }} />
            </CardContent>
          </Card>
        </Box>
      )}
      {/* <Button color="primary" size="small" variant="contained" onClick={()=>props.resetItem()}>
        <CommentIcon /> Rispondi
      </Button> */}
    </>
  )
}

const PracticeConversations = (props) => {
  return(
    <React.Fragment>
      <Box mb={3}>
        <List {...props} />
      </Box>
      <Box mb={3}>
        <Detail {...props} />
      </Box>
    </React.Fragment>
  )  
}

export default PracticeConversations