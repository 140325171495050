import React from 'react'
import qs from 'qs'
import * as yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'

import { appConfig } from 'config/appConfig';
import AlertMessage from 'components/UI/AlertMessage';
import PageLoader from 'components/UI/PageLoader';

import {
  Avatar,
  Box,
  Container,
  Typography,
  Link,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';

import { 
  ArrowForward as ArrowForwardIcon,
  LockOutlined as LockOutlinedIcon,
  Save as SaveIcon, 
  Cancel as CancelIcon,
  DeleteForever as DeleteForeverIcon,
  SentimentVerySatisfiedOutlined
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
		alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box>{children}</Box>} */}
      <Box>{children}</Box>
    </Typography>
  );
}


const SignupUser = props => {
  const classes = useStyles()

  /**
   * Handles
   */
  const handleChange = (e) => {
    var item = Object.assign({}, props.user)

    switch (e.target.name) {
      case 'privacy':
        item[e.target.name] = ( e.target.checked ) ? 1 : 0
      break
      default: 
        item[e.target.name] = e.target.value
      break
    }
    props.setUser(item)
  }

  const handleSubmit = (e) => {
    // props.createUser(props.user)
    
    // if(props.item && props.item.id > 0) 
    //   props.handleMainTab(e, 1)
    props.handleMainTab(e, 1)
    window.scrollTo(0, 0)
  }


  /**
   * Validate
   */
  const formik =  useFormik({
    initialValues: {
      first_name: props.user.first_name || "",
      last_name: props.user.last_name || "",
      email: props.user.email || "",
      password: props.user.password || "",
      password2: props.user.password2 || "",
      privacy: props.user.privacy || "",
    },
    validationSchema: yup.object().shape({
      first_name: yup.string().required('Campo non valido'),
      last_name: yup.string().required('Campo non valido'),
      email: yup.string().required('Campo non valido').email('Inserire una mail valida'),
      password: yup.string().required('Campo non valido'),
      // .matches(
      //   "^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$",
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      // ),
      password2: yup.string().required('Campo non valido').oneOf([yup.ref('password'), null], 'Le password non coincidono'),
      // privacy: yup.boolean().oneOf([true], 'Devi accettare le condizioni di privacy policy'),
      privacy: yup.number().required('Campo obbligatorio').min(1, 'Campo obbligatorio').max(1, 'Campo obbligatorio'),
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  
  return(
    <>
      <Typography component="h3" variant="h5">
        Account
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="first_name"
          name="first_name"
          autoComplete="first_name"
          autoFocus
          label="Nome"
          value={formik.values.first_name}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.first_name ? true : false}
          helperText={formik.errors.first_name}
        />

        <TextField
          id="last_name"
          name="last_name"
          label="Cognome"
          value={formik.values.last_name}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.last_name ? true : false}
          helperText={formik.errors.last_name}
        />

        <TextField
          type="email"
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.email ? true : false}
          helperText={formik.errors.email}
        />

        <TextField
          type="password"
          id="password"
          name="password"
          label="Password"
          value={formik.values.password}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.password ? true : false}
          helperText={formik.errors.password}
        />

        <TextField
          type="password"
          id="password2"
          name="password2"
          label="Conferma Password"
          value={formik.values.password2}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.password2 ? true : false}
          helperText={formik.errors.password2}
        />

        <div style={{marginBottom: 24}}>
          <FormControlLabel
            control={
              <Checkbox
                id="privacy"
                name="privacy"
                checked={formik.values.privacy == "1" ? true : false}
                onChange={e => handleChange(e)}
                value="1"
              />
            }
            label={
              <span style={{fontSize: '1rem'}}>
                Acconsento al trattamento dei miei dati personali secondo la &nbsp;
                <Link href={process.env.REACT_APP_URL + "/privacy-policy"} target="_blank" variant="body2">
                  Privacy policy
                </Link>
              </span>
            }
            
          />
          {formik.errors.privacy &&
          <FormHelperText error={formik.errors.privacy}>{formik.errors.privacy}</FormHelperText>
          }
        </div>
        

        <Button variant="contained" color="primary" type="submit">
          Avanti <ArrowForwardIcon />
        </Button>
      </form>
    </>
  )
}



const SignupCustomer = props => {
  const classes = useStyles()

  /**
   * ComponentDidMount
   */
  React.useEffect(() => {
    props.initialize()
  }, [])

  /**
   * Handles
   */
  const handleChange = (e) => {
    var item = Object.assign({}, props.customer)

    switch (e.target.name) {
      case 'status':
        item[e.target.name] = ( e.target.checked ) ? 1 : 0
      break
      default: 
        item[e.target.name] = e.target.value
      break
    }
    props.setCustomer(item)
  }

  const handleSubmit = (e) => {
    // props.createCustomer(props.customer)
    props.signup({...props.user, ...props.customer})
    window.scrollTo(0, 0)
  }


  /**
   * Validate
   */
  const formik =  useFormik({
    initialValues: {
      type: props.customer.type || "",
      referent: props.customer.referent || "",
      company: props.customer.company || "",
      taxcode: props.customer.taxcode || "",
      vat: props.customer.vat || "",
      
      country: props.customer.country || "Italia",
      state: props.customer.state || "",
      zipcode: props.customer.zipcode || "",
      city: props.customer.city || "",
      address: props.customer.address || "",
      
      phone: props.customer.phone || "",
      mobile_phone: props.customer.mobile || "",
      // email: props.customer.email || "",
    },
    validationSchema: yup.object().shape({
      type: yup.string().required('Campo non valido'),
      referent: (props.customer.type != 'persona_fisica') ? yup.string().required('Campo non valido') : null,
      company: (props.customer.type != 'persona_fisica') ? yup.string().required('Campo non valido') : null,
      taxcode: (props.customer.type == 'persona_fisica' || props.customer.type == 'ditta_individuale' || props.customer.type == 'libero_professionista') ? 
        yup.string()
        .required('Campo non valido')
        .matches(
          /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/,
          "Il campo deve essere una stringa alfanumerica di 16 caratteri e numeri"
        ) : 
        null,
      vat: (props.customer.type != 'persona_fisica') ? 
        yup.string()
        .required('Campo non valido')
        .matches(
          /^IT[0-9]{11}$/,
          "Il campo deve rispettare il seguente formato: IT12345678901 (codice paese e 11 cifre)"
        ) : 
        null,
      country: yup.string().required('Campo non valido'),
      state: yup.string().required('Campo non valido'),
      zipcode: yup.string().required('Campo non valido').min(5, 'Numero di caratteri non valido').max(5, 'Numero di caratteri non valido'),
      city: yup.string().required('Campo non valido'),
      address: yup.string().required('Campo non valido'),

      phone: yup.string().required('Campo non valido'),
      mobile_phone: yup.string().required('Campo non valido'),
      // company_email: yup.string().required('Campo non valido').email('Inserire una mail valida'),
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  
  
  return(
    <>
      <Typography component="h3" variant="h5">
        Account
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          select
          id="type"
          name="type"
          label="Tipologia utente"
          value={formik.values.type}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.type ? true : false}
          helperText={formik.errors.type}
        >
          <MenuItem value="">
            -
          </MenuItem>
          {props.types && props.types.map((option,index) =>
            <MenuItem key={index} value={option.key}>
              {option.value}
            </MenuItem>
          )}
        </TextField>

        {props.customer.type != 'persona_fisica' &&
        <>
        <TextField
          id="referent"
          name="referent"
          label="Nome e Cognome del referente"
          value={formik.values.referent}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.referent ? true : false}
          helperText={formik.errors.referent}
        />

        <TextField
          id="company"
          name="company"
          label="Ragione Sociale"
          value={formik.values.company}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.company ? true : false}
          helperText={formik.errors.company}
        />

        <TextField
          id="vat"
          name="vat"
          label="P.IVA"
          value={formik.values.vat}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.vat ? true : false}
          helperText={formik.errors.vat}
        />
        </>
        }

        {(props.customer.type == 'persona_fisica' || props.customer.type == 'ditta_individuale' || props.customer.type == 'libero_professionista') &&
        <TextField
          id="taxcode"
          name="taxcode"
          label="Codice fiscale"
          value={formik.values.taxcode}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.taxcode ? true : false}
          helperText={formik.errors.taxcode}
        />
        }

        <TextField
          id="country"
          name="country"
          label="Paese"
          value={formik.values.country}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.country ? true : false}
          helperText={formik.errors.country}
          disabled
        />

        <TextField
          select
          id="state"
          name="state"
          label="Provincia"
          value={formik.values.state}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.state ? true : false}
          helperText={formik.errors.state}
        >
          <MenuItem value="">
            -
          </MenuItem>
          {props.states && Object.entries(props.states).map(([key, value]) =>
            <MenuItem key={key} value={value}>
              {value}
            </MenuItem>
          )}
        </TextField>

        <TextField
          id="zipcode"
          name="zipcode"
          label="Codica Postale"
          value={formik.values.zipcode}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.zipcode ? true : false}
          helperText={formik.errors.zipcode}
        />

        <TextField
          id="city"
          name="city"
          label="Città"
          value={formik.values.city}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.city ? true : false}
          helperText={formik.errors.city}
        />

        <TextField
          id="address"
          name="address"
          label="Indirizzo"
          value={formik.values.address}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.address ? true : false}
          helperText={formik.errors.address}
        />

        <TextField
          id="phone"
          name="phone"
          label="Telefono"
          value={formik.values.phone}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.phone ? true : false}
          helperText={formik.errors.phone}
        />

        <TextField
          id="mobile_phone"
          name="mobile_phone"
          label="Cellulare"
          value={formik.values.mobile_phone}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.mobile_phone ? true : false}
          helperText={formik.errors.mobile_phone}
        />

        <Button variant="contained" color="primary" type="submit">
          Avanti <ArrowForwardIcon />
        </Button>
      </form>
    </>
  )
}


const SignupVerify = props => {
  const classes = useStyles()
  const [token, setToken] = React.useState("")
  const [resendVerifyEmailButton, setResendVerifyEmailButton] = React.useState(true)

  /**
   * ComponentDidMount
   */
  React.useEffect(() => {
    let queryParams = qs.parse(props.location.search.replace('?',''))
    if( queryParams && queryParams.token && queryParams.token != "" ){
      setToken(queryParams.token)
      setResendVerifyEmailButton(false)
    }
  },[])

  /**
   * Handles
   */
  const handleChange = (e) => {
    setToken(e.target.value)
  }

  const handleSubmit = (e) => {
    // props.createCustomer(props.customer)
    props.verifyUser(token)
    window.scrollTo(0, 0)
  }

  const handleSend = e => {
    props.resendVerifyEmail(props.user.id)
  }


  /**
   * Validate
   */
  const formik =  useFormik({
    initialValues: {
      token: token || ""
    },
    validationSchema: yup.object().shape({
      token: yup.string().required('Campo non valido')
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  

  return(
    <>
      <label style={{display: 'block', marginBottom: 12}}>
        Verifica la tua casella di posta elettronica. Ti abbiamo inviato una mail per verificare il tuo account.
      </label>

      <form onSubmit={formik.handleSubmit} style={{marginBottom: 24}}>
        <TextField
          id="token"
          name="token"
          label="Codice di verifica"
          value={formik.values.token}
          onChange={e => {
            handleChange(e);
            formik.handleChange(e)
          }}
          // onBlur={formik.handleBlur}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          error={formik.errors.token ? true : false}
          helperText={formik.errors.token}
        />

        <Button variant="contained" color="primary" type="submit">
          Verifica account
        </Button>
      </form>

      {resendVerifyEmailButton &&
      <Button variant="contained" type="button"
        onClick={e => handleSend(e)}
        >
        Rinvia email di verifica
      </Button>
      }
    </>
  )
}



const Signup = props => {
  const classes = useStyles()

  /**
   * ComponentDidMount
   */
  React.useEffect(() => {
    let queryParams = qs.parse(props.location.search.replace('?',''))
    if( queryParams && queryParams.token && queryParams.token != "" ){
      props.setMainTab(2)
    }
  },[])

  /**
   * Handle
   */
  const handleMainTab = (event, newValue) => {
    props.setMainTab(newValue)
  }

  /**
  * Render
  */

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  return(
    <>
      <PageLoader />
      <AlertMessage />

      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registrazione
          </Typography>

          <Paper style={{marginBottom: "1rem"}}>
            <Tabs
              value={props.mainTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue)=>props.setMainTab(newValue)}
              aria-label="disabled tabs example"
            >
              <Tab label="Step 1" {...a11yProps(0)} disabled={(props.mainTab > 1) ? true : false} />
              <Tab label="Step 2" {...a11yProps(1)} disabled />
              <Tab label="Step 3" {...a11yProps(2)} disabled />
            </Tabs>
          </Paper>

          <TabPanel value={props.mainTab} index={0}>
            <SignupUser {...props} handleMainTab={(event, newValue) => handleMainTab(event, newValue)} />
          </TabPanel>

          <TabPanel value={props.mainTab} index={1}>
            <SignupCustomer {...props} handleMainTab={(event, newValue) => handleMainTab(event, newValue)} />
          </TabPanel>

          <TabPanel value={props.mainTab} index={2}>
            <SignupVerify {...props} handleMainTab={(event, newValue) => handleMainTab(event, newValue)} />
          </TabPanel>

        </div>
      </Container>
    </>
  )
}

export default Signup