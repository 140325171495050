import { practiceRecordConsts as consts } from '../actions/PracticeRecordAction';

let initialState = {
  loader: false,
  items: [],
  totItems: 0,
  filter: {
    // id: {
    //   key: 'id',
    //   compare: '=',
    //   value: ''
    // },
    // item_date: {
    //   key: 'item_date',
    //   compare: '=',
    //   value: ''
    // },
    recordable_id: {
      key: 'recordable_id',
      compare: '=',
      value: ''
    },
    recordable_type: {
      key: 'recordable_type',
      compare: '=',
      value: ''
    },
    order: ['date_at', 'asc'],
    // page: 1,
    // num_per_page: 25,
  },
  item: {
    id: 0,
    recordable_id: 0,
    recordable_type: "",
    description: "",
    date_at: null,
    created_at: null,
    updated_at: null,
  },
}

const PracticeRecordReducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.INITIALIZE:
      return {
        ...state,
        types: action.types,
      };
    case consts.SET_LOADER:
      return {
        ...state,
        loader: action.loader,
      };
    case consts.RESET:
      return {
        ...state,
        item: initialState.item,
      };
    case consts.SET_SEARCH:
      return {
        ...state,
        filter: action.filter
      };
    case consts.SEARCHED:
      return {
        ...state,
        items: action.items,
        totItems: action.totItems
      };
    case consts.LOADED:
      return {
        ...state,
        item: action.item,
      };
    case consts.CREATED:
      return {
        ...state,
        item: action.item,
      };
    case consts.UPDATED:
      return {
        ...state,
        item: action.item,
      };
    case consts.REMOVED:
      return {
        ...state,
        // item: initialState.item,
      };
    // case consts.SORT:
    //   return {
    //     ...state,
    //     sortedItems: action.sortedItems,
    //   };
    // case consts.SORTED:
    //   return {
    //     ...state,
    //     sortedItems: action.sortedItems,
    //   };
    default:
      return state
  }
}

export default PracticeRecordReducer;
