import React from 'react'

import index from 'index.png'

import {
  Button,
  Container,
  Grid,
  IconButton,
} from '@material-ui/core'

import {
  AccountCircle,
  ContactMail as ContactMailIcon,
  Mail as MailIcon,
  AlternateMail as AlternateMailIcon,
  Language as LanguageIcon,
  PanTool as PanToolIcon,
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  root: {
    '& .section': {
      padding: '48px 0px',
    },
    '& .section-1': {
      // padding: '24px 0',
      background: theme.palette.primary.gradient,
      color: '#fff'
    },
    '& .section-2': {
      backgroundColor: 'transparent',
      color: theme.palette.black.main,
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.3vw'
      },
    },
    '& p': {
      lineHeight: 1.5
    }
  },
  wrapSx: {
    textAlign: 'center'
  },
  title: {
    // textAlign: 'center'
    marginBottom: 48
  },
  link: {
    display: 'block',
    marginTop: '30px',
    color: '#ffffff !important',
    '&:visited, &:hover': {
      color: '#ffffff !important',
    }
  },
  card: {
    // display: 'flex',
    // alignItems: 'center',
    color: theme.palette.white.main,
    backgroundColor: 'transparent',
    padding: 12,
    boxShadow: '0 0 5px #fff',
    height: '15vw',
    '&:hover': {
      transform: 'scale(1.02, 1.02)'
    },
    '& .cardContent': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1rem',
      textDecoration: 'none'
    },
    '& .icon': {
      textAlign: 'center',
      marginBottom: 24
    },
    '& a, & a:visited, & .hrefTitle, & .hrefTitle:visited': {
      color: theme.palette.white.main,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1rem',
      textDecoration: 'none'
    },
    '& .hrefBlock': {
      display: 'block',
      width: '100%',
      height: '100%'
    },
    '@media screen and (max-width: 600px)': {
      height: '30vw',
      '& .cardContent': {
        fontSize: '0.9rem'
      }
    }
  }
}), {name: 'Index'});

const Index = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>

      <div className="section section-1">
        <Container maxWidth={false}>
            
      
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>

                {/* <p>
                Il portale è stato realizzato per offrire al cliente la possibilità di caricare anche in via autonoma le singole pratiche da affidare allo studio tramite le apposite sezioni “contravvenzioni a sanzioni amministrative” e “cause ordinarie”.
                </p>

                <p>
                Il cliente ha così la possibilità di monitorare lo stato di avanzamento di ogni singola pratica, interfacciarsi con i professionisti, ricevere tempestivi aggiornamenti (udienze, scadenze, attività, documentazione prodotta, report ed altro) così da ottenere semplificazione, ottimizzazione, efficienza e contezza dell’intero iter delle pratiche affidate allo studio.
                </p> */}

              <div className={classes.wrapSx}>
                <h1 className={classes.title}>Portale Clienti</h1>

                <img src={index} style={{maxWidth: '70%', marginTop: 24}}/>

                {/* <a href="https://www.ambron.it" target="_blank" className={classes.link}>
                  www.ambron.it
                </a> */}
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6}>
                  <div className={classes.card}>
                    <a className="hrefBlock" href="/signin">
                      <div className="cardContent">
                        <div className="icon">
                          <AccountCircle />
                        </div>
                        <div className="hrefTitle">Accedi</div>
                      </div>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                <div className={classes.card}>
                  <a className="hrefBlock" href="/signup">
                    <div className="cardContent">
                      <div className="icon">
                          <ContactMailIcon />
                        </div>
                        <div className="hrefTitle">Registrati</div>
                      </div>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                <div className={classes.card}>
                  <a className="hrefBlock" href="mailto:info@ambron.it">
                    <div className="cardContent">
                      <div className="icon">
                          <MailIcon />
                        </div>
                        <div className="hrefTitle">Richiedi assistenza</div>
                      </div>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                <div className={classes.card}>
                  <a className="hrefBlock" href="/privacy-policy">
                    <div className="cardContent">    
                        <div className="icon">
                          <PanToolIcon />
                        </div>
                        <div className="hrefTitle">Privacy Policy</div>
                      </div>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
        


      <div className="section section-2">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item md={6}>
              <h1 className={classes.title} style={{paddingLeft: 24}}>
                Benvenuti!<br/><br/>
                Siamo sempre più vicini ai nostri clienti...
              </h1>
            </Grid>
            <Grid item md={3}>
              <p>
              Il portale è stato realizzato per offrire al cliente la possibilità di caricare anche in via autonoma le singole pratiche da affidare allo studio tramite le apposite sezioni "contravvenzioni" e "cause ordinarie".
              </p>
            </Grid>
            <Grid item md={3}>
              <p>
                Il cliente ha così la possibilità di monitorare lo stato di avanzamento di ogni singola pratica, interfacciarsi con i professionisti, ricevere tempestivi aggiornamenti (udienze, scadenze, attività, documentazione prodotta, report ed altro) così da ottenere semplificazione, ottimizzazione, efficienza e contezza dell’intero iter delle pratiche affidate allo studio.
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>


    </div>
  )
}

export default Index