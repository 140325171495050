import React from 'react';
import * as yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'

import { appConfig } from 'config/appConfig';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from 'components/UI/Alert';
import CK5Editor from 'components/UI/Input/CK5Editor'
import MediaComponent from 'components/UI/Media/MediaComponent';
import RevisionComponent from 'components/UI/RevisionComponent';
// import ContravvenzioneItems from '../../containers/Contravvenzione/ContravvenzioneItems';
import PracticeRecords from '../../containers/PracticeRecords';
import PracticeConversations from '../../containers/PracticeConversations';
// import VerbaleMedia from '../../containers/VerbaleMedia'
// import VerbaleMedia from '../VerbaleMedia'
import VerbaleMedia from '../../containers/Contravvenzione/ContravvenzioneVerbaleMedia'

import {
  Avatar,
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';

import { 
  Save as SaveIcon, 
  Cancel as CancelIcon,
  DeleteForever as DeleteForeverIcon,
  SentimentVerySatisfiedOutlined
} from '@material-ui/icons';
import { contravvenzioneConsts } from '../../actions/ContravvenzioneAction';




const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box>{children}</Box>} */}
      <Box>{children}</Box>
    </Typography>
  );
}


const CausaDetail = props => {
  const [state, setState] = React.useState({
    itemId: props.match.params.itemId,
    mainTabs: 0,
    path: `${process.env.REACT_APP_URL}/lawyer-one/cause`,
    mediaPath: `/lawyer-one/cause`,
  })


  /**
   * ComponentDidMount
   */
  React.useEffect(() => {
    if( state.itemId <= 0 && document.forms[0] )
      document.forms[0].reset()
    
    props.initialize()

    if( state.itemId > 0 )
      axiosGetItem()
    else
      resetItem()
  },[])

  /**
   * Axios
   */
  const resetItem = () => {
    props.resetItem()
    window.scroll(0, 0)
  }

  const axiosGetItem = () => {
    props.load(state.itemId)
    window.scroll(0, 0)
  }

  const axiosCreateItem = (data) => {
    if( !data )
      data = props.item
    props.create(data, props)
    window.scroll(0, 0)
  }

  const axiosUpdateItem = (data) => {
    if( !data )
      data = props.item
    props.update(props.item.id, data, props)
    window.scroll(0, 0)
  }

  /**
   * Handle
   */
  const handleChange = (e) => {
    var item = Object.assign({}, props.item)

    switch (e.target.name) {
      case 'archived':
        item[e.target.name] = ( e.target.checked ) ? 1 : 0
      break
      case 'fattura':
        item.fattura = ( e.target.checked ) ? 1 : 0
        item.numero_fattura = null
        item.compenso_fattura = 0
        item.iva_fattura = 0
      break
      default: 
        item[e.target.name] = e.target.value
      break
    }
    props.setItem(item)
  }

  const handleAutocomplete = (key, value) => {
    var item = Object.assign({}, props.item);
    switch(key) {
      default: item[key] = value; break;
    }
    props.setItem(item);
  }

  const handleSubmit = () => {
    if (props.item && props.item.id > 0)
      axiosUpdateItem()
    else
      axiosCreateItem()
    window.scrollTo(0, 0);
  }

  const handleMainTab = (event, newValue) => {
    setState({
      ...state,
      mainTabs: newValue
    });
  }


  /**
   * Validate
   */
  const formik =  useFormik({
    initialValues: {
      cliente_id: props.item.cliente_id || "",
      controparte: props.item.controparte || "",
      foro_competente: props.item.foro_competente || "",
      valore_causa: props.item.valore_causa || "",
      status: props.item.status || "presa_in_carico",
    },
    validationSchema: yup.object().shape({
      cliente_id: yup.string().required('Campo non valido'),
      controparte: yup.string().required('Campo non valido'),
      foro_competente: yup.string().required('Campo non valido'),
      valore_causa: yup.string().required('Campo non valido'),
      status: yup.string().required('Campo non valido'),
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  /**
  * Render
  */
  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="page-title">
        { (state.itemId == 0) ? 'Nuova' : 'Modifica' } Causa
      </div>

      <Grid container className="row rowSpacing">
        <Grid item md={4}>
          <Box mb={3}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Generale - ID:{" "}
                  {props.item.id || "0"}
                </Typography>

                
                <Autocomplete
                  id="cliente_id"
                  name="cliente_id"
                  options={props.customers || []}
                  getOptionLabel={(option) => {
                    console.log('option label', option)
                    if( option ){
                      if( appConfig.clientiTypes.aziende.includes(option.type) ){
                        return `[${option.id}] ${option.company}`;
                      }
                      if( option.user && appConfig.clientiTypes.privati.includes(option.type) ){
                        return `[${option.id}] ${option.user.last_name} ${option.user.first_name}`;
                      }
                      if( !option.user && appConfig.clientiTypes.privati.includes(option.type) ){
                        return `[${option.id}] ${option.referent}`;
                      }
                      // return `[${option.id}] ${option.last_name} ${option.first_name}`;
                    }
                    return '';
                  }}
                  renderInput={(params) => 
                    <TextField {...params} 
                      label="Cliente"
                      margin="dense"
                      fullWidth={true}
                      variant={appConfig.inputVariant}
                      error={formik.errors.cliente_id ? true : false}
                      helperText={formik.errors.cliente_id}
                    />
                  }
                  value={(props.customers) ? props.customers.find(option => option.id === formik.values.cliente_id) || null : null}
                  onChange={(e, option) => {
                    if( option ){
                      handleAutocomplete('cliente_id', option.id)
                      formik.setFieldValue('cliente_id', option.id)
                    }
                  }}
                  disabled={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}    
                />
                  
                <TextField
                  select
                  id="status"
                  name="status"
                  label="Stato"
                  // value={props.item ? (props.item.status || 'preanalisi') : ''}
                  value={formik.values.status || 'preanalisi'}
                  onChange={(e)=>{
                    handleChange(e)
                    formik.handleChange(e)
                  }}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  error={formik.errors.status ? true : false}
                  helperText={formik.errors.status}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                >
                  {props.statusList && props.statusList.map((option,index) =>
                    <MenuItem key={index} value={option.key}>
                      {option.value}
                    </MenuItem>
                  )}
                  {!props.statusList &&
                    <MenuItem value="presa_in_carico">
                      presa in carico
                    </MenuItem>
                  }
                </TextField>

                <TextField 
                  type="date"
                  id="inizio_pratica"
                  name="inizio_pratica"
                  label="Inizio pratica"
                  placeholder="Inizio pratica"
                  value={props.item.inizio_pratica || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  type="date"
                  id="termine_pratica"
                  name="termine_pratica"
                  label="Termine pratica"
                  placeholder="Termine pratica"
                  value={props.item.termine_pratica || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                {/* <FormControlLabel
                  control={
                    <Checkbox
                      id="archived"
                      name="archived"
                      checked={props.item.archived == "1" ? true : false}
                      onChange={e => handleChange(e)}
                      value="1"
                    />
                  }
                  label="Archiviato?"
                  disabled={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}
                /> */}

                <FormControlLabel
                  control={
                    <Checkbox
                      id="fattura"
                      name="fattura"
                      checked={props.item.fattura == "1" ? true : false}
                      onChange={e => handleChange(e)}
                      value="1"
                      disabled={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}
                    />
                  }
                  label="Fatturato"
                />

                
                <TextField 
                  id="numero_fattura"
                  name="numero_fattura"
                  label="Numero Fattura"
                  placeholder="Numero Fattura"
                  value={props.item.numero_fattura || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  id="spese_esenti"
                  name="spese_esenti"
                  label="Spese esenti"
                  placeholder="Spese esenti"
                  value={props.item.spese_esenti || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  id="compenso_fattura"
                  name="compenso_fattura"
                  label="Compenso Fattura senza iva"
                  placeholder="Compenso Fattura senza iva"
                  value={props.item.compenso_fattura || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  id="iva_fattura"
                  name="iva_fattura"
                  label="Tasse Fattura"
                  placeholder="Tasse Fattura"
                  value={props.item.iva_fattura || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  id="tot_fattura"
                  name="tot_fattura"
                  label="Totale Fattura"
                  placeholder="Totale Fattura"
                  value={(parseFloat(props.item.compenso_fattura || "0") + parseFloat(props.item.iva_fattura || "0") + parseFloat(props.item.spese_esenti || "0"))}
                  onChange={e => {}}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                />

              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item md={8}>
          <Paper style={{marginBottom: "1rem"}}>
            <Tabs
              value={state.mainTabs}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue)=>handleMainTab(e, newValue)}
              aria-label="disabled tabs example"
            >
              <Tab label="Dettagli" {...a11yProps(0)} />
              <Tab label="Comunicazioni" {...a11yProps(1)} />
              <Tab label="Documenti" {...a11yProps(2)} />
              <Tab label="Aggiornamenti" {...a11yProps(3)} />
            </Tabs>
          </Paper>

          <TabPanel value={state.mainTabs} index={0}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Dettagli
                </Typography>
                
                <TextField
                  id="controparte"
                  name="controparte"
                  label="Controparte"
                  placeholder="Controparte"
                  value={formik.values.controparte || ""}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  error={formik.errors.controparte ? true : false}
                  helperText={formik.errors.controparte}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  id="controparti"
                  name="controparti"
                  label="Altre controparti"
                  placeholder="Altre controparti"
                  value={props.item.controparti || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField
                  select
                  id="autorita_giudiziaria"
                  name="autorita_giudiziaria"
                  label="Autorità giudiziaria"
                  value={props.item.autorita_giudiziaria || ''}
                  onChange={(e)=>handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                >
                  <MenuItem value="">
                    -
                  </MenuItem>
                  {props.autoritaGiudiziariaList && props.autoritaGiudiziariaList.map((option,index) =>
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  )}
                </TextField>

                <TextField
                  id="sezione"
                  name="sezione"
                  label="Sezione"
                  placeholder="Sezione"
                  value={props.item.sezione || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField
                  id="foro_competente"
                  name="foro_competente"
                  label="Foro competente"
                  placeholder="Foro competente"
                  value={formik.values.foro_competente || ""}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  error={formik.errors.foro_competente ? true : false}
                  helperText={formik.errors.foro_competente}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField
                  id="grado_giudizio"
                  name="grado_giudizio"
                  label="Grado di giudizio"
                  placeholder="Grado di giudizio"
                  value={props.item.grado_giudizio || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField
                  id="rg"
                  name="rg"
                  label="R.G."
                  placeholder="R.G."
                  value={props.item.rg || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  id="valore_causa"
                  name="valore_causa"
                  label="Valore Causa"
                  placeholder="Valore Causa"
                  value={props.item.valore_causa || ""}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  error={formik.errors.valore_causa ? true : false}
                  helperText={formik.errors.valore_causa}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  id="giudice"
                  name="giudice"
                  label="Giudice"
                  placeholder="Giudice"
                  value={props.item.giudice || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  error={formik.errors.giudice ? true : false}
                  helperText={formik.errors.giudice}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                <TextField 
                  id="oggetto_contenzioso"
                  name="oggetto_contenzioso"
                  label="Oggetto contenzioso"
                  placeholder="Oggetto contenzioso"
                  value={props.item.oggetto_contenzioso || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  error={formik.errors.oggetto_contenzioso ? true : false}
                  helperText={formik.errors.oggetto_contenzioso}
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                {/** */}

                <TextField 
                  type="datetime-local"
                  id="data_udienza"
                  name="data_udienza"
                  label="Data udienza"
                  placeholder="Data udienza"
                  value={(props.item.data_udienza) ? moment(props.item.data_udienza, moment.ISO_8601).format('YYYY-MM-DDThh:mm') : ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    // readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                    readOnly: true
                  }}
                />

                <TextField 
                  id="esito_causa"
                  name="esito_causa"
                  label="Esito causa"
                  placeholder="Esito causa"
                  value={props.item.esito_causa || ""}
                  onChange={e => handleChange(e)}
                  margin="dense"
                  variant={appConfig.inputVariant}
                  fullWidth
                  InputProps={{
                    readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
                  }}
                />

                {((props.match.params && props.match.params.itemId > 0 && props.item.id > 0) || (props.match.params && props.match.params.itemId == 0 && props.item.id == 0)) &&
                <CK5Editor 
                  id="note"
                  name="note"
                  label="Note"
                  placeholder="Note"
                  value={props.item.note || ""}
                  onEditorChange={(e) => handleChange(e)}
                  disabled={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}
                  readOnly={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}
                  />
                }
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel value={state.mainTabs} index={1}>
            { (!props.item || props.item.id <= 0) &&
              <Alert severity="warning" message="Puoi aggiungere comunicazioni dopo aver salvato la pratica." />
            }
            { props.item && props.item.id > 0 &&
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Comunicazioni
                  </Typography>

                  <PracticeConversations
                    practice={props.item}
                    match={props.match}
                    />
                </CardContent>
              </Card>
            }
          </TabPanel>

          <TabPanel value={state.mainTabs} index={2}>
          { !props.item || props.item.id <= 0 &&
            <Alert severity="warning" message="Puoi aggiungere documenti dopo aver salvato."/>
          }
          { props.item && props.item.id > 0 &&
            <>
              {/* <Box mb={3}>
              <VerbaleMedia 
                // item={props.item || null}
                // authUser={props.authUser || null}
                // // itemId={props.item.id}
                // // file_verbale={props.item.file_verbale} 
                // upload={(itemId, postData)=>props.mediaSingleUpload(itemId, postData)}
                // reload={props.load}
              />
              </Box> */}

              <MediaComponent 
                itemId={props.item.id || 0}
                url={state.mediaPath}
                collections={{files:[]}}
              />
            </>
          }
          </TabPanel>

          <TabPanel value={state.mainTabs} index={3}>
            { (!props.item || props.item.id <= 0) &&
              <Alert severity="warning" message="Puoi aggiungere aggiornamenti dopo aver salvato."/>
            }
            { props.item && props.item.id > 0 &&
            <PracticeRecords
              practice={props.item}
              match={props.match}
              />
            }
          </TabPanel>
          
        </Grid>
      </Grid>

      {state.itemId > 0 &&
      <RevisionComponent
        createdUser={props.item.createdUser}
        createdAt={props.item.created_at}
        updatedUser={props.item.updatedUser}
        updatedAt={props.item.updated_at}
      />
      }

      <Grid container>
        <Grid item md={12}>
          <Button variant="contained" color="primary" type="submit">
            <SaveIcon /> Salva
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" href={`${state.path}${props.location.search}`}>
            <CancelIcon /> Chiudi
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CausaDetail