import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup'
import { useFormik } from 'formik'

import UserAction from 'packages/User/actions/UserAction';
import AlertMessage from 'components/UI/AlertMessage';
import PageLoader from 'components/UI/PageLoader';

import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        {process.env.REACT_APP_NAME}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
		alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const Login = props => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    email: '',
    password: '',
    classes
  })

  /**
   * Handle
   */
  const handleChange = (e) => {
    setState({ 
      ...state,
      [e.target.name]: e.target.value 
    });
  }

  const handleSubmit = () => {
		props.login({
      email: state.email,
      password: state.password
    }, props);
  }
  
  /**
   * Validate
   */
  const formik =  useFormik({
    initialValues: {
      email: state.email || "",
      password: state.password || "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().required('Campo non valido').email("Inserire una mail valida"),
      password: yup.string().required('Campo non valido'),
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
	
	return (
    <React.Fragment>
      <PageLoader />
      <AlertMessage />
			<Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
						onChange={(e)=>{
              handleChange(e)
              formik.handleChange(e)
            }}
            error={formik.errors.email ? true : false}
            helperText={formik.errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
						onChange={(e)=>{
              handleChange(e)
              formik.handleChange(e)
            }}
            error={formik.errors.password ? true : false}
            helperText={formik.errors.password}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href={process.env.REACT_APP_URL + "/password/reset"} variant="body2">
                Non ricordo la password?
              </Link>
            </Grid>
            {/* <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  </React.Fragment>
	)
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToPros = (state) => {
  return {
    authenticated: state.auth.authenticated,
    message: state.auth.message
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials, ownProps) => {
			let action = new UserAction();
      dispatch( action.login(credentials, ownProps) );
    }
  }
}

export default connect( mapStateToPros, mapDispatchToProps )(Login);