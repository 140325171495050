import { connect } from 'react-redux'

import SignupAction from "../../actions/SignupAction";
import SignupComponent from '../../components/Signup/Signup';


const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    user: state.lwo_signup.user,
    customer: state.lwo_signup.customer,
    types: state.lwo_signup.types,
    customers: state.lwo_signup.customers,
    states: state.lwo_signup.states,
    mainTab: state.lwo_signup.mainTab
  }
}
const mapDispatchToProps = (dispatch) => {
  let action = new SignupAction();
  return {
    initialize: (params) => {
      dispatch(action.initialize(params));
    },
    setMainTab: (index) => {
      dispatch(action.setMainTab(index));
    },
    setUser: (model) => {
      dispatch(action.setUser(model));
    },
    setCustomer: (model) => {
      dispatch(action.setCustomer(model));
    },
    signup: (postData) => {
      dispatch(action.signup(postData));
    },
    verifyUser: (token) => {
      dispatch(action.verifyUser(token));
    },
    resendVerifyEmail: (userId) => {
      dispatch(action.resendVerifyEmail(userId));
    },
    // createUser: (postData) => {
    //   dispatch(action.createUser(postData));
    // },
    // createCustomer: (postData, ownProps) => {
    //   dispatch(action.createCustomer(postData, ownProps));
    // }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SignupComponent);