import { connect } from 'react-redux';

import CausaAction from '../../actions/CausaAction';

import CausaListComponent from '../../components/Causa/CausaList';

const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		pageLoader: state.pageLoader.display,
		filter: state.lwo_causa.filter,
		items: state.lwo_causa.items,
    totItems: state.lwo_causa.totItems,
    customers: state.lwo_causa.customers,
    statusList: state.lwo_causa.status,
    autoritaGiudiziariaList: state.lwo_causa.autorita_giudiziaria,
    exportUrl: state.lwo_causa.exportUrl
	}
}
const mapDispatchToProps = (dispatch) => {
  let action = new CausaAction();
	return {
		initialize: (params) => {
      dispatch(action.initialize(params));
    },
    search: (params) => {
      dispatch(action.search(params));
    },
    setSearch: (params) => {
      dispatch(action.setSearch(params));
    },
    remove: (id, filter, count) => {
      dispatch(action.removeOnList(id, filter, count));
    },
    updateFields: (id, params, filter, count) => {
      dispatch(action.updateFields(id, params, filter, count));
    },
    export: (params) => {
      dispatch(action.export(params));
    },
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(CausaListComponent);