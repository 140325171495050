import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  footer: {
    fontSize: '0.8rem',
		// background: theme.palette.secondary.main,
		background: theme.palette.black.main,
		color: theme.palette.white.main,
    padding: '24px 0',
    boxShadow: '0 0 5px #3a3a3a',
		'& a': {
			color: '#fff'
		}
	}
}));

const Footer = () => {
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<Container>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<span>© 2020 Studio Legale Ambron</span>
					</Grid>
					<Grid item xs={6}>
					<a className="grey-text text-lighten-4 right" href="/privacy-policy" target="_blank">Privacy Policy</a>
					&nbsp;-&nbsp; 
					<a className="grey-text text-lighten-4 right" href="https://www.ambron.it" target="_blank">www.ambron.it</a>
					</Grid>
				</Grid>
			</Container>			
		</footer>
	);
}

export default Footer;