import React from 'react';
import * as yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'

import { appConfig } from 'config/appConfig';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from 'components/UI/Alert';
import MediaComponent from 'components/UI/Media/MediaComponent';
import RevisionComponent from 'components/UI/RevisionComponent';

import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';

import { 
  Save as SaveIcon, 
  Cancel as CancelIcon,
  DeleteForever as DeleteForeverIcon,
  SentimentVerySatisfiedOutlined
} from '@material-ui/icons';
import { contravvenzioneConsts } from '../../actions/ContravvenzioneAction';




const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box>{children}</Box>} */}
      <Box>{children}</Box>
    </Typography>
  );
}


const CustomerDetail = props => {
  const [state, setState] = React.useState({
    itemId: props.match.params.itemId,
    mainTabs: 0,
    path: `${process.env.REACT_APP_URL}/lawyer-one/customers`,
    mediaPath: `/lawyer-one/clienti`,
  })


  /**
   * ComponentDidMount
   */
  React.useEffect(() => {
    if( state.itemId <= 0 && document.forms[0] )
      document.forms[0].reset()
    
    props.initialize()

    if( state.itemId > 0 )
      axiosGetItem()
    else
      resetItem()
  },[])

  /**
   * Axios
   */
  const resetItem = () => {
    props.resetItem()
    window.scroll(0, 0)
  }

  const axiosGetItem = () => {
    props.load(state.itemId)
    window.scroll(0, 0)
  }

  const axiosCreateItem = (data) => {
    if( !data )
      data = props.item
    props.create(data, props)
    window.scroll(0, 0)
  }

  const axiosUpdateItem = (data) => {
    if( !data )
      data = props.item
    props.update(props.item.id, data, props)
    window.scroll(0, 0)
  }

  /**
   * Handle
   */
  const handleChange = (e) => {
    var item = Object.assign({}, props.item)

    switch (e.target.name) {
      case 'status':
        item[e.target.name] = ( e.target.checked ) ? 1 : 0
      break
      default: 
        item[e.target.name] = e.target.value
        console.log(e.target.name, e.target.value)
      break
    }
    props.setItem(item)
  }

  const handleAutocomplete = (key, value) => {
    var item = Object.assign({}, props.item);
    switch(key) {
      default: item[key] = value; break;
    }
    props.setItem(item);
  }

  const handleSubmit = () => {
    if (props.item && props.item.id > 0)
      axiosUpdateItem()
    else
      axiosCreateItem()
    window.scrollTo(0, 0);
  }

  const handleMainTab = (event, newValue) => {
    setState({
      ...state,
      mainTabs: newValue
    });
  }


  /**
   * Validate
   */
  const formik =  useFormik({
    initialValues: {
      owner_user_id: props.item.owner_user_id || "",
      type: props.item.type || "",
      referent: props.item.referent || "",
      company: props.item.company || "",
      taxcode: props.item.taxcode || "",
      vat: props.item.vat || "",
      
      country: props.item.country || "Italia",
      state: props.item.state || "",
      zipcode: props.item.zipcode || "",
      city: props.item.city || "",
      address: props.item.address || "",
      
      phone: props.item.phone || "",
      mobile_phone: props.item.mobile || "",
      // email: props.customer.email || "",
    },
    validationSchema: yup.object().shape({
      owner_user_id: yup.string().required('Campo non valido'),
      type: yup.string().required('Campo non valido'),
      referent: (props.item.type != 'persona_fisica') ? yup.string().required('Campo non valido') : null,
      company: (props.item.type != 'persona_fisica') ? yup.string().required('Campo non valido') : null,
      taxcode: (props.item.type == 'persona_fisica' || props.item.type == 'ditta_individuale' || props.item.type == 'libero_professionista') ? 
        yup.string()
        .required('Campo non valido')
        .matches(
          /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/,
          "Codice Fiscale non valido"
        ) : 
        null,
      vat: (props.item.type != 'persona_fisica') ? 
        yup.string()
        .required('Campo non valido')
        .matches(
          /^IT[0-9]{11}$/,
          "P.IVA non valida"
        ) : 
        null,
      country: yup.string().required('Campo non valido'),
      state: yup.string().required('Campo non valido'),
      zipcode: yup.string().required('Campo non valido').min(5, 'Numero di caratteri non valido').max(5, 'Numero di caratteri non valido'),
      city: yup.string().required('Campo non valido'),
      address: yup.string().required('Campo non valido'),

      phone: yup.string().required('Campo non valido'),
      mobile_phone: yup.string().required('Campo non valido'),
      // company_email: yup.string().required('Campo non valido').email('Inserire una mail valida'),
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  /**
  * Render
  */
  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="page-title">
        { (state.itemId == 0) ? 'Nuova' : 'Modifica' } Anagrafica
      </div>

      <Grid container className="row rowSpacing">
        <Grid item md={4}>
          <Box mb={3}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Generale - ID:{" "}
                  {props.item.id || "0"}
                </Typography>

                <Autocomplete
                  id="owner_user_id"
                  name="owner_user_id"
                  options={props.customers || []}
                  getOptionLabel={(option) => {
                    console.log('option label', option)
                    if( option )
                      return `[${option.id}] ${option.last_name} ${option.first_name}`;
                    return '';
                  }}
                  renderInput={(params) => 
                    <TextField {...params} 
                      label="Cliente"
                      margin="dense"
                      fullWidth={true}
                      variant={appConfig.inputVariant}
                      error={formik.errors.owner_user_id ? true : false}
                      helperText={formik.errors.owner_user_id}
                    />
                  }
                  value={(props.customers) ? props.customers.find(option => option.id === formik.values.owner_user_id) || null : null}
                  onChange={(e, option) => {
                    if( option ){
                      handleAutocomplete('owner_user_id', option.id)
                      formik.setFieldValue('owner_user_id', option.id)
                    }
                  }}
                  disabled={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}    
                />
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item md={8}>
          <Box mb={3}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Dettagli
                </Typography>
                
                <TextField
                  select
                  id="type"
                  name="type"
                  label="Tipologia utente"
                  value={formik.values.type}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.type ? true : false}
                  helperText={formik.errors.type}
                >
                  <MenuItem value="">
                    -
                  </MenuItem>
                  {props.types && props.types.map((option,index) =>
                    <MenuItem key={index} value={option.key}>
                      {option.value}
                    </MenuItem>
                  )}
                </TextField>

                <TextField
                  id="referent"
                  name="referent"
                  label="Nome e Cognome del referente"
                  value={formik.values.referent}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.referent ? true : false}
                  helperText={formik.errors.referent}
                />

                {props.item.type != 'persona_fisica' &&
                <>
                <TextField
                  id="company"
                  name="company"
                  label="Ragione Sociale"
                  value={formik.values.company}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.company ? true : false}
                  helperText={formik.errors.company}
                />

                <TextField
                  id="vat"
                  name="vat"
                  label="P.IVA"
                  value={formik.values.vat}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.vat ? true : false}
                  helperText={formik.errors.vat}
                />
                </>
                }

                {(props.item.type == 'persona_fisica' || props.item.type == 'ditta_individuale' || props.item.type == 'libero_professionista') &&
                <TextField
                  id="taxcode"
                  name="taxcode"
                  label="Codice fiscale"
                  value={formik.values.taxcode}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.taxcode ? true : false}
                  helperText={formik.errors.taxcode}
                />
                }

                <TextField
                  id="country"
                  name="country"
                  label="Paese"
                  value={formik.values.country}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.country ? true : false}
                  helperText={formik.errors.country}
                  disabled
                />

                <TextField
                  select
                  id="state"
                  name="state"
                  label="Provincia"
                  value={formik.values.state}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.state ? true : false}
                  helperText={formik.errors.state}
                >
                  <MenuItem value="">
                    -
                  </MenuItem>
                  {props.states && Object.entries(props.states).map(([key, value]) =>
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  )}
                </TextField>

                <TextField
                  id="zipcode"
                  name="zipcode"
                  label="Codica Postale"
                  value={formik.values.zipcode}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.zipcode ? true : false}
                  helperText={formik.errors.zipcode}
                />

                <TextField
                  id="city"
                  name="city"
                  label="Città"
                  value={formik.values.city}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.city ? true : false}
                  helperText={formik.errors.city}
                />

                <TextField
                  id="address"
                  name="address"
                  label="Indirizzo"
                  value={formik.values.address}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.address ? true : false}
                  helperText={formik.errors.address}
                />

                <TextField
                  id="phone"
                  name="phone"
                  label="Telefono"
                  value={formik.values.phone}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.phone ? true : false}
                  helperText={formik.errors.phone}
                />

                <TextField
                  id="mobile_phone"
                  name="mobile_phone"
                  label="Cellulare"
                  value={formik.values.mobile_phone}
                  onChange={e => {
                    handleChange(e);
                    formik.handleChange(e)
                  }}
                  // onBlur={formik.handleBlur}
                  variant={appConfig.inputVariant}
                  margin="dense"
                  fullWidth
                  error={formik.errors.mobile_phone ? true : false}
                  helperText={formik.errors.mobile_phone}
                />
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      {state.itemId > 0 &&
      <RevisionComponent
        createdUser={props.item.createdUser}
        createdAt={props.item.created_at}
        updatedUser={props.item.updatedUser}
        updatedAt={props.item.updated_at}
      />
      }

      <Grid container>
        <Grid item md={12}>
          <Button variant="contained" color="primary" type="submit">
            <SaveIcon /> Salva
          </Button>
          &nbsp;&nbsp;
          <Button 
            variant="contained" 
            href={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? `${process.env.REACT_APP_URL}/home` : `${state.path}${props.location.search}`}
          >
            <CancelIcon /> Chiudi
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CustomerDetail