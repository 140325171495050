import React from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';

import logo from 'logo.svg'

import {
  AppBar,
  Badge,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
} from '@material-ui/core'

import {
  AccountCircle,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  DesktopWindowsTwoTone,
} from '@material-ui/icons'


import { connect } from 'react-redux';
import UserAction from 'packages/User/actions/UserAction';
import NotificationAction from 'packages/LawyerOne/actions/NotificationAction';

import Sidebar from './Sidebar';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  appbar: {
    color: theme.palette.primary.main,
    background: theme.palette.white.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuNotifications: {
    '& .MuiMenu-list': {
      maxHeight: '60vh',
      overflowY: 'scroll',
      '& li': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '0.9rem'
      }
    }
  }
}));


const Header = (props) => {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuNotification, setMenuNotification] = React.useState(null);
  const open = Boolean(anchorEl);
  const openedMenuNotification = Boolean(menuNotification);

  const [sidebarState, setSidebarState] = React.useState({
    side: false
  });

  const handleChange = event => {
    setAuth(event.target.checked);
  };

  /**
   * Account Menu
   */
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Notifications
   */
  const openMenuNotification = event => {
    if( props.notifications && props.notifications.length > 0 )
      setMenuNotification(event.currentTarget)
  }

  const closeMenuNotification = () => {
    setMenuNotification(null)
  }

  const clickNotification = notification => {
    props.markAsReadNotification(notification.id)
    if( notification.data.action ){
      setTimeout(function(){
        window.location.href = notification.data.action
      }, 1000)
    }
  }

  /**
   * Sidebar
   */
  const toggleSidebar = (side) => {
    setSidebarState({ side: side });
  };
  
  /**
   * Render
   */
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <IconButton onClick={() => toggleSidebar(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title}>
            <a href="/home">
            <img src={logo} style={{width: '100px'}} />
            </a>
          </Typography>
          {/* <Typography variant="h6" className={classes.title}>
            {process.env.REACT_APP_NAME || 'Material-UI'}
          </Typography> */}
          {/* <Button color="inherit">Login</Button> */}

          {props.authenticated && (
            <div>
              <IconButton 
                aria-label={`${(props.notifications) ? props.notifications.length : '0'} notifiche`} 
                aria-controls="menu-notifications"
                aria-haspopup="true"
                onClick={(e) => openMenuNotification(e)}
                color="inherit"
                >
                {props.notifications && props.notifications.length > 0 &&
                  <Badge badgeContent={props.notifications.length} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                }
                {(!props.notifications || props.notifications.length <= 0) &&
                  <NotificationsIcon />
                }
              </IconButton>
              {props.notifications && props.notifications.length > 0 &&
              <Menu
                className={classes.menuNotifications}
                id="menu-notifications"
                anchorEl={menuNotification}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openedMenuNotification}
                onClose={() => closeMenuNotification()}
              >
                {props.notifications.map((obj) => 
                  <span key={obj.id}>
                  <MenuItem 
                    key={obj.id} 
                    onClick={()=>clickNotification(obj)}
                  >
                    <div>{obj.data.message}</div>
                    <div>{moment(obj.created_at).format('DD/MM/YYYY')}</div>
                  </MenuItem>
                  <Divider />
                  </span>
                )}
              </Menu>
              }



              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                <MenuItem>
                  <Link href={`${process.env.REACT_APP_URL}/user/users/${props.user.id}`}>Account</Link>
                </MenuItem>
                <MenuItem onClick={() => props.logout()}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      
      <Sidebar side={sidebarState.side} toggleSidebar={toggleSidebar} />
    </div>
  );
}

// export default Header;
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    notifications: state.lwo_notification.notifications,
    errorMessage: state.auth.message,
    alertMessage: state.alertMessage.message
  }
}

const mapDispatchToProps = (dispatch) => {
  let userAction = new UserAction();
  let notificationAction = new NotificationAction();
  return {
    markAsReadNotification: (id) => {
      dispatch( notificationAction.markAsRead(id) );
    },
    logout: () => {
      dispatch( userAction.logout() );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);