import axios from 'axios';
import qs from 'qs';
import BaseService from 'packages/Base/services/BaseService';

export default class UserService extends BaseService {

  constructor() {
    super('/users');
    this.api_url = process.env.REACT_APP_API_URL + '/users';

    // this.login = this.login.bind(this);
    // this.logout = this.logout.bind(this);
  }

  /**
   * Signin
   */
  signin(credentials) {
    let url = process.env.REACT_APP_API_URL + '/signin';

    return axios.post(url, credentials, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then( response => {
      return Promise.resolve(response);
    })
    .catch( error => {
      return Promise.reject(error);
    });
  }

  signinWithConfirmation(credentials) {
    let url = process.env.REACT_APP_API_URL + '/signin/with-confirmation';

    return axios.post(url, credentials, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then( response => {
      return Promise.resolve(response);
    })
    .catch( error => {
      return Promise.reject(error);
    });
  }

  /**
   * Auth
   */
  login(credentials) {
    // let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_OAUTH_URL;
    let url = process.env.REACT_APP_API_URL + '/auth/login';

    // return axios.get(process.env.REACT_APP_API_URL.replace('api/v1a','airlock') + '/csrf-cookie').then(response1 => {
    //   // login
    // });
    return axios.post(url, {
      // grant_type: process.env.REACT_APP_OAUTH_GRANT_TYPE,
      // client_id: process.env.REACT_APP_OAUTH_GRANT_CLIENT_ID,
      // client_secret: process.env.REACT_APP_OAUTH_GRANT_CLIENT_SECRET,
      // scope: process.env.REACT_APP_OAUTH_GRANT_SCOPE,
      email: credentials.email,
      password: credentials.password
      // provider: "admins"
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then( response => {
      return Promise.resolve(response);
    })
    .catch( error => {
      return Promise.reject(error);
    });
  }

  logout() {
    let url = process.env.REACT_APP_API_URL + '/auth/logout';
    let config = this.checkAuthApi();

    return axios.get(url, {
      ...config
    })
    .then(response => {
      localStorage.removeItem('user');
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
  }



  /**
   * Password Reset
   */
  passwordReset_emailSend(email) {
    let url = process.env.REACT_APP_API_URL + '/auth/password/reset';
    // let config = this.checkAuthApi();

    return axios.post(url, {
      email: email
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
  }

  passwordReset_passwordSend(credentials) {
    let url = process.env.REACT_APP_API_URL + '/auth/password/reset/change';
    // let config = this.checkAuthApi();

    return axios.post(url, {
      email: credentials.email,
      password: credentials.password,
      token: credentials.token
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
  }
}