import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux';

import index from 'index.png'
import PageLoaderActions from 'actions/PageLoaderActions'

import {
  Button,
  Container,
  Grid,
  IconButton,
} from '@material-ui/core'

import {
  AccountCircle,
  ContactMail as ContactMailIcon,
  Mail as MailIcon,
  AlternateMail as AlternateMailIcon,
  Language as LanguageIcon,
  PanTool as PanToolIcon,
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    color: theme.palette.black.main,
    padding: 24
  },
  wrapSx: {
    textAlign: 'center'
  },
  title: {
    // textAlign: 'center'
    marginBottom: 48
  },
  link: {
    display: 'block',
    marginTop: '30px',
    color: '#ffffff !important',
    '&:visited, &:hover': {
      color: '#ffffff !important',
    }
  },
  card: {
    // display: 'flex',
    // alignItems: 'center',
    color: theme.palette.white.main,
    backgroundColor: 'transparent',
    padding: 12,
    boxShadow: '0 0 5px #fff',
    height: '15vw',
    '&:hover': {
      transform: 'scale(1.02, 1.02)'
    },
    '& .cardContent': {
      display: 'block',
      width: '100%',
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1rem',
      textDecoration: 'none'
    },
    '& .icon': {
      textAlign: 'center',
      marginBottom: 24
    },
    '& a, & a:visited, & .hrefTitle, & .hrefTitle:visited': {
      color: theme.palette.white.main,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1rem',
      textDecoration: 'none'
    },
    '& .hrefBlock': {
      display: 'block',
      width: '100%',
      height: '100%'
    }
  }
}), {name: 'Index'});

const PrivacyPolicy = props => {
  const classes = useStyles()
  const [html, setHtml] = React.useState("")

  React.useEffect(() => {
    props.pageLoaderShow();
    axios.get(`${process.env.REACT_APP_API_URL}/lawyer-one/privacy-html`, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
    .then(response => {
      setHtml(response.data.data);
      props.pageLoaderHide();
    })
    .catch(error => {
      console.log(error)
      props.pageLoaderHide();
    })
  }, [])


  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12}>
              {/* <h1 className={classes.title}>Privacy Policy</h1> */}
              <div dangerouslySetInnerHTML={{ __html: html }} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    pageLoaderShow: () => {
      dispatch(PageLoaderActions.show())
    },
    pageLoaderHide: () => {
      dispatch(PageLoaderActions.hide())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy)