import { connect } from 'react-redux';

import RoleAction from '../../actions/RoleAction';

import RoleDetailComponent from '../../components/Role/RoleDetail';



const mapStateToProps = state => {
  return {
    item: state.role.item
  };
};
const mapDispatchToProps = dispatch => {
  let action = new RoleAction();
  return {
    initialize: (params) => {
			dispatch(action.initialize(params));
		},
    setItem: object => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: id => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.create(postData, ownProps));
    },
    update: (id, postData, ownProps) => {
      dispatch(action.update(id, postData, ownProps));
    },
    remove: id => {
      dispatch(action.remove(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleDetailComponent);