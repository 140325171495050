import React from 'react';
import { connect } from 'react-redux';

import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/routes';

import UserList from './UserList';
import UserDetail from './UserDetail';

class UserPage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { authenticated } = this.props;
		return(
			<Switch>
				{this.props.authUser && this.props.authUser.role && (this.props.authUser.role.name == 'Super Admin' || this.props.authUser.role.name == 'Admin') &&
				<ProtectedRoute exact path="/user/users" authenticated component={UserList} />
				}
				<ProtectedRoute path="/user/users/:itemId" authenticated component={UserDetail}/>
			</Switch>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		authUser: state.auth.user
	}
}

export default connect(mapStateToProps)(UserPage);