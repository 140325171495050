import { userConsts } from '../actions/UserActions';
import UserAction from '../actions/UserAction';

let action = new UserAction();
const initialState = action.checkAuthentication();

const AuthReducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case userConsts.AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        message: 'Autenticazione eseguita con successo',
        user: (action.user) ? action.user : null
      };
    case userConsts.AUTHENTICATION_ERROR:
      return {
        ...state,
        authenticated: false,
        message: action.message
      }
    case userConsts.UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false,
        message: action.message
      }
    default:
      return state
  }
}

export default AuthReducer;