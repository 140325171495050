import BaseAction from 'packages/Base/actions/BaseAction';
import RoleService from '../services/RoleService';
import AlertMessageActions from 'actions/AlertMessageActions';

export const roleConsts = {
	INITIALIZE: 'ROLE_INITIALIZE',
	RESET: 'ROLE_RESET',
	SET_SEARCH: 'ROLE_SET_SEARCH',
	SEARCHED: 'ROLE_SEARCH',
	LOADED: 'ROLE_LOADED',
	CREATED: 'ROLE_CREATED',
	UPDATED: 'ROLE_UPDATED',
	FIELDS_UPDATED: 'ROLE_FIELDS_UPDATED',
	REMOVED: 'ROLE_REMOVED',
	LIST_ERROR: 'ROLE_LIST_ERROR',
	ITEM_ERROR: 'ROLE_ITEM_ERROR',
}

export default class RoleAction extends BaseAction {

	constructor() {
		super();
		this.service = new RoleService();
		this.consts = roleConsts;
		this.redirect = `/user/roles`; //${process.env.REACT_APP_URL}
	}
	
	/**
	 * Initialize
	 */
	initialize(params) {
    return (dispatch) => {
      this.service.initialize(params)
      .then(response => {
        dispatch({
          type: this.consts.INITIALIZE,
          permissions: response.data.permissions,
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }
  
}