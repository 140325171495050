import React, { Component } from "react"
import Dropzone from "react-dropzone"
import moment from "moment"

import Alert from 'components/UI/Alert';
import BaseService from "packages/Base/services/BaseService"
import MediaService from "packages/Base/services/MediaService"
// import { render } from "@testing-library/react"


import {
  Typography,
  Grid,
  Button,
  Box,
  Card,
  CardContent,
  // InputLabel,
  // TextField,
  // Tabs,
  // Tab,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';

import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
	DeleteForever as DeleteForeverIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  InsertDriveFile as InsertDriveFileIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';


const MediaTable = props => {
  return(
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Documento</TableCell>
          <TableCell>Data</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.collection.map((obj, index) =>
        <TableRow key={index}>{console.log()}
          <TableCell>
            <a href={obj.url} target="_blank">
              {(obj.mime_type === 'image/jpeg' || obj.mime_type === 'image/png' || obj.mime_type === 'image/gif') &&
                <img src={obj.url} width="100"/>
              }
              {/* {obj.mime_type === 'application/pdf' &&
                <InsertDriveFileIcon />
              } */}
              {(obj.mime_type !== 'image/jpeg' && obj.mime_type !== 'image/png' && obj.mime_type !== 'image/gif') &&
                <InsertDriveFileIcon />
              }
            </a>
          </TableCell>
          <TableCell>{obj.file_name}</TableCell>
          <TableCell>
            {moment(obj.created_at).format('DD/MM/YYYY HH:mm')}
          </TableCell>
          <TableCell>
            <Button color="primary" size="small" variant="contained" href={obj.url} target="_blank">
              <GetAppIcon />
            </Button>
            &nbsp;&nbsp;
            <Button color="secondary" size="small" variant="contained" onClick={()=>props.onRemove(obj.id)}>
              <DeleteForeverIcon />
            </Button>
          </TableCell>
        </TableRow>
        )}
        
      </TableBody>
    </Table>
  )
}


const useDropZoneComponentStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const DropZoneComponent = props => {
  const classes = useDropZoneComponentStyles();

  return(
    <section>
      <h4>Carica documenti</h4>
      
      {props.message.error != "" && props.message.message != "" &&
      <div className={classes.root}>
        <Alert severity={props.message.error} message={props.message.message} />
      </div>
      }

      <div className="dropzone">
        <Dropzone
          onDrop={(acceptedFiles, rejectedFiles) =>
            props.onDrop(acceptedFiles, rejectedFiles)
          }
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p style={{ border: "dashed 1px green", padding: "15px" }}>
                  Trascina i file qui, o clicca per aprire la finestra di
                  caricamento
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <aside>
        <ul>
          {props.upload &&
            props.upload.map(f => (
              <li key={f.name}>
                {f.name} - {f.size} bytes
              </li>
            ))}
        </ul>
      </aside>
    </section>
  )
}



const MediaComponent = props => {
  
  const [state, setState] = React.useState({
    itemId: props.itemId,
    url: props.url,
    collections: props.collections || {files: [], gallery: []},
    // collections: {
    //   files: [],
    //   gallery: []
    // },
    upload: [],
    maxUpload: 10,
    error: "",
    message: "",
  })

  /**
   * ComponentDidMount
   */
  React.useEffect(() => {
    axiosGetAll()
  }, [])

  const axiosGetAll = () => {
    let service = new BaseService(props.url);

    let requestCollections = [];
    for( let [key, collection] of Object.entries(props.collections) ){
      requestCollections.push(key.toString())
    }

    service
    .mediaMultiAll(props.itemId, {
      itemId: props.itemId,
      collections: requestCollections
    })
    .then(response => {
      setState({
        ...state,
        collections: response.data.items,
        error: '',
        message: ''
      });
    })
    .catch(error => {
      setState({
        ...state,
        error: 'error',
        message: error.response.data.message
      });
    });
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    let upload = state.upload

    if (rejectedFiles.length > 0) {
      alert("Alcuni tipi di file non sono accettati")
    } else if (upload.length > state.maxUpload) {
      alert("Caricare massimo 10 file alla volta")
    } else {
      const formData = new FormData();
      acceptedFiles.map(file => {
        formData.append("files[]", file, file.name)
        upload.push(file)
      });
      formData.append("itemId", props.itemId)

      setState({ ...state, upload: upload })

      // let service = new MediaService(props.url);
      let service = new BaseService(props.url)
      service
      .mediaMultiUpload(props.itemId, formData)
      .then(response => {
        setState({
          ...state,
          error: response.data.code == 200 ? 'success' : 'error',
          message: response.data.message
        });
        axiosGetAll();
      })
      .catch(error => {
        setState({
          ...state,
          error: 'error',
          message: error.response.data.message
        });
        console.log(error)
      })
    }
  }

  const onRemove = (mediaId) => {
    // let baseService = new BaseService(this.state.url);
    let service = new MediaService();
    service
    .mediaRemove(
      // this.state.itemId,
      mediaId
    )
    .then(response => {
      setState({
        ...state,
        error: response.data.code == 200 ? 'success' : 'error',
        message: response.data.message
      });
      axiosGetAll();
    })
    .catch(error => {
      setState({
        ...state,
        error: 'error',
        message: error.response.data.message
      });
      console.log(error);
    });
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Allegati e documenti correlati
        </Typography>
        {/* <hr/> */}
        
        {Object.entries(state.collections).map(([key, collection]) => 
        <Box key={key}>
          {/* <h4>{key.charAt(0).toUpperCase() + key.slice(1)}</h4> */}
          
          <MediaTable 
            collection={collection} 
            onRemove={(mediaId) => onRemove(mediaId)}
            />
        </Box>
        )}
        
        

        <Box>
          <DropZoneComponent 
            upload={state.upload}
            onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}
            message={{
              message: state.message,
              error: state.error
            }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default MediaComponent