import axios from 'axios';
import qs from 'qs';
import { appConfig } from 'config/appConfig';

export default class BaseService {

  constructor(url) {
    this.api_url_base = process.env.REACT_APP_API_URL;
    this.api_url = process.env.REACT_APP_API_URL + (url || '');

    this.checkAuthApi = this.checkAuthApi.bind(this);
    this.search = this.search.bind(this);
    this.load = this.load.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.remove = this.remove.bind(this);
  }

  setUrl(url) {
    this.api_url = process.env.REACT_APP_API_URL + (url || '');
  }


  checkAuthApi(json) {
    let token = localStorage.getItem('access_token') || null;
    // let token = sessionStorage.getItem('access_token') || null;

    if (token) {
      if (json)
        return {
          headers: {
            ...appConfig.api.headers,
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      else
        return {
          headers: {
            ...appConfig.api.headers,
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        }
    } else {
      // throw "No token saved!"
      return {
        headers: {
          ...appConfig.api.headers,
          'Content-Type': 'application/json',
        }
      }
    }
  }




  /**
   * Initialize
   */

  initialize(params) {
    let config = this.checkAuthApi();
    let axiosConfig = {}

    if( params != null ) {
      axiosConfig = {
        params,
        paramsSerializer: function(params) {
          return qs.stringify(params, {
            encode: false
          });
        },
        ...config
      }
    }
    else {
      axiosConfig = {
        ...config
      }
    }

    return axios.get(this.api_url + '/initialize', axiosConfig)
    .then(response => {
      return response;
    }).catch(error => {
      return Promise.reject(error);
    });
  }

	/**
   * Main Services
   */

  search(params, count) {
    let config = this.checkAuthApi();
    if( count != null )
      Object.assign(params, {count: count});

    return axios.get(this.api_url, {
      // params: {
      //   filter: filter,
      //   count: count
      // },
      params,
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          encode: false
        });
      },
      ...config
    }).then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  load(id) {
    let config = this.checkAuthApi();

    return axios.get(this.api_url + '/' + id, {
      ...config
    }).then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  createPost(postData) {
    let config = this.checkAuthApi();

    return axios.post(this.api_url, postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  create(postData) {
    let config = this.checkAuthApi('json');

    return axios.post(this.api_url, postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  // update(id, postData) {
  //   let config = this.checkAuthApi();
  //   config = {
  //     ...config,
  //     onUploadProgress: progressEvent => console.log(progressEvent.loaded)
  //   };
  //   return axios.put(this.api_url + '/' + id, postData, config)
  //     .then(response => {
  //       return Promise.resolve(response);
  //     }).catch(error => {
  //       return Promise.reject(error);
  //     });
  // }

  update(id, postData) {
    let config = this.checkAuthApi('json');
    config = {
      ...config,
      onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    };
    return axios.put(this.api_url + '/' + id, postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  updatePost(id, postData) {
    let config = this.checkAuthApi();
    postData.append('_method', 'PUT');

    return axios.post(this.api_url + '/' + id, postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  remove(id) {
    let config = this.checkAuthApi();

    return axios.delete(this.api_url + '/' + id, {
      ...config
    }).then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  updateFields(id, postData) {
    let config = this.checkAuthApi('json');
    // config.headers = {
    //   ...config.headers,
    //   'Content-type' : 'application/form-url-encode'
    // }
    // postData['_method'] = 'PUT';

    return axios.put(this.api_url + '/update-fields/' + id, postData, config)
    .then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  /**
   * Sort
   */

  sortList(params) {
    let config = this.checkAuthApi('json');

    return axios.get(this.api_url + '/sort', {
      params,
      ...config
    }).then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  sortItems(postData) {
    let config = this.checkAuthApi('json');

    return axios.put(this.api_url + '/sort', postData, config)
    .then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  /**
   * Media Single
   */
  mediaSingleUpload(itemId, postData) {
    let config = this.checkAuthApi();

    return axios.post(this.api_url + '/' + itemId + '/single-media', postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  mediaRemove(itemId, mediaId) {
    let config = this.checkAuthApi();

    return axios.delete(this.api_url_base + '/media/' + mediaId, {
        ...config
      })
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  /**
   * Media Multiple
   */
  mediaSingleUpload(itemId, postData) {
    let config = this.checkAuthApi();

    return axios.post(this.api_url + '/' + itemId + '/single-media', postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  mediaMultiUpload(itemId, postData) {
    let config = this.checkAuthApi();

    return axios.post(this.api_url + '/' + itemId + '/media', postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  mediaMultiAll(itemId, params) {
    let config = this.checkAuthApi();

    // return axios.get(appConfig.api.url + '/users', {
    return axios.get(this.api_url + '/' + itemId + '/media', {
      params,
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          encode: false
        });
      },
      ...config
    }).then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  mediaMultiSort(postData) {
    let config = this.checkAuthApi('json');

    return axios.put(this.api_url + '/media/sort', postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  mediaUpdateDetails(mediaId, postData) {
    let config = this.checkAuthApi('json');
    // postData.append('_method', 'PUT');

    return axios.put(this.api_url + '/media/update-details/' + mediaId, postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }


  /**
   * Export
   */
  export(params) {
    let config = this.checkAuthApi();
    let axiosConfig = {}

    if( params != null ) {
      axiosConfig = {
        params,
        paramsSerializer: function(params) {
          return qs.stringify(params, {
            encode: false
          });
        },
        ...config
      }
    }
    else {
      axiosConfig = {
        ...config
      }
    }

    return axios.get(this.api_url + '/export', axiosConfig)
    .then(response => {
      return response;
    }).catch(error => {
      return Promise.reject(error);
    });
  }
}
