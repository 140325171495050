import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';
import BaseAction from 'packages/Base/actions/BaseAction';
import InitService from '../services/InitService';

export const initConsts = {
	INITIALIZE: 'LWO_INIT_INITIALIZE',
}

export default class InitAction extends BaseAction {

	constructor() {
		super();
		this.service = new InitService();
		this.consts = initConsts;
		this.redirect = '/lawyer-one/init';
  }
  
  init(params) {
    return (dispatch) => {
      this.service.init(params)
      .then(response => {
        dispatch({
          type: this.consts.INITIALIZE,
          currUserId: response.data.curr_user_id,
          currCustomerId: response.data.curr_cliente_id
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }
}