import React, { Component } from 'react';
// import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Header from './MainLayout/Header';
import Footer from './MainLayout/Footer';
import PageLoader from '../UI/PageLoader';
import AlertMessage from '../UI/AlertMessage';

// const Breadcrumbs = () => <Route path="*" render={props => {
//   let parts = props.location.pathname.split("/");
//   const place = parts[parts.length-1];
//   parts = parts.slice(1, parts.length-1);
//   return(
//     <ol className="breadcrumb">
//         <li className="breadcrumb-item"><a href="/admin">Home</a></li>
//         {parts.map(crumb)}
//         <li className="breadcrumb-item">{place}</li>
//     </ol>
//   )}} />

// const crumb = (part, partIndex, parts) => {
//   const path = ['', ...parts.slice(0, partIndex+1)].join("/");
//   return (
//     <li key={partIndex} className="breadcrumb-item">
//       {part}
//     </li>
//   )}

const useStyles = makeStyles(theme => ({
  "@global": {
    'html, body': {
      fontFamily: "'Roboto', sans-serif",
      fontSize: 14
    },
    '.pulsantiera': {
      display: 'flex',
      margin: '0 -6px',
      padding: 0,
      '& li': {
        listStyle: 'none',
        padding: '0 6px',
        marginBottom: 12
      }
    },
    '.error': {
      color: '#bf1650',
      '&::before': {
        display: 'inline',
        content: "⚠ ",
      }
    },
  },
  app: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    background: theme.palette.background.main,
    // background: theme.palette.background.gradient,
    // '&::before': {
    //   display: 'block',
    //   content: '""',
    //   position: 'absolute',
    //   top: '0',
    //   left: '0',
    //   right: '0',
    //   width: '100%',
    //   height: '300px',
    //   background: theme.palette.secondary.rgba10,
    //   // zIndex: -1
    // }
  },
  main: {
    position: 'relative',
    padding: '0 0 3rem 0',
    flexGrow: 1,
  },
}), {name: 'MainLayout'});

const MainLayout = (props) => {
  const classes = useStyles();

  return(
    <React.Fragment>
      <PageLoader />
      <AlertMessage />
      <div className={classes.app}>
        <Header />
        <main className={classes.main}>
          <Container maxWidth={false}>
            {props.children}
          </Container>
        </main>
        <Footer/>
      </div>
    </React.Fragment>
  );
}
export default MainLayout;