import { permissionConsts as consts } from '../actions/PermissionAction';

let initialState = {
  items: [],
  totItems: 0,
  filter: {
    id: {
      key: 'id',
      compare: '=',
      value: ''
    },
    name: {
      key: 'name',
      compare: 'like',
      value: ''
    },
    guard_name: {
      key: 'guard_name',
      compare: 'like',
      value: ''
    },
    order: ['id', 'asc'],
    page: 1,
    num_per_page: 25,
  },
  item: {},
};

const PermissionReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case consts.RESET:
      return {
        ...state,
        item: initialState.item,
      };
    case consts.SET_SEARCH:
      return {
        ...state,
        filter: action.filter
      };
    case consts.SEARCHED:
      return {
        ...state,
        items: action.items,
        totItems: action.totItems
      };
    case consts.LOADED:
      return {
        ...state,
        item: action.item,
      };
    case consts.CREATED:
      return {
        ...state,
        item: action.item,
      };
    case consts.UPDATED:
      return {
        ...state,
        item: action.item,
      };
    case consts.REMOVED:
      return {
        ...state,
        item: initialState.item,
      };
    default:
      return state
  }
}

export default PermissionReducer;
