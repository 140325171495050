import React, { Component } from 'react';

import {
  Button,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
	DeleteForever as DeleteForeverIcon,
	FiberManualRecord as FiberManualRecordIcon
} from '@material-ui/icons';

import CardTable from 'components/UI/Table/CardTable';

import { appConfig } from 'config/appConfig';

class UserList extends Component {
	constructor(props) {
		super(props);

		this.state = {
      path: `${process.env.REACT_APP_URL}/user/users`
    }
	}

	componentDidMount() {
		this.props.initialize();
		this.axiosItems();
	}


	/**
  * Axios
	*/
	getFilter() {
		let _filter = {};
		for( let i in this.props.filter )
			if( this.props.filter[i].value != '' )
				_filter[i] = this.props.filter[i];
		return _filter;
	}
	axiosItems() {
		let _filter = this.getFilter();
		this.props.search(_filter);
	}

	/**
	 * Handle
	 */
	onFilterChange(e) {
    let filter = Object.assign({}, this.props.filter);
		for( let i in filter )
			if( filter[i]['key'] == e.target.name )
				filter[i]['value'] = e.target.value;
    this.props.setSearch(filter);
	}

	onSearch() {
		this.axiosItems();
	}

	onChangePage(e, newPage) {
    let filter = this.props.filter;
		Object.assign(filter, {
			page: parseInt(newPage) + 1,
		});
		
		this.props.setSearch(filter);
		this.axiosItems();
  };

	onChangeRowsPerPage(event){
    let filter = this.props.filter;
		Object.assign(filter, {
      page: 1,
      num_per_page: parseInt(event.target.value, 10)
		});
		
		this.props.setSearch(filter);
		this.axiosItems();
  };

	onOrderChange(orderBy, orderHow) {
		let filter = this.props.filter;
		Object.assign(filter, {
			page: 1,
			order: (filter.order[0] == orderBy) ? [orderBy, orderHow] : [orderBy, 'ASC']
		});
		this.props.setSearch(filter);
		this.axiosItems();
  }

	onChangeStatus(id, params) {
		if(window.confirm("Sicuro di voler cambiare lo stato del numero " + id + "?")) {
			this.props.updateFields(id, {params}, this.getFilter(), 'true');
		}
	}

	onRemove(id) {
		if(window.confirm("Sicuro di voler eliminare il numero " + id + "?")) {
			this.props.remove(id, this.getFilter(), 'true');
		}
	}

	/**
	 * Render
	 */
	renderTableBody() {
    return(
      <TableBody>
        {this.props.items.map(item => (
          <TableRow key={item.id}>
            <TableCell component="th" scope="row">
              {item.id}
            </TableCell>
            <TableCell>{item.first_name}</TableCell>
            <TableCell>{item.last_name}</TableCell>
						<TableCell>{item.email}</TableCell>
						<TableCell>
							<Button>
								<FiberManualRecordIcon 
									className={"textStatus-" + item.status} 
									onClick={(id) => this.onChangeStatus(item.id, { status: (item.status == 1) ? 0 : 1 } )}
									/>
							</Button>
						</TableCell>
						<TableCell>{item.role && item.role.name}</TableCell>
            <TableCell>
              <Button color="primary" size="small" variant="contained" href={this.state.path + "/" + item.id}>
                <EditIcon /> Apri
              </Button>
              &nbsp;&nbsp;
              <Button color="secondary" size="small" variant="contained" onClick={() => this.onRemove(item.id)}>
                <DeleteForeverIcon /> Elimina
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
	}
	

	render() {
    const headCells = [
      { id: 'id', numeric: false, isSort: true, disablePadding: false, label: 'ID', type: 'text' },
      { id: 'first_name', numeric: false, isSort: true, disablePadding: false, label: 'Nome', type: 'text' },
			{ id: 'last_name', numeric: false, isSort: true, disablePadding: false, label: 'Cognome', type: 'text' },
			{ id: 'email', numeric: false, isSort: true, disablePadding: false, label: 'Email', type: 'text' },
			{ id: 'status', numeric: false, isSort: true, disablePadding: false, label: 'Status', type: 'select', data: (appConfig.status.numbers || []), dataOptions: {dataKey:"key", dataValue:"value"}},
			{ id: 'role', numeric: false, isSort: true, disablePadding: false, label: 'Ruolo', type: 'select', data: (this.props.roles || []), dataOptions: {dataKey:"id", dataValue:"name"} },
      { id: 'last', numeric: false, isSort: false, disablePadding: false, label: 'Modifica' },
		];
		
		return (
			<React.Fragment>
				<div className="page-title">Utenti</div>
				
				<CardTable 
					actionButtons={
						<React.Fragment>
							<Button size="small" color="primary" variant="contained" href={this.state.path + '/0'}>
								<AddCircleIcon /> Nuovo
							</Button>
						</React.Fragment>
					}
					totItems={this.props.totItems || 0}
					rowItems={this.props.items.length || 0}
					filter={this.props.filter}
					renderFilter={true}
					headCells={headCells}
					renderTableBody={() => this.renderTableBody()}
					onSearch={() => this.onSearch()}
					onFilterChange={(e) => this.onFilterChange(e)}
					onOrderChange={(orderBy, orderHow) => this.onOrderChange(orderBy, orderHow)}
					onChangePage={(e,newPage) => this.onChangePage(e,newPage)}
					onChangeRowsPerPage={(e) => this.onChangeRowsPerPage(e)}
					paginationColSpan={7}
				/>
			</React.Fragment>
		);
	}
}


export default UserList;