import { notificationConsts as consts} from '../actions/NotificationAction';

let initialState = {
  notifications: []
}

const NotificationReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case consts.INITIALIZE:
      return {
        ...state,
        notifications: action.notifications,
      };
    case consts.NOTIFICATION_MARK_AS_READ:
      return {
        ...state,
        notifications: action.notifications,
      };
    case consts.NOTIFICATIONS_MARK_AS_READ:
      return {
        ...state,
        notifications: action.notifications,
      };
    default:
      return state
  }
}

export default NotificationReducer;
