import React from 'react';
import * as yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'

import { appConfig } from 'config/appConfig';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from 'components/UI/Alert';
import MediaComponent from 'components/UI/Media/MediaComponent';

import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  MenuItem,
  FormControlLabel,
  InputLabel,
  TextField,
  Paper,
  Tabs,
  Tab
} from '@material-ui/core';

import { 
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Save as SaveIcon, 
  Cancel as CancelIcon,
  DeleteForever as DeleteForeverIcon
} from '@material-ui/icons';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box>{children}</Box>} */}
      <Box>{children}</Box>
    </Typography>
  );
}


const ContravvenzioneNew = props => {
  const [state, setState] = React.useState({
    itemId: props.match.params.itemId,
    mainTabs: 0,
    path: `${process.env.REACT_APP_URL}/lawyer-one/contravvenzioni`,
    mediaPath: `/lawyer-one/contravvenzioni`,
  })

  /**
   * ComponentDidMount
   */
  React.useEffect(() => {
    resetItem()
  },[])

  /**
   * Axios
   */
  const resetItem = () => {
    props.resetItem()
    window.scroll(0, 0)
  }

  const axiosGetItem = () => {
    props.load(state.itemId)
    window.scroll(0, 0)
  }

  /**
   * Handle
   */
  const handleChange = (e) => {
    // item
    var item = Object.assign({}, props.item)

    // form
    let formData = props.formData;
    if( !formData )
      formData = new FormData()

    switch (e.target.name) {
      case 'archived':
      case 'fattura':
        item[e.target.name] = ( e.target.checked ) ? 1 : 0
        formData.delete(e.target.name)
        formData.append(e.target.name, ( e.target.checked ) ? 1 : 0)
      break
      default: 
        item[e.target.name] = e.target.value
        formData.delete(e.target.name)
        formData.append(e.target.name, e.target.value)
      break
    }
    props.setItem(item)
    props.setFormData(formData)
  }

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader()
    const name = target.name;
    // const name = target.accept.includes('image') ? 'images' : 'videos'

    if( !target.files || !target.files[0] ){
      let item = Object.assign({}, props.item)
      item[name] = null
      props.setItem(item)
      return
    }

    fileReader.readAsDataURL(target.files[0])
    fileReader.onload = (e) => {
      // item
      let item = Object.assign({}, props.item)
      item[name] = e.target.result
      props.setItem(item)

      // form
      let formData = props.formData;
      if( !formData )
        formData = new FormData()
      
      formData.delete('file_verbale')
      formData.append('file_verbale', target.files[0], 'file_verbale')
      props.setFormData(formData)
      
        // this.setState((prevState) => ({
        //     [target.name]: [...prevState[name], e.target.result]
        // }))
    }
  }

  const handleAutocomplete = (key, value) => {
    var item = Object.assign({}, props.item);
    switch(key) {
      default: item[key] = value; break;
    }
    props.setItem(item);
  }

  const handleSubmit = (e) => {
    // const formData = new FormData(e.target);
    const formData = props.formData

    // let formData = new FormData();
    // formData.append('numero_protocollo', props.item.numero_protocollo)
    // formData.append('numero_verbale', props.item.numero_verbale)
    // formData.append('dipendente_nome', props.item.dipendente_nome)
    // formData.append('dipendente_ruolo_aziendale', props.item.dipendente_ruolo_aziendale)
    // formData.append('costo_commessa', props.item.costo_commessa)
    // formData.append('file_verbale', props.item.file_verbale)

    if (props.item && props.item.id > 0)
      props.update(props.item.id, formData, props)
    else
      props.create(formData, props)
    
    if(props.item && props.item.id > 0) 
      handleMainTab(e, 1)
    window.scrollTo(0, 0)
  }

  const handleMainTab = (event, newValue) => {
    props.setMainTab(newValue)
  }

  /**
   * Validate
   */
  const formik =  useFormik({
    initialValues: {
      numero_protocollo: props.item.numero_protocollo || "",
      numero_verbale: props.item.numero_verbale || "",
      centro_costo: props.item.centro_costo || "",
      wbs: props.item.wbs || "",
      dipendente_cognome: props.item.dipendente_cognome || "",
      dipendente_nome: props.item.dipendente_nome || "",
      dipendente_ruolo_aziendale: props.item.dipendente_ruolo_aziendale || "",
      file_verbale: props.item.file_verbale || ""
    },
    validationSchema: yup.object().shape({
      numero_protocollo: yup.string().required('Campo non valido'),
      numero_verbale: yup.string().required('Campo non valido'),
      centro_costo: yup.string().required('Campo non valido'),
      wbs: yup.string().required('Campo non valido'),
      dipendente_cognome: yup.string().required('Campo non valido'),
      dipendente_nome: yup.string().required('Campo non valido'),
      dipendente_ruolo_aziendale: yup.string().required('Campo non valido'),
      file_verbale: yup.string().required('Campo non valido'),
    }),
    enableReinitialize: true,
    onSubmit: e => handleSubmit(e)
  })


  /**
  * Render
  */
  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  return (
    <>
      <div className="page-title">
        Carica Nuova Contravvenzione
      </div>


      <Grid container className="row rowSpacing">
        <Grid item sm={12}>
          <Paper style={{marginBottom: "1rem"}}>
            <Tabs
              value={props.mainTab}
              indicatorColor="primary"
              textColor="primary"
              // onChange={(e, newValue)=>handleMainTab(e, newValue)}
              onChange={(e, newValue)=>props.setMainTab(newValue)}
              aria-label="disabled tabs example"
            >
              <Tab label="Step 1" {...a11yProps(0)} />
              <Tab label="Step 2" {...a11yProps(1)} disabled={(!props.item || props.item.id <= 0) ? true : false} />
            </Tabs>
          </Paper>

          <TabPanel value={props.mainTab} index={0}>
            <form onSubmit={e=>formik.handleSubmit(e)}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Dati contravvenzione
                  </Typography>

                  <TextField
                    id="numero_protocollo"
                    name="numero_protocollo"
                    label="Numero protocollo"
                    placeholder="Numero protocollo"
                    value={formik.values.numero_protocollo || ""}
                    onChange={e => {
                      handleChange(e);
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant={appConfig.inputVariant}
                    fullWidth
                    error={formik.errors.numero_protocollo ? true : false}
                    helperText={formik.errors.numero_protocollo}
                  />

                  <TextField 
                    id="numero_verbale"
                    name="numero_verbale"
                    label="Numero verbale"
                    placeholder="Numero verbale"
                    value={formik.values.numero_verbale || ""}
                    onChange={e => {
                      handleChange(e);
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant={appConfig.inputVariant}
                    fullWidth
                    error={formik.errors.numero_verbale ? true : false}
                    helperText={formik.errors.numero_verbale}
                  />

                  <TextField 
                    id="dipendente_cognome"
                    name="dipendente_cognome"
                    label="Cognome del dipendente"
                    placeholder="Cognome del dipendente"
                    value={formik.values.dipendente_cognome || ""}
                    onChange={e => {
                      handleChange(e);
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant={appConfig.inputVariant}
                    fullWidth
                    error={formik.errors.dipendente_cognome ? true : false}
                    helperText={formik.errors.dipendente_cognome}
                  />

                  <TextField 
                    id="dipendente_nome"
                    name="dipendente_nome"
                    label="Nome del dipendente"
                    placeholder="Nome del dipendente"
                    value={formik.values.dipendente_nome || ""}
                    onChange={e => {
                      handleChange(e);
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant={appConfig.inputVariant}
                    fullWidth
                    error={formik.errors.dipendente_nome ? true : false}
                    helperText={formik.errors.dipendente_nome}
                  />

                  <TextField 
                    id="dipendente_ruolo_aziendale"
                    name="dipendente_ruolo_aziendale"
                    label="Ruolo aziendale del dipendente"
                    placeholder="Ruolo aziendale del dipendente"
                    value={formik.values.dipendente_ruolo_aziendale || ""}
                    onChange={e => {
                      handleChange(e);
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant={appConfig.inputVariant}
                    fullWidth
                    error={formik.errors.dipendente_ruolo_aziendale ? true : false}
                    helperText={formik.errors.dipendente_ruolo_aziendale}
                  />

                  <TextField 
                    id="centro_costo"
                    name="centro_costo"
                    label="Centro di costo"
                    placeholder="Centro di costo"
                    value={formik.values.centro_costo || ""}
                    onChange={e => {
                      handleChange(e);
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant={appConfig.inputVariant}
                    fullWidth
                    error={formik.errors.centro_costo ? true : false}
                    helperText={formik.errors.centro_costo}
                  />

                  <TextField 
                    id="wbs"
                    name="wbs"
                    label="WBS"
                    placeholder="WBS"
                    value={formik.values.wbs || ""}
                    onChange={e => {
                      handleChange(e);
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant={appConfig.inputVariant}
                    fullWidth
                    error={formik.errors.wbs ? true : false}
                    helperText={formik.errors.wbs}
                  />

                  <label>Carica verbale</label>
                  <TextField 
                    id="file_verbale"
                    name="file_verbale"
                    // label="Carica verbale"
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={e => {
                      handleCapture(e);
                      formik.handleChange(e)
                    }}
                    margin="dense"
                    variant={appConfig.inputVariant}
                    fullWidth
                    error={formik.errors.costo_commessa ? true : false}
                    helperText={formik.errors.costo_commessa}
                  />

                </CardContent>
                <CardActions>
                  <Button variant="contained" color="primary" type="submit">
                    Avanti <ArrowForwardIcon />
                  </Button>
                  &nbsp;&nbsp;
                  <Button variant="contained" href={`${state.path}${props.location.search}`}>
                    <CancelIcon /> Chiudi
                  </Button>
                </CardActions>
              </Card>
            </form>
          </TabPanel>

          <TabPanel value={props.mainTab} index={1}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Carica allegati
                </Typography>
                { !props.item || props.item.id <= 0 &&
                  <Alert severity="warning" message="Devi prima completare lo step 1."/>
                }
                { props.item && props.item.id > 0 &&
                <>
                  <Alert severity="info" message="La pratica è stata salvata con successo. Puoi caricare allegati alla pratica o terminare l'operazione."/>
                  <MediaComponent 
                    itemId={props.item.id || 0}
                    url={state.mediaPath}
                    collections={{files:[]}}
                  />
                </>
                }
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" type="button" onClick={(e) => handleMainTab(e, 0)}>
                  <ArrowBackIcon /> Indietro
                </Button>
                &nbsp;&nbsp;
                <Button variant="contained" href={`${state.path}${props.location.search}`}>
                  <CancelIcon /> Chiudi
                </Button>
              </CardActions>
            </Card>
          </TabPanel>

        </Grid>
      </Grid>

    </>
  )
}

export default ContravvenzioneNew