import React from 'react';
import { appConfig } from 'config/appConfig';
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  Button,
  Grid,
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  MenuItem,
} from '@material-ui/core';

import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

import Autocomplete from '@material-ui/lab/Autocomplete';
import AutocompleteComponent from 'components/UI/Input/AutocompleteComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const FilterPanel = (props) => {
  const classes = useStyles();

  const handleFilterChange = event => {
    event.persist();
    props.onFilterChange(event);
  }

  return (
    <React.Fragment>
    {props.renderFilter &&
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="filter-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}><SearchIcon/> Filtro</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className="row rowSpacing">
          {props.headCells.map( (headCell, index) => (
            <React.Fragment key={index}>
              {headCell.type &&
                <Grid item md={3} key={index}>
                  { headCell.type == "text" &&
                    <TextField 
                      id={headCell.id}
                      name={headCell.id}
                      label={headCell.label}
                      onChange={handleFilterChange} 
                      defaultValue={ props.filter[headCell.id].value }
                      margin="dense"
                      fullWidth
                      variant={appConfig.inputVariant}
                      />
                  }
                  { headCell.type == "date" &&
                    <TextField 
                      id={headCell.id}
                      name={headCell.id}
                      label={headCell.label}
                      type="date"
                      onChange={handleFilterChange} 
                      defaultValue={ props.filter[headCell.id].value || "" }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="dense"
                      fullWidth
                      variant={appConfig.inputVariant}
                      />
                  }
                  { headCell.type == "date_range" &&
                  <React.Fragment>
                    <TextField 
                      id={headCell.id + '_from'}
                      name={headCell.id + '_from'}
                      label={`${headCell.label}: Dal`}
                      type="date"
                      onChange={handleFilterChange} 
                      defaultValue={ props.filter[`${headCell.id}_from`].value }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant={appConfig.inputVariant}
                      fullWidth
                      margin="dense"
                      />
                    <TextField 
                      id={headCell.id + '_to'}
                      name={headCell.id + '_to'}
                      label={`${headCell.label}: Al`}
                      type="date"
                      onChange={handleFilterChange} 
                      defaultValue={ props.filter[`${headCell.id}_to`].value }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant={appConfig.inputVariant}
                      fullWidth
                      margin="dense"
                      />
                  </React.Fragment>
                  }
                  { headCell.type == "select" &&
                    <TextField 
                      id={headCell.id}
                      name={headCell.id}
                      label={headCell.label}
                      onChange={handleFilterChange} 
                      value={ props.filter[headCell.id].value }
                      select
                      fullWidth
                      margin="dense"
                      variant={appConfig.inputVariant}
                      disabled={(headCell.visible !== false) ? false : true}
                    >
                      <MenuItem value="">
                        -
                      </MenuItem>
                      {headCell.data && headCell.data.map((obj,index) => (
                        <MenuItem key={index} value={obj[headCell.dataOptions.dataKey]}>
                          {obj[headCell.dataOptions.dataValue]}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  { headCell.type == "simple_select" &&
                    <TextField 
                      id={headCell.id}
                      name={headCell.id}
                      label={headCell.label}
                      onChange={handleFilterChange} 
                      value={ props.filter[headCell.id].value }
                      select
                      fullWidth
                      margin="dense"
                      variant={appConfig.inputVariant}
                      // disabled={(headCell.visible === false) ? false : true}
                    >
                      <MenuItem value="">
                        -
                      </MenuItem>
                      {headCell.data && Object.entries(headCell.data).map(([key,value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  { headCell.type == "autocomplete" &&
                    <Autocomplete
                      id={headCell.id}
                      name={headCell.id}
                      options={headCell.data || []}
                      // getOptionLabel={(option) => {
                      //   if( option )
                      //     return `[${option.id}] ${option.last_name} ${option.first_name}`;
                      //   return '';
                      // }}
                      getOptionLabel={headCell.getOptionLabel}
                      renderInput={(params) => 
                        <TextField {...params} 
                          label={headCell.label}
                          margin="dense"
                          fullWidth={true}
                          variant={appConfig.inputVariant}
                        />
                      }
                      value={(headCell.data) ? headCell.data.find(option => option.id === props.filter[headCell.id].value) || null : null}
                      // onChange={(e, option) => {
                      //   if( option ){
                      //     e.target.key = option.id
                      //     e.target.value = option.id
                      //   }
                      //     handleFilterChange
                      //     // this.handleAutocomplete('owner_user_id', option.id);
                      // }}
                      onChange={headCell.onChange}
                    />
                  }
                </Grid>
              }
            </React.Fragment>
          ))}
          <Grid item md={12}>
            <Button 
              size="small" 
              variant="contained" 
              color="primary"
              onClick={() => props.onSearch()} 
              >
              Cerca
            </Button>
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
    }
    </React.Fragment>
  );
}

export default FilterPanel;