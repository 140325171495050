export const appConfig = {
	title : process.env.REACT_APP_NAME,
	url : process.env.REACT_APP_URL,
	multisite : false,//process.env.REACT_APP_MULTISITE,
	api : {
			url : process.env.REACT_APP_API_URL,
			headers: {
				'Accept': 'application/json'
				// 'Content-Type': 'application/json'
				// 'Content-Type': 'multipart/form-data'
				// 'Content-Type': 'application/x-www-form-urlencoded'
				// "X-Requested-With": "XMLHttpRequest"
			}
	},
	oauth : {
		url : process.env.REACT_APP_OAUTH_URL,
		grant : {
			grant_type: process.env.REACT_APP_OAUTH_GRANT_TYPE,
			client_id: process.env.REACT_APP_OAUTH_GRANT_CLIENT_ID,
			client_secret: process.env.REACT_APP_OAUTH_GRANT_CLIENT_SECRET,
			scope: process.env.REACT_APP_OAUTH_GRANT_SCOPE,
		}
	},
	// title : 'React App',
	// url : '',
	// multisite : true,
	// api : {
	//     url : '',
	//     headers: {
	//         // 'Accept': 'application/json',
	//         // 'Content-Type': 'application/json'
	//         // 'Content-Type': 'multipart/form-data'
	//         // 'Content-Type': 'application/x-www-form-urlencoded'
	//         // "X-Requested-With": "XMLHttpRequest"
	//     }
	// },
	// oauth : {
	//     url : '',
	//     grant : {
	//         grant_type: 'password',
	//         client_id: '2',
	//         client_secret: '',
	//         scope: '*'
	//     }
	// },
	inputVariant: process.env.REACT_APP_INPUT_VARIANT,
	status: {
		strings: [
			{ key: 'published', value: 'Pubblicato' },
			{ key: 'disabled', value: 'Disattivo' }
		],
		numbers: [
			{ key: '1', value: 'Pubblicato' },
			{ key: '0', value: 'Disattivo' }
		],
		yesno: [
			{ key: '1', value: 'Si' },
			{ key: '0', value: 'No' }
		]
	},
	order: {
		by: [
			{ key: 'id', value: 'ID' },
			{ key: 'name', value: 'Nome' },
			{ key: 'date', value: 'Data' },
			{ key: 'order_seq', value: 'Sequenza' }
		],
		how: [
			{ key: 'asc', value: 'Crescente' },
			{ key: 'desc', value: 'Decrescente' }
		]
	},
	roles: [
		{ id : 'Admin', name: 'Admin' },
		{ id : 'User', name: 'User' },
		{ id: 'App', name: 'App' }
	],
	
	clientiTypes: {
		aziende: ['azienda', 'ditta_individuale', 'pubblica_amministrazione'],
		privati: ['persona_fisica','libero_professionista']
	}
}
