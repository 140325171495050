import { signupConsts as consts} from '../actions/SignupAction';

let initialState = {
  mainTab: 0,
  user: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",
    privacy: 0
  },
  customer: {
    type: "",
    referent: "",
    company: "",
    taxcode: "",
    vat: "",
    country: "Italia",
    state: "",
    zipcode: "",
    city: "",
    address: "",
    phone: "",
    mobile_phone: "",
    // email: "",
  },
}

const SignupReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case consts.INITIALIZE:
      const defaultSelectValue = {
        text: "-",
        id: ""
      }

      return {
        ...state,
        types: action.types,
        customers: action.customers,
        states: action.states
      };
    case consts.SET_MAIN_TAB:
      return {
        ...state,
        mainTab: action.index
      };
    case consts.SET_USER:
      return {
        ...state,
        user: action.model
      };
    case consts.SET_CUSTOMER:
      return {
        ...state,
        customer: action.model
      };
    case consts.SIGNUP:
      return {
        ...state,
        user: action.user,
        customer: action.customer,
        mainTab: action.nextStep
      };
    case consts.VERIFY_USER:
      return {
        ...state,
        mainTab: action.nextStep
      };
    case consts.RESEND_VERIFY_EMAIL:
      return {
        ...state,
        mainTab: action.nextStep
      };
    
    // case consts.CREATED_USER:
    //   return {
    //     ...state,
    //     user: action.user,
    //     mainTab: action.nextStep,
    //   };
    // case consts.CREATED_CUSTOMER:
    //   return {
    //     ...state,
    //     item: action.item,
    //     mainTab: action.nextStep,
    //   };

    default:
      return state
  }
}

export default SignupReducer;
