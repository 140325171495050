import React from "react";
import {
  // BrowserRouter as Router,
  // Switch,
  Route,
  Redirect
  // Link
} from "react-router-dom";

import AuthLayout from "./Layouts/AuthLayout";
import MainLayout from "./Layouts/MainLayout";
import FrontendLayout from "./Layouts/FrontendLayout/FrontendLayout";

export const ProtectedRoute = ({
  component: Component,
  authenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={matchProps =>
        authenticated === true ? (
          <React.Fragment>
          <Component {...matchProps} {...rest} />
          </React.Fragment>
        ) : (
          <Redirect 
            // to="/" 
            to={{
              pathname: '/',
              state: { from: matchProps.location },
            }}
          />
        )
      }
    />
  );
};

export const MainRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps =>
        authenticated === true ? (
          <MainLayout {...matchProps}>
            <Component {...matchProps} />
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { message: "Devi eseguire il login", error: 1, from: matchProps.location }
            }}
          />
        )
      }
    />
  );
};

export const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthLayout>
          <Component {...matchProps} />
        </AuthLayout>
      )}
    />
  );
};


export const FrontendRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps =>
        <FrontendLayout {...matchProps}>
          <Component {...matchProps} />
        </FrontendLayout>
      }
    />
  );
};
