import React from 'react';

import {
  TextField,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'

const AutocompleteComponent = (props) => {

  const [value, setValue] = React.useState(null);

  const flatProps = {
    options: props.data.map(option => option[props.dataOptions.dataValue]),
  };

  const handleChange = (event, value) => {
    if( !value || value === "" ) {
      event.target = {
        name: props.id,
        value: ""
      }
    }
    else {
      for( let i in props.data ) {
        if( props.data[i][props.dataOptions.dataValue] == value ) {
          event.target = {
            name: props.id,
            value: props.data[i][props.dataOptions.dataKey]
          }
          break;
        }
      }
    }
    props.onChange(event);
  }

  const getDefaultValue = (key) => {
    for( let i in props.data ) {
      if( props.data[i][props.dataOptions.dataKey] == key ) {
        console.log('selected', props.data[i][props.dataOptions.dataValue])
        return props.data[i][props.dataOptions.dataValue];
      }
    }
    return null;
  }

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        {...flatProps}        
        value={getDefaultValue(props.value)}
        id={props.id}
        debug
        renderInput={params => (
          <TextField 
            {...params}
            label={props.label}
            margin="normal"
            fullWidth
            />
        )}
        onChange={(event, value) => handleChange(event, value)}
      />
    </div>
  );

}

export default AutocompleteComponent;