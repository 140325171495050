import { connect } from 'react-redux';

import ContravvenzioneAction from '../../actions/ContravvenzioneAction';

import ContravvenzioneListComponent from '../../components/Contravvenzione/ContravvenzioneList';

const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		pageLoader: state.pageLoader.display,
		filter: state.lwo_contravvenzione.filter,
		items: state.lwo_contravvenzione.items,
    totItems: state.lwo_contravvenzione.totItems,
    customers: state.lwo_contravvenzione.customers,
    statusList: state.lwo_contravvenzione.status,
    exportUrl: state.lwo_contravvenzione.exportUrl
	}
}
const mapDispatchToProps = (dispatch) => {
  let action = new ContravvenzioneAction();
	return {
		initialize: (params) => {
      dispatch(action.initialize(params));
    },
    search: (params) => {
      dispatch(action.search(params));
    },
    setSearch: (params) => {
      dispatch(action.setSearch(params));
    },
    remove: (id, filter, count) => {
      dispatch(action.removeOnList(id, filter, count));
    },
    updateFields: (id, params, filter, count) => {
      dispatch(action.updateFields(id, params, filter, count));
    },
    export: (params) => {
      dispatch(action.export(params));
    },
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ContravvenzioneListComponent);