import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';
import BaseAction from 'packages/Base/actions/BaseAction';
import UserService from 'packages/User/services/UserService';
import CustomerService from '../services/CustomerService';

export const signupConsts = {
	INITIALIZE: 'LWO_SIGNUP_INITIALIZE',
  SET_MAIN_TAB: 'LWO_SIGNUP_SET_MAIN_TAB',

  SET_USER: 'LWO_SIGNUP_SET_USER',
  SET_CUSTOMER: 'LWO_SIGNUP_SET_CUSTOMER',
  
	SIGNUP: 'LWO_SIGNUP_SIGNUP',
	VERIFY_USER: 'LWO_SIGNUP_VERIFY_USER',
	RESEND_VERIFY_EMAIL: 'LWO_SIGNUP_RESEND_VERIFY_EMAIL',
	// CREATED_USER: 'SIGNUP_CREATED_USER',
  // CREATED_CUSTOMER: 'SIGNUP_CREATED_CUSTOMER',
}

export default class SignupAction extends BaseAction {

	constructor() {
		super();
		this.service = new CustomerService();
		this.consts = signupConsts;
		this.redirect = '/lawyer-one/signup';
  }
  
  initialize(params) {
    return (dispatch) => {
      this.service.initializeSignup(params)
      .then(response => {
        dispatch({
          type: this.consts.INITIALIZE,
          types: response.data.clienti_types,
					customers: response.data.customers,
					states: response.data.states,
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }


	setMainTab(index){
		return (dispatch) => {
			dispatch({
				type: this.consts.SET_MAIN_TAB,
				index: index
			});
		}
  }
  
  setUser(model){
		return (dispatch) => {
			dispatch({
				type: this.consts.SET_USER,
				model: model
			});
		}
  }
  
  setCustomer(model){
		return (dispatch) => {
			dispatch({
				type: this.consts.SET_CUSTOMER,
				model: model
			});
		}
	}
	
	signup(postData){
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			const service = new CustomerService();
			service
			.signup(postData)
			.then( response => {
				dispatch({
					type: this.consts.SIGNUP,
					user: response.data.user,
					customer: response.data.customer,
					nextStep: 2
				});
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	verifyUser(token){
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			const service = new CustomerService();
			service
			.verifyUser(token)
			.then( response => {
				// dispatch({
				// 	type: this.consts.VERIFY_USER,
				// 	nextStep: 2
				// });
				dispatch(PageLoaderActions.hide());
				window.location = `${process.env.REACT_APP_URL}/signin`;
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	resendVerifyEmail(userId){
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			const service = new CustomerService();
			service
			.resendVerifyEmail(userId)
			.then( response => {
				dispatch({
					type: this.consts.RESEND_VERIFY_EMAIL,
					nextStep: 2
				});
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}
  
  // createUser( data ) {
	// 	return (dispatch) => {
  //     dispatch(PageLoaderActions.show());
  //     const service = new UserService();
	// 		service
	// 		.signinWithConfirmation(data).then( response => {
	// 			dispatch({
	// 				type: this.consts.CREATED_USER,
	// 				user: response.data.data,
	// 				nextStep: 1
	// 			});
	// 			dispatch(PageLoaderActions.hide());
	// 		})
	// 		.catch( error => {
	// 			dispatch(PageLoaderActions.hide());
	// 			if( error.response && error.response.data && error.response.data.meta )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
	// 			else if( error.response && error.response.data && error.response.data.message )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
	// 			else
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
	// 		});
	// 	}
  // }
  
  // createCustomer( data, history ) {
	// 	return (dispatch) => {
  //     dispatch(PageLoaderActions.show());
  //     const service = new CustomerService();
	// 		service
	// 		.create(data).then( response => {
	// 			dispatch({
	// 				type: this.consts.CREATED_CUSTOMER,
	// 				customer: response.data.data,
	// 				nextStep: 1
	// 			});
	// 			dispatch(PageLoaderActions.hide());

	// 			history.push('/signin');
	// 		})
	// 		.catch( error => {
	// 			dispatch(PageLoaderActions.hide());
	// 			if( error.response && error.response.data && error.response.data.meta )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
	// 			else if( error.response && error.response.data && error.response.data.message )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
	// 			else
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
	// 		});
	// 	}
	// }
}