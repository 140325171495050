import React from 'react'
import moment from 'moment'
import * as yup from 'yup'
import { useFormik } from 'formik'

import spinner from 'spinner.gif';

import { appConfig } from 'config/appConfig';
import CK5Editor from 'components/UI/Input/CK5Editor'

// import PostLocaleComponent from 'components/UI/Post/PostLocaleComponent';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Tabs,
  Tab,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,

  Modal,
  Backdrop,
  Fade,
} from '@material-ui/core';

import { 
  Save as SaveIcon,
  Edit as EditIcon, 
  Cancel as CancelIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons';
import { render } from '@testing-library/react';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box>{children}</Box>} */}
      <Box>{children}</Box>
    </Typography>
  );
}


const Detail = (props) => {
  // if(props.authUser.role.name == 'Customer')
  //   return;
  /*
   * ComponentDidMount
   */
  React.useEffect(() => {
    if( !props.practice || !props.practice.id || props.practice.id <= 0 )
      return

    let newItem = Object.assign({},props.item)
    newItem.recordable_id = props.practice.id
    newItem.recordable_type = props.practice.className
    props.setItem(newItem)

    props.initialize();
  },[])

  /**
   * Handle
   */
  const handleChangeItem = (e) => {
    // e.persist();
    const newItem = Object.assign({},props.item)
    newItem[e.target.name] = e.target.value
    props.setItem(newItem)
  }

  const handleSubmit = () => {
    if( !props.item.description || props.item.description == "" ) {
      alert('compilare il campo descrizione')
      return;
    }
    if( parseInt(props.item.id) <= 0 )
      props.create(props.item)
    else
      props.update(props.item.id, props.item)
  }

  // /**
  //  * Validate
  //  */
  // const formik =  useFormik({
  //   initialValues: {
  //     item_date: props.item.item_date || "",
  //     item_description: props.item.item_description || "",
  //   },
  //   validationSchema: yup.object().shape({
  //     item_date: yup.string().required('Campo non valido'),
  //     item_description: yup.string().required('Campo non valido'),
  //   }),
  //   enableReinitialize: true,
  //   onSubmit: handleSubmit
  // })

  return(
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
        {(props.item && props.item.id && parseInt(props.item.id) > 0) ? 'Modifica' : 'Nuovo'} Aggiornamento
        </Typography>

        <TextField
          type="datetime-local"
          id="date_at"
          name="date_at"
          label="Data"
          value={(props.item.date_at) ? moment(props.item.date_at, moment.ISO_8601).format('YYYY-MM-DDThh:mm') : moment().format('YYYY-MM-DDThh:mm')}
          onChange={(e) => handleChangeItem(e)}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
          }}
        />

        <TextField
          select
          id="type"
          name="type"
          label="Tipologia"
          value={props.item.type || ''}
          onChange={(e)=>handleChangeItem(e)}
          margin="dense"
          variant={appConfig.inputVariant}
          fullWidth
          InputProps={{
            readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
          }}
        >
          <MenuItem value="">
            -
          </MenuItem>
          {props.practiceRecordTypes && props.practiceRecordTypes.map((option,index) =>
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          )}
        </TextField>

        {/* {((props.match.params && props.match.params.itemId > 0 && props.item.id > 0) || (props.match.params && props.match.params.itemId == 0 && props.item.id == 0)) &&
        <Box mb={3}>
          <CK5Editor 
            id="item_description"
            name="item_description"
            label="Descrizione"
            placeholder="Descrizione"
            value={props.item.item_description || ""}
            onEditorChange={(e) => handleChangeItem(e)}
            disabled={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}
            readOnly={(props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false}
            />
        </Box>
        } */}
        <TextField
          id="description"
          name="description"
          label="Descrizione"
          value={props.item.description || ""}
          onChange={(e) => handleChangeItem(e)}
          variant={appConfig.inputVariant}
          margin="dense"
          fullWidth
          multiline
          rows={4}
          InputProps={{
            readOnly: (props.authUser && props.authUser.roles && props.authUser.roles[0] && props.authUser.roles[0].name == 'Customer') ? true : false,
          }}
        />

        <Button size="small" variant="contained" color="primary" type="button" onClick={()=>handleSubmit()}>
          <SaveIcon /> Salva Aggiornamento
        </Button>
      </CardContent>
    </Card>
  )
}


const List = (props) => {
  React.useEffect(() => {
    if( !props.practice || !props.practice.id || props.practice.id <= 0 )
      return

    let newFilter = Object.assign({},props.filter)
    newFilter.recordable_id = {
      key: 'recordable_id',
      compare: '=',
      value: props.practice.id,
    }
    newFilter.recordable_type = {
      key: 'recordable_type',
      compare: '=',
      value: props.practice.className,
    }
    props.setSearch(newFilter)
    // props.search(newFilter)
    props.setItems(props.practice.records, props.practice.records.length)
  },[])


  return(
    <Card>
      <CardContent>
        {/* <Typography gutterBottom variant="h5" component="h2">
          Elenco
        </Typography> */}

        {props.loader && 
          <img src={spinner} style={{width: 50}}/>
        }
        {!props.loader &&
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                Data
              </TableCell>
              <TableCell>
                Tipologia
              </TableCell>
              <TableCell>
                Descrizione
              </TableCell>
              {props.authUser.role.name != 'Customer' &&
              <TableCell>
                Modifica
              </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items && props.items.map((obj) => 
              <TableRow key={obj.id}>
                <TableCell>
                  {obj.date_at ? moment(obj.date_at, moment.ISO_8601).format('DD/MM/YYYY hh:mm') : ""}
                </TableCell>
                <TableCell>
                  <span className={`text-${obj.type}`}>
                  {obj.type || '-'}
                  </span>
                </TableCell>
                <TableCell>
                  <div dangerouslySetInnerHTML={{ __html: obj.description }} />
                </TableCell>
                {props.authUser.role.name != 'Customer' &&
                <TableCell>
                  <Button color="primary" size="small" variant="contained" onClick={()=>props.setItem(obj)}>
                    <EditIcon /> Modifica
                  </Button>
                  &nbsp;&nbsp;
                  <Button color="secondary" size="small" variant="contained" onClick={() => props.removeOnList(obj.id, props.filter)}>
                    <DeleteForeverIcon /> Elimina
                  </Button>
                </TableCell>
                }
              </TableRow>
            )}
          </TableBody>
        </Table>
        }
      </CardContent>

      {props.authUser.role.name != 'Customer' &&
      <CardActions style={{justifyContent: 'flex-end'}}>
        <Button color="primary" size="small" variant="contained" onClick={()=>props.resetItem()}>
          <EditIcon /> Nuovo
        </Button>
      </CardActions>
      }
    </Card>
  )
}

const PracticeRecords = (props) => {
  return(
    <React.Fragment>
      <Box mb={3}>
        <List {...props} />
      </Box>
      {props.authUser.role.name != 'Customer' &&
      <Box mb={3}>
        <Detail {...props} />
      </Box>
      }
    </React.Fragment>
  )  
}

export default PracticeRecords