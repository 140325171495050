import React, { Component } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  TableCell
} from '@material-ui/core';

import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon
} from '@material-ui/icons';

import RenderTableHead from 'components/UI/Table/RenderTableHead';
import FilterPanel from 'components/UI/Table/FilterPanel';


const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  visuallyHidden: {}
}));


const CardTable = (props) => {
  const classes = useStyles();

  return(
    <React.Fragment>
      <Card>
        <CardActions style={{justifyContent: 'flex-end'}}>
          {props.actionButtons}
        </CardActions>

        <CardActions style={{justifyContent: 'flex-end'}}>
          <FilterPanel
            headCells={props.headCells}
            filter={props.filter || null}
            renderFilter={props.renderFilter || false}
            onFilterChange={(e) => props.onFilterChange(e)}
            onSearch={() => props.onSearch()}
          />
        </CardActions>

        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            trovati {props.rowItems} di {props.totItems}
          </Typography>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <RenderTableHead 
                orderBy={(props.filter && props.filter.order[0]) ? props.filter.order[0] : 'id'}
                order={(props.filter && props.filter.order[1]) ? props.filter.order[1] : 'ASC'}
                headCells={props.headCells}
                filter={props.filter || null}
                // renderFilter={props.renderFilter || false}
                onFilterChange={(e) => props.onFilterChange(e)}
                onOrderChange={(orderBy, orderHow) => props.onOrderChange(orderBy, orderHow)}
                onSearch={() => props.onSearch()}
                />

              {props.renderTableBody()}

              <TableFooter>
                <TableRow>
                <TablePagination
                  colSpan={props.paginationColSpan || 4}
                  rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                  component="td"
                  count={props.totItems}
                  rowsPerPage={props.filter.num_per_page}
                  page={props.filter.page - 1}
                  labelRowsPerPage="Righe per pagina"
                  onChangePage={(e, newPage) => props.onChangePage(e, newPage)}
                  onChangeRowsPerPage={(e) => props.onChangeRowsPerPage(e)}
                />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default CardTable;