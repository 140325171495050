import { connect } from 'react-redux'

import CustomerAction from "../../actions/CustomerAction";
import CustomerDetailComponent from '../../components/Customer/CustomerDetail';


const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    item: state.lwo_customer.item,
    types: state.lwo_customer.types,
    customers: state.lwo_customer.customers,
    states: state.lwo_customer.states
  }
}
const mapDispatchToProps = (dispatch) => {
  let action = new CustomerAction();
  return {
    initialize: (params) => {
      dispatch(action.initialize(params));
    },
    setItem: (object) => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: (id) => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.create(postData, ownProps));
    },
    update: (id, postData, ownProps) => {
      dispatch(action.update(id, postData, ownProps));
    },
    remove: (id) => {
      dispatch(action.remove(id));
    },
    mediaRemove: (itemId, mediaId) => {
      dispatch(action.mediaRemove(itemId, mediaId));
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailComponent);