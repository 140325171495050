import { causaConsts as consts} from '../actions/CausaAction';

let initialState = {
  items: [],
  totItems: 0,
  filter: {
    id: {
      key: 'id',
      compare: '=',
      value: ''
    },
    inizio_pratica_from: {
      key: 'inizio_pratica_from',
      compare: '>=',
      value: ''
    },
    inizio_pratica_to: {
      key: 'inizio_pratica_to',
      compare: '<=',
      value: ''
    },
    controparte: {
      key: 'controparte',
      compare: 'like',
      value: ''
    },
    autorita_giudiziaria: {
      key: 'autorita_giudiziaria',
      compare: 'like',
      value: ''
    },
    sezione: {
      key: 'sezione',
      compare: 'like',
      value: ''
    },
    foro_competente: {
      key: 'foro_competente',
      compare: 'like',
      value: ''
    },
    rg: {
      key: 'rg',
      compare: 'like',
      value: ''
    },
    valore_causa: {
      key: 'valore_causa',
      compare: 'like',
      value: ''
    },
    data_udienza_from: {
      key: 'data_udienza_from',
      compare: '>=',
      value: ''
    },
    data_udienza_to: {
      key: 'data_udienza_to',
      compare: '<=',
      value: ''
    },
    status: {
      key: 'status',
      compare: '=',
      value: ''
    },
    archived: {
      key: 'archived',
      compare: '=',
      value: ''
    },
    owner_user_id: {
      key: 'owner_user_id',
      compare: '=',
      value: ''
    },
    cliente_id: {
      key: 'cliente_id',
      compare: '=',
      value: ''
    },
    order: ['id', 'desc'],
    page: 1,
    num_per_page: 25,
  },
  item: {
    id: 0,
    status: 'presa_in_carico',
    archived: 0
  },
  formData: null,
  itemsItem: {
    id: 0,
    item_date: null,
    item_description: ""
  },
  mainTab: 0,
  exportUrl: ""
}

const CausaReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case consts.INITIALIZE:
      const defaultSelectValue = {
        text: "-",
        id: ""
      }

      return {
        ...state,
        status: action.status,
        autorita_giudiziaria: action.autorita_giudiziaria,
        customers: action.customers 
      };
    case consts.SET_MAIN_TAB:
      return {
        ...state,
        mainTab: action.index
      };
    case consts.SET_FORM_DATA:
      return {
        ...state,
        formData: action.formData
      };
    case consts.RESET:
      return {
        ...state,
        item: initialState.item,
      };
    case consts.SET_SEARCH:
      return {
        ...state,
        filter: action.filter
      };
    case consts.SEARCHED:
      return {
        ...state,
        items: action.items,
        totItems: action.totItems
      };
    case consts.LOADING:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case consts.LOADED:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case consts.CREATED:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case consts.UPDATED:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case consts.CREATED_CUSTOMER:
      return {
        ...state,
        item: action.item,
        mainTab: action.nextStep,
        loading: false
      };
    case consts.UPDATED_CUSTOMER:
      return {
        ...state,
        item: action.item,
        mainTab: action.nextStep,
        loading: false
      };
    case consts.FIELDS_UPDATED:
      return {
        ...state,
        items: action.items,
      };
    case consts.REMOVED:
      return {
        ...state,
        item: {},
      };
    case consts.SORT:
      return {
        ...state,
        sortedItems: action.sortedItems,
      };
    case consts.SORTED:
      return {
        ...state,
        sortedItems: action.sortedItems,
      };
    /**
     * Media
     */
    case consts.MEDIA_SINGLE_UPLOADED:
      return {
        ...state,
        item: action.item,
      };
    case consts.MEDIA_REMOVED:
      return {
        ...state,
        item: action.item,
      };
    /**
     * Export
     */
    case consts.EXPORTED:
      return {
        ...state,
        exportUrl: action.exportUrl,
      };
    /**
     * Items
     */
    case consts.ITEMS_RESET:
      return {
        ...state,
        itemsItem: initialState.itemsItem,
      };
    case consts.ITEMS_LOADED:
      return {
        ...state,
        itemsItem: action.itemsItem,
        loading: false
      };
    case consts.ITEMS_ADD_OR_UPDATE_SUCCESS:
      return {
        ...state,
        item: action.item,
        meta_hotelprices: action.meta,
        loading: false
      };
    case consts.ITEMS_ADD_OR_UPDATE_ERROR:
      return {
        ...state,
        meta_hotelprices: action.meta,
        loading: false
      };
    case consts.ITEMS_REMOVE_SUCCESS:
      return {
        ...state,
        item: action.item,
        meta_hotelprices: action.meta,
        loading: false
      };
    case consts.ITEMS_REMOVE_ERROR:
      return {
        ...state,
        meta_hotelprices: action.meta,
        loading: false
      };

    default:
      return state
  }
}

export default CausaReducer;
