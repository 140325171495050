import React from 'react';
import { connect } from 'react-redux';

import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/routes';

import CausaList from './CausaList';
import CausaDetail from './CausaDetail';
import CausaNew from './CausaNew';

class CausaPage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { authenticated } = this.props;
		return(
			<Switch>
				<ProtectedRoute exact path="/lawyer-one/cause" authenticated component={CausaList} />
        {this.props.user && this.props.user.role && this.props.user.role.id > 2 &&
          <ProtectedRoute path="/lawyer-one/cause/0" authenticated component={CausaNew}/>
        }
        <ProtectedRoute path="/lawyer-one/cause/:itemId" authenticated component={CausaDetail}/>
			</Switch>
		);
	}

}

const mapStateToProps = (state) => {
	return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
	}
}

export default connect(mapStateToProps)(CausaPage);