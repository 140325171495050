import { connect } from 'react-redux'

import ContravvenzioneAction from "../../actions/ContravvenzioneAction";
import ContravvenzioneDetailComponent from '../../components/Contravvenzione/ContravvenzioneDetail';


const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    item: state.lwo_contravvenzione.item,
    customers: state.lwo_contravvenzione.customers,
    statusList: state.lwo_contravvenzione.status
  }
}
const mapDispatchToProps = (dispatch) => {
  let action = new ContravvenzioneAction();
  return {
    initialize: (params) => {
      dispatch(action.initialize(params));
    },
    setItem: (object) => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: (id) => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.create(postData, ownProps));
    },
    update: (id, postData, ownProps) => {
      dispatch(action.update(id, postData, ownProps));
    },
    remove: (id) => {
      dispatch(action.remove(id));
    },
    mediaSingleUpload: (itemId, postData) => {
      dispatch(action.mediaSingleUpload(itemId, postData));
    },
    mediaRemove: (itemId, mediaId) => {
      dispatch(action.mediaRemove(itemId, mediaId));
    },
    // servicesRemoveAndStore: (id, data, service_type) => {
    //   dispatch(action.servicesRemoveAndStore(id, data, service_type));
    // },
    // servicesAddOrUpdate: (id, data) => {
    //   dispatch(action.servicesAddOrUpdate(id, data));
    // },
    // servicesRemove: (id, data) => {
    //   dispatch(action.servicesRemove(id, data));
    // }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ContravvenzioneDetailComponent);