import React from 'react';
import { connect } from 'react-redux';

import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/routes';

import CustomerList from './CustomerList';
import CustomerDetail from './CustomerDetail';

class CustomerPage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { authenticated } = this.props;
		return(
			<Switch>
				<ProtectedRoute exact path="/lawyer-one/customers" authenticated component={CustomerList} />
        <ProtectedRoute path="/lawyer-one/customers/:itemId" authenticated component={CustomerDetail}/>
			</Switch>
		);
	}

}

const mapStateToProps = (state) => {
	return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
	}
}

export default connect(mapStateToProps)(CustomerPage);