import axios from 'axios';
import qs from 'qs';
import BaseService from 'packages/Base/services/BaseService';

export default class RoleService extends BaseService {

  constructor() {
    super('/roles');
    this.api_url = process.env.REACT_APP_API_URL + '/roles';
  }
}