import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme( {
  color: '#006C63',
  typography: {
    fontFamily: ['Roboto','Arial'].join(),
    fontSize: 12,
    htmlFontSize: 12,
  },
  overrides: {
    // MuiAppBar: {
    //   colorPrimary: {
    //     backgroundColor: 'transparent',
    //     background: 'linear-gradient(45deg, rgba(170,219,30,0.5) 0%, rgba(84,209,105,0.5) 100%)',
    //     color: '#fff',
    //       // "&.appbarScroll": {
    //       //   backgroundColor: 'red'
    //     // }
    //   },
    // },
  },
  palette: {
    primary: {
      // main: '#006C63',
      main: '#005e95',
      // main: '#3a3a3a',
      // rgba50: 'rgba(0,108,99,0.5)',
      // rgba20: 'rgba(0,108,99,0.2)'
      gradient: 'radial-gradient(circle, #009fe3 0%, #005e95 100%)',
    },
    secondary: {
      main: '#A51109',
      rgba10: 'rgba(165,17,9,0.1)',
      // main: '#c71717'
    },
    background: {
      // background: 'linear-gradient(90deg, #3a7fd5, #6ebce2)',
      main: '#d3d4d6',
      gradient: 'linear-gradient(45deg, rgba(111,11,6,1) 0%, rgba(165,17,9,1) 100%)'
    },
    white: {
      main: '#ffffff'
    },
    black: {
      main: '#3a3a3a'
    }
  },
})