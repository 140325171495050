import { connect } from 'react-redux';

import ContravvenzioneAction from "../../actions/ContravvenzioneAction";

import ContravvenzioneNewComponent from '../../components/Contravvenzione/ContravvenzioneNew';

const mapStateToProps = (state) => {
  return {
    item: state.lwo_contravvenzione.item,
    formData: state.lwo_contravvenzione.formData,
    mainTab: state.lwo_contravvenzione.mainTab
  }
}
const mapDispatchToProps = (dispatch) => {
  let action = new ContravvenzioneAction();
  return {
    initialize: (params) => {
      dispatch(action.initialize(params));
    },
    setFormData: (formData) => {
      dispatch(action.setFormData(formData));
    },
    setMainTab: (index) => {
      dispatch(action.setMainTab(index));
    },
    setItem: (object) => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: (id) => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.createCustomerPost(postData, ownProps));
    },
    // update: (id, postData, ownProps) => {
    //   dispatch(action.update(id, postData, ownProps));
    // },
    // remove: (id) => {
    //   dispatch(action.remove(id));
    // },
    // mediaRemove: (itemId, mediaId) => {
    //   dispatch(action.mediaRemove(itemId, mediaId));
    // },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContravvenzioneNewComponent);