import axios from 'axios';
import qs from 'qs';
import BaseService from 'packages/Base/services/BaseService';

export default class CustomerService extends BaseService {

  constructor() {
    super('/lawyer-one/clienti');
    this.api_url = process.env.REACT_APP_API_URL + '/lawyer-one/clienti';
  }

  initializeSignup() {
    let url = process.env.REACT_APP_API_URL + '/lawyer-one/signup/initialize';

    return axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then( response => {
      return Promise.resolve(response);
    })
    .catch( error => {
      return Promise.reject(error);
    });
  }

  signup(credentials) {
    let url = process.env.REACT_APP_API_URL + '/lawyer-one/signup';

    return axios.post(url, credentials, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then( response => {
      return Promise.resolve(response);
    })
    .catch( error => {
      return Promise.reject(error);
    });
  }

  verifyUser(token) {
    let url = process.env.REACT_APP_API_URL + '/lawyer-one/signup/verify/' + token;

    return axios.post(url, {token: token}, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then( response => {
      return Promise.resolve(response);
    })
    .catch( error => {
      return Promise.reject(error);
    });
  }

  resendVerifyEmail(userId) {
    let url = process.env.REACT_APP_API_URL + '/lawyer-one/signup/resend-verify-email/' + userId;

    return axios.post(url, {user_id: userId}, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then( response => {
      return Promise.resolve(response);
    })
    .catch( error => {
      return Promise.reject(error);
    });
  }
}