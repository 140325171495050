import { connect } from 'react-redux';

import PermissionAction from '../../actions/PermissionAction';

import PermissionListComponent from '../../components/Permission/PermissionList';


const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		pageLoader: state.pageLoader.display,
		filter: state.permission.filter,
		items: state.permission.items,
		totItems: state.permission.totItems
	}
}
const mapDispatchToProps = (dispatch) => {
  let action = new PermissionAction();
	return {
		search: (params, count) => {
			dispatch(action.search(params, count));
		},
		setSearch: (params) => {
			dispatch(action.setSearch(params));
		},
		remove: (id, searchParams, searchCount) => {
			dispatch(action.removeOnList(id, searchParams, searchCount));
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionListComponent);