import React from 'react';

import {
  Card,
  CardContent,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography
} from '@material-ui/core';


export default class Permissions extends React.Component {

  isChecked(permissionId) {
    if( this.props.item.permissions )
      for ( let i in this.props.item.permissions )
        if( permissionId == this.props.item.permissions[i].id )
          return true;
    return false;
  }

  onChange(e) {
    e.persist();

    var inputs = document.getElementsByName("permissions[]");
    var data = new Array();

    for(var i in inputs) {
      if( inputs[i].value !== undefined && inputs[i].checked === true ) {
        data.push({
          id: inputs[i].value,
        });
      }
    }

    let item = Object.assign({}, this.props.item);
    item.permissions = data;
    this.props.setItem(item);
  }

  render() {
    return (
      <Grid container className="row rowSpacing">
        <Grid item md={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Permessi
              </Typography>

              <FormGroup row>
                {this.props.permissions && this.props.permissions.map( (obj, index) => 
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        name="permissions[]"
                        id={'permissions_' + index}
                        onChange={(e) => this.onChange(e)}
                        checked={this.isChecked(obj.id)}
                        value={obj.id}
                      />
                    }
                    label={obj.name}
                  />
                )}
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

}