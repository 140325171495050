import { connect } from 'react-redux';

import RoleAction from '../../actions/RoleAction';

import PermissionsComponent from '../../components/Permissions';



const mapStateToProps = state => {
  return {
    item: state.role.item,
    permissions: state.role.permissions
  };
};
const mapDispatchToProps = dispatch => {
  let action = new RoleAction();
  return {
    setItem: object => {
      dispatch(action.setItem(object));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsComponent);