import { initConsts as consts} from '../actions/InitAction';

let initialState = {
  currUserId: 0,
  currCustomerId: 0,
}

const InitReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case consts.INITIALIZE:
      const defaultSelectValue = {
        text: "-",
        id: ""
      }

      return {
        ...state,
        currUserId: action.currUserId,
        currCustomerId: action.currCustomerId 
      };
    default:
      return state
  }
}

export default InitReducer;
