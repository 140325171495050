import axios from 'axios';
import qs from 'qs';
import BaseService from 'packages/Base/services/BaseService';

export default class InitService extends BaseService {

  constructor() {
    super('/lawyer-one/notifications');
    this.api_url = process.env.REACT_APP_API_URL + '/lawyer-one/notifications';
  }

  index(params) {
    let config = this.checkAuthApi();
    let axiosConfig = {}

    if( params != null ) {
      axiosConfig = {
        params,
        paramsSerializer: function(params) {
          return qs.stringify(params, {
            encode: false
          });
        },
        ...config
      }
    }
    else {
      axiosConfig = {
        ...config
      }
    }

    return axios.get(this.api_url, axiosConfig)
    .then(response => {
      return response;
    }).catch(error => {
      return Promise.reject(error);
    });
  }


  markAsRead(id) {
    let config = this.checkAuthApi();
    
    return axios.post(this.api_url + '/' + id, {}, config)
    .then(response => {
      return response;
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  markAsReadList(params) {
    let config = this.checkAuthApi();
    let axiosConfig = {}

    if( params != null ) {
      axiosConfig = {
        params,
        paramsSerializer: function(params) {
          return qs.stringify(params, {
            encode: false
          });
        },
        ...config
      }
    }
    else {
      axiosConfig = {
        ...config
      }
    }

    return axios.post(this.api_url, axiosConfig)
    .then(response => {
      return response;
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  delete(id) {
    let config = this.checkAuthApi();
    
    return axios.delete(this.api_url + '/' + id, config)
    .then(response => {
      return response;
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  deleteList(params) {
    let config = this.checkAuthApi();
    let axiosConfig = {}

    if( params != null ) {
      axiosConfig = {
        params,
        paramsSerializer: function(params) {
          return qs.stringify(params, {
            encode: false
          });
        },
        ...config
      }
    }
    else {
      axiosConfig = {
        ...config
      }
    }

    return axios.post(this.api_url, axiosConfig)
    .then(response => {
      return response;
    }).catch(error => {
      return Promise.reject(error);
    });
  }
}