import React from 'react';
import { connect } from 'react-redux';

import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/routes';

import RoleList from './RoleList';
import RoleDetail from './RoleDetail';

class RolePage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { authenticated } = this.props;
		return(
			<Switch>
				<ProtectedRoute exact path="/user/roles" authenticated component={RoleList} />
				<ProtectedRoute path="/user/roles/:itemId" authenticated component={RoleDetail}/>
			</Switch>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated
	}
}

export default connect(mapStateToProps)(RolePage);