import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';
import BaseAction from 'packages/Base/actions/BaseAction';
import NotificationService from '../services/NotificationService';

export const notificationConsts = {
  INITIALIZE: 'LWO_NOTIFICATION_INITIALIZE',
  NOTIFICATION_MARK_AS_READ: 'LWO_NOTIFICATION_MARK_AS_READ',
  NOTIFICATIONS_MARK_AS_READ: 'LWO_NOTIFICATIONS_MARK_AS_READ',
  NOTIFICATION_DELETE: 'LWO_NOTIFICATION_DELETE',
  NOTIFICATIONS_DELETE: 'LWO_NOTIFICATIONS_DELETE',
}

export default class InitAction extends BaseAction {

	constructor() {
		super();
		this.service = new NotificationService();
		this.consts = notificationConsts;
		this.redirect = '/lawyer-one/notifications';
  }
  
  index(params) {
    return (dispatch) => {
      this.service.index(params)
      .then(response => {
        dispatch({
          type: this.consts.INITIALIZE,
          notifications: response.data.data,
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }

  markAsRead(id) {
    return (dispatch) => {
      this.service.markAsRead(id)
      .then(response => {
        dispatch({
          type: this.consts.NOTIFICATION_MARK_AS_READ,
          notifications: response.data,
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }

  markAsReadList(params) {
    return (dispatch) => {
      this.service.markAsReadList(params)
      .then(response => {
        dispatch({
          type: this.consts.NOTIFICATIONS_MARK_AS_READ,
          notifications: response.data,
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }


}