import React from 'react'
import qs from 'qs'

import Alert from 'components/UI/Alert';

import {
	Button,
	IconButton,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'

import {
	AddCircle as AddCircleIcon,
	ArrowDownward as ArrowDownwardIcon,
  Edit as EditIcon,
	DeleteForever as DeleteForeverIcon,
  FiberManualRecord as FiberManualRecordIcon,
	Sort as SortIcon,
	Description as DescriptionIcon,
} from '@material-ui/icons'

import { appConfig } from 'config/appConfig'
import CardTable from 'components/UI/Table/CardTable'


const CustomerList = props => {
	const [state, setState] = React.useState({
		path: `${process.env.REACT_APP_URL}/lawyer-one/customers`
	})

	/**
	 * ComponentDidMount
	 */
	React.useEffect(() => {
		props.initialize()
		axiosItems()
	}, [])

	/**
	 * Axios
	 */
	const getFilter = () => {
		let _filter = {};
		for( const[key, singleFilter] of Object.entries(props.filter) )
			if( singleFilter.value != '' )
				_filter[key] = singleFilter
		return _filter
	}

	const axiosItems = () => {
		let _filter = getFilter()
		props.search(_filter, 'true')
	}

	/**
	 * Handle
	 */
	const onFilterChange = (e) => {
    let filter = Object.assign({}, props.filter)
		for( let [key, obj] of Object.entries(props.filter))
			if( obj.key == e.target.name )
				obj.value = e.target.value || ''
    props.setSearch(filter)
	}

  const onSearch = (e) => {
    axiosItems()
  }

  const onChangePage = (e, newPage) => {
    let filter = props.filter
		Object.assign(filter, {
			page: parseInt(newPage) + 1,
		});
		
		props.setSearch(filter)
		axiosItems()
  }

	const onChangeRowsPerPage = (event) => {
    let filter = props.filter
		Object.assign(filter, {
      page: 1,
      num_per_page: parseInt(event.target.value, 10)
		})
		
		props.setSearch(filter)
		axiosItems()
  }

	const onOrderChange = (orderBy, orderHow) => {
		let filter = props.filter
		Object.assign(filter, {
			page: 1,
			order: (filter.order[0] == orderBy) ? [orderBy, orderHow] : [orderBy, 'ASC']
		})
		props.setSearch(filter)
		axiosItems()
  }

	const onChangeStatus = (id, params) => {
		if(window.confirm("Sicuro di voler cambiare lo stato del numero " + id + "?")) {
			props.updateFields(id, {params}, getFilter(), 'true');
		}
	}

	const onRemove = (id) => {
		if(window.confirm("Sicuro di voler eliminare il numero " + id + "?")) {
			props.remove(id, getFilter(), 'true');
		}
	}

	const getItemStatus = (status) => {
		if( props.statusList )
			for( let obj of Object.entries(props.statusList) )
				if( obj.key == status )
					return obj.value
		return status
	}

	/**
	 * Render
	 */
	const headCells = [
		{ id: 'id', numeric: false, isSort: true, disablePadding: false, label: 'ID', type: 'text' },
		{ id: 'owner_user_id', numeric: false, isSort: true, disablePadding: false, label: 'Cliente', type: 'autocomplete' , data: (props.customers || []), 
			getOptionLabel: function(option){
				if( option )
					return `[${option.id}] ${option.last_name} ${option.first_name}`;
				return ''
			},
			onChange: function(e, option){
				e.persist()
				e.target.name = 'owner_user_id'
				if( option )
					e.target.value = option.id
				else 
					e.target.value = ''
				onFilterChange(e)
			}
		},
		{ id: 'type', numeric: false, isSort: true, disablePadding: false, label: 'Tipologia', type: 'select', data: (props.types || []), dataOptions: {dataKey: 'key', dataValue: 'value'} },
		{ id: 'company', numeric: false, isSort: true, disablePadding: false, label: 'Ragione Sociale', type: 'text' },
		{ id: 'referent', numeric: false, isSort: true, disablePadding: false, label: 'Referente', type: 'text' },
		{ id: 'state', numeric: false, isSort: true, disablePadding: false, label: 'Provincia', type: 'simple_select', data: (props.states || []) },
		{ id: 'city', numeric: false, isSort: true, disablePadding: false, label: 'Città', type: 'text'},
		{ id: 'last', numeric: false, isSort: false, disablePadding: false, label: 'Modifica' }
	]

	const renderType = (type) => {
		for(let i in props.types)
			if( props.types[i].key == type )
				return props.types[i].value
		return ""
	}

	const renderTableBody = () => {
    return(
      <TableBody>
        {props.items.map(item => (
          <TableRow key={item.id}>
            <TableCell component="th" scope="row">
              {item.id}
            </TableCell>
            <TableCell>
							{item.ownerUser && 
							<a href={`${process.env.REACT_APP_URL}/user/users/${item.owner_user_id}`} target="_blank">
								{`[${item.ownerUser.id}] ${item.ownerUser.last_name} ${item.ownerUser.first_name}`}
							</a>
							}
						</TableCell>
						<TableCell>{props.types && renderType(item.type)}</TableCell>
						<TableCell>{item.company}</TableCell>
						<TableCell>{item.referent}</TableCell>
						<TableCell>{item.state}</TableCell>
						<TableCell>{item.city}</TableCell>
            <TableCell>
              <Button color="primary" size="small" variant="contained" href={`${state.path}/${item.id}${props.location.search}`}>
                <EditIcon /> Apri
              </Button>
              {/* &nbsp;&nbsp;
              <Button color="secondary" size="small" variant="contained" onClick={() => onRemove(item.id)}>
                <DeleteForeverIcon /> Elimina
              </Button> */}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
	}

	return (
		<React.Fragment>
			<div className="page-title">
				Anagrafica Clienti
			</div>

			<CardTable 
				actionButtons={
					<ul className="pulsantiera">
						<li>
							{/* <Button size="small" color="primary" variant="contained" href={`${state.path}/0${props.location.search}`}>
								<AddCircleIcon /> Nuovo
							</Button> */}
						</li>
					</ul>
				}
				totItems={props.totItems || 0}
				rowItems={props.items.length || 0}
				filter={props.filter}
				renderFilter={true}
				headCells={headCells}
				renderTableBody={() => renderTableBody()}
				onSearch={() => onSearch()}
				onFilterChange={(e) => onFilterChange(e)}
				onOrderChange={(orderBy, orderHow) => onOrderChange(orderBy, orderHow)}
				onChangePage={(e,newPage) => onChangePage(e,newPage)}
				onChangeRowsPerPage={(e) => onChangeRowsPerPage(e)}
				paginationColSpan={8}
			/>
		</React.Fragment>
	);
}

export default CustomerList;