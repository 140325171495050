import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import AlertMessageActions from 'actions/AlertMessageActions';


import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
	},
	success: {
    color: 'white',
    backgroundColor: 'green'
	},
	error: {
    color: 'white',
    backgroundColor: 'red'
	},
	warning: {
    color: 'white',
    backgroundColor: 'yellow'
	},
	info: {
    color: 'white',
    backgroundColor: 'blue'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertMessage = (props) => {
	const classes = useStyles();

	let initialState = (props.message && props.message != "") ? true : false;
	const [open, setOpen] = React.useState(initialState);
	if( initialState != open )
		setOpen(initialState);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
		setOpen(false);
		props.resetAlertMessage();
	};
	
	let type = "info";
	let title = "Info";
	if( props.type ) {
		switch(props.type) {
			case 'alert-success': type = 'success'; title = "Successo"; break;
			case 'alert-danger': type = 'error'; title = "Errore"; break;
			case 'alert-warning': type = 'warning'; title = "Attenzione"; break;
			default: type = "info"; title = "Info"; break;
		}
	}

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        // keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={classes[type]}
        >
          {title}
        </DialogTitle>
        <DialogContent className={classes[type]}>
          <DialogContentText id="alert-dialog-slide-description" className={classes[type]}>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes[type]}>
          <Button onClick={handleClose} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
	return {
		type: state.alertMessage.type,
		message: state.alertMessage.message
	}
}
const mapDispatchToProps = (dispatch) => {
  return {
    resetAlertMessage: () => {
      dispatch(AlertMessageActions.reset());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
