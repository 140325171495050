import React from 'react';
import { connect } from 'react-redux';

import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/routes';

import PermissionList from './PermissionList';
import PermissionDetail from './PermissionDetail';

class PermissionPage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { authenticated } = this.props;
		return(
			<Switch>
				<ProtectedRoute exact path="/user/permissions" authenticated component={PermissionList} />
				<ProtectedRoute path="/user/permissions/:itemId" authenticated component={PermissionDetail}/>
			</Switch>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated
	}
}

export default connect(mapStateToProps)(PermissionPage);