import { connect } from 'react-redux';

import PermissionAction from '../../actions/PermissionAction';

import PermissionDetailComponent from '../../components/Permission/PermissionDetail';



const mapStateToProps = state => {
  return {
    item: state.permission.item
  };
};
const mapDispatchToProps = dispatch => {
  let action = new PermissionAction();
  return {
    setItem: object => {
      dispatch(action.setItem(object));
    },
    resetItem: () => {
      dispatch(action.resetItem());
    },
    load: id => {
      dispatch(action.load(id));
    },
    create: (postData, ownProps) => {
      dispatch(action.create(postData, ownProps));
    },
    update: (id, postData, ownProps) => {
      dispatch(action.update(id, postData, ownProps));
    },
    remove: id => {
      dispatch(action.remove(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionDetailComponent);