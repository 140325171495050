import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';
import BaseAction from 'packages/Base/actions/BaseAction';
import CustomerService from '../services/CustomerService';

export const customerConsts = {
	INITIALIZE: 'LWO_CUSTOMER_INITIALIZE',
	SET_MAIN_TAB: 'LWO_CUSTOMER_SET_MAIN_TAB',
	SET_SEARCH: 'LWO_CUSTOMER_SET_SEARCH',
	SEARCHED: 'LWO_CUSTOMER_SEARCH',
	RESET: 'LWO_CUSTOMER_RESET',
	LOADING: 'LWO_CUSTOMER_LOADING',	
	LOADED: 'LWO_CUSTOMER_LOADED',
	CREATED: 'LWO_CUSTOMER_CREATED',
	CREATED_CUSTOMER: 'LWO_CUSTOMER_CREATED_CUSTOMER',
	UPDATED: 'LWO_CUSTOMER_UPDATED',
	UPDATED_CUSTOMER: 'LWO_CUSTOMER_UPDATED_CUSTOMER',
	FIELDS_UPDATED: 'LWO_CUSTOMER_FIELDS_UPDATED',
  REMOVED: 'LWO_CUSTOMER_REMOVED',
  SORT: 'LWO_CUSTOMER_SORT',
	SORTED: 'LWO_CUSTOMER_SORTED',
	LIST_ERROR: 'LWO_CUSTOMER_LIST_ERROR',
  ITEM_ERROR: 'LWO_CUSTOMER_ITEM_ERROR',
	MEDIA_REMOVED: 'LWO_CUSTOMER_MEDIA_REMOVED',
}

export default class CustomerAction extends BaseAction {

	constructor() {
		super();
		this.service = new CustomerService();
		this.consts = customerConsts;
		this.redirect = '/lawyer-one/clienti';
  }
  
  initialize(params) {
    return (dispatch) => {
      this.service.initialize(params)
      .then(response => {
        dispatch({
          type: this.consts.INITIALIZE,
          types: response.data.clienti_types,
          states: response.data.states,
          customers: response.data.customers
        })
      })
      .catch(error => {
        if( error.data && error.data.meta && error.data.meta.message)
          dispatch(AlertMessageActions.alertMessageError(error.data.meta.message));
        else if( error.data && error.data.message )
          dispatch(AlertMessageActions.alertMessageError(error.data.message));
        else
          dispatch(AlertMessageActions.alertMessageError(error.message));
      });
    }
  }


	// setMainTab(index){
	// 	return (dispatch) => {
	// 		dispatch({
	// 			type: this.consts.SET_MAIN_TAB,
	// 			index: index
	// 		});
	// 	}
	// }

  // createCustomerPost( postData, ownProps ) {
	// 	return (dispatch) => {
	// 		dispatch(PageLoaderActions.show());
	// 		this.service
	// 		.createPost(postData).then( response => {
	// 			dispatch({
	// 				type: this.consts.CREATED_CUSTOMER,
	// 				item: response.data.data,
	// 				nextStep: 1
	// 			});
	// 			dispatch(PageLoaderActions.hide());
	// 			dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
	// 		})
	// 		.catch( error => {
	// 			dispatch(PageLoaderActions.hide());
	// 			if( error.response && error.response.data && error.response.data.meta )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
	// 			else if( error.response && error.response.data && error.response.data.message )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
	// 			else
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
	// 		});
	// 	}
  // }
  
  // createCustomer( postData, ownProps ) {
	// 	return (dispatch) => {
	// 		dispatch(PageLoaderActions.show());
	// 		this.service
	// 		.create(postData).then( response => {
	// 			dispatch({
	// 				type: this.consts.CREATED_CUSTOMER,
	// 				item: response.data.data,
	// 				nextStep: 1
	// 			});
	// 			dispatch(PageLoaderActions.hide());
	// 			dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
	// 		})
	// 		.catch( error => {
	// 			dispatch(PageLoaderActions.hide());
	// 			if( error.response && error.response.data && error.response.data.meta )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
	// 			else if( error.response && error.response.data && error.response.data.message )
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
	// 			else
	// 				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
	// 		});
	// 	}
	// }
}