import React from 'react'
import moment from 'moment'
import { appConfig } from 'config/appConfig';

import {
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core';

import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
	DeleteForever as DeleteForeverIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  InsertDriveFile as InsertDriveFileIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const VerbaleMedia = props => {console.log('props',props)
  const classes = useStyles()

  /**
   * Handle
   */
  const onUpload = ({ target }) => {
    const fileReader = new FileReader()
    const name = target.name;

    fileReader.readAsDataURL(target.files[0])
    fileReader.onload = (e) => {
      // form
      let formData = new FormData()
      formData.append('file_verbale', target.files[0], 'file_verbale')

      props.mediaSingleUpload(props.item.id, formData)
    }
  }

  const onRemove = (mediaId) => {
    props.mediaRemove(props.item.id, mediaId)
  }


  const isProtected = (props.authUser && props.authUser.roles && props.authUser.roles[0] && (props.authUser.roles[0].name == 'Admin' || props.authUser.roles[0].name == 'Super Admin')) ? true : false

  return(
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Verbale
        </Typography>
        {props.item && !props.item.file_verbale &&
          <TextField 
            id="file_verbale"
            name="file_verbale"
            // label="Carica verbale"
            accept="image/*"
            multiple
            type="file"
            onChange={e => onUpload(e)}
            margin="dense"
            variant={appConfig.inputVariant}
            fullWidth
            // error={formik.errors.costo_commessa ? true : false}
            // helperText={formik.errors.costo_commessa}
          />
        }
        {props.item && props.item.file_verbale &&
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Documento</TableCell>
              <TableCell>Data</TableCell>
              { isProtected &&
              <TableCell></TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <a href={props.item.file_verbale.url} target="_blank">
                  {(props.item.file_verbale.mime_type === 'image/jpeg' || props.item.file_verbale.mime_type === 'image/png' || props.item.file_verbale.mime_type === 'image/gif') &&
                    <img src={props.item.file_verbale.url} width="100"/>
                  }
                  {/* {props.item.file_verbale.mime_type === 'application/pdf' &&
                    <InsertDriveFileIcon />
                  } */}
                  {(props.item.file_verbale.mime_type !== 'image/jpeg' && props.item.file_verbale.mime_type !== 'image/png' && props.item.file_verbale.mime_type !== 'image/gif') &&
                    <InsertDriveFileIcon />
                  }
                </a>
              </TableCell>
              <TableCell>{props.item.file_verbale.file_name}</TableCell>
              <TableCell>
                {moment(props.item.file_verbale.created_at).format('DD/MM/YYYY HH:mm')}
              </TableCell>
              { isProtected &&
              <TableCell>
                <Button color="primary" size="small" variant="contained" href={props.item.file_verbale.url} target="_blank">
                  <GetAppIcon />
                </Button>
                &nbsp;&nbsp;
                <Button color="secondary" size="small" variant="contained" onClick={()=>onRemove(props.item.file_verbale.id)}>
                  <DeleteForeverIcon />
                </Button>
              </TableCell>
              }
            </TableRow>
          </TableBody>
        </Table>
        }
      </CardContent>
    </Card>
  )
}


export default VerbaleMedia