import axios from 'axios';
import qs from 'qs';
import { appConfig } from 'config/appConfig';
import BaseService from '../services/BaseService';

export default class MediaService extends BaseService {

  constructor(url) {
    url = (url != null) ? url : '/media';
    super(url);
    this.api_url = process.env.REACT_APP_API_URL + url;
  }

  /**
   * Media Single
   */
  mediaRemove(mediaId) {
    let config = this.checkAuthApi();

    return axios.delete(this.api_url + '/' + mediaId, {
        ...config
      })
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  /**
   * Media Multiple
   */
  // mediaMultiUpload(postData) {
  //   let config = this.checkAuthApi();

  //   return axios.post(this.api_url + '/media', postData, config)
  //     .then(response => {
  //       return Promise.resolve(response);
  //     }).catch(error => {
  //       return Promise.reject(error);
  //     });
  // }

  mediaMultiAll(itemId, params) {
    let config = this.checkAuthApi();

    // return axios.get(appConfig.api.url + '/users', {
      return axios.get(this.api_url + '/' + itemId + '/media', {
      params,
      paramsSerializer: function(params) {
        return qs.stringify(params, {
          encode: false
        });
      },
      ...config
    }).then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  mediaMultiSort(postData) {
    let config = this.checkAuthApi('json');

    return axios.put(this.api_url + '/sort', postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }

  mediaUpdateDetails(mediaId, postData) {
    let config = this.checkAuthApi('json');
    // postData.append('_method', 'PUT');

    return axios.put(this.api_url + '/' + mediaId, postData, config)
      .then(response => {
        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
  }
}