import React from 'react'
import qs from 'qs'
import moment from 'moment'

import Alert from 'components/UI/Alert';

import {
	Button,
	IconButton,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'

import {
	AddCircle as AddCircleIcon,
	ArrowDownward as ArrowDownwardIcon,
  Edit as EditIcon,
	DeleteForever as DeleteForeverIcon,
  FiberManualRecord as FiberManualRecordIcon,
	Sort as SortIcon,
	Description as DescriptionIcon,
} from '@material-ui/icons'

import { appConfig } from 'config/appConfig'
import CardTable from 'components/UI/Table/CardTable'


const CausaList = props => {
	const [state, setState] = React.useState({
		path: `${process.env.REACT_APP_URL}/lawyer-one/cause`
	})

	/**
	 * ComponentDidMount
	 */
	React.useEffect(() => {
		let queryParams = qs.parse(props.location.search.replace('?',''))
		if( queryParams.archived ) {
			props.setSearch({
				...props.filter,
				archived: {
					key: 'archived',
					compare: '=',
					value: parseInt(queryParams.archived)
				},
				status: {
					key: 'status',
					compare: '=',
					value: parseInt(queryParams.archived) == 1 ? 'archiviata': ''
				},
			})
		}
		// console.log('filter', props.filter, queryParams.archived)

		props.initialize()
		axiosItems()

	}, [])

	/**
	 * Axios
	 */
	const getFilter = () => {
		let _filter = {};
		for( const[key, singleFilter] of Object.entries(props.filter) )
			if( singleFilter.value != '' )
				_filter[key] = singleFilter
		
		let queryParams = qs.parse(props.location.search.replace('?',''))
		if( queryParams.archived ) {
			_filter['archived'] = {
				key: 'archived',
				compare: '=',
				value: parseInt(queryParams.archived)
			}
		}
		return _filter
	}

	const axiosItems = () => {
		let _filter = getFilter()
		props.search(_filter, 'true')
	}

	/**
	 * Handle
	 */
	const onFilterChange = (e) => {
    let filter = Object.assign({}, props.filter)
		// for( let i in filter )
		// 	if( filter[i]['key'] == e.target.name )
		// 		filter[i]['value'] = e.target.value || ''
		for( let [key, obj] of Object.entries(props.filter))
			if( obj.key == e.target.name )
				obj.value = e.target.value || ''
    props.setSearch(filter)
	}

  const onSearch = (e) => {
    axiosItems()
  }

  const onChangePage = (e, newPage) => {
    let filter = props.filter
		Object.assign(filter, {
			page: parseInt(newPage) + 1,
		});
		
		props.setSearch(filter)
		axiosItems()
  }

	const onChangeRowsPerPage = (event) => {
    let filter = props.filter
		Object.assign(filter, {
      page: 1,
      num_per_page: parseInt(event.target.value, 10)
		})
		
		props.setSearch(filter)
		axiosItems()
  }

	const onOrderChange = (orderBy, orderHow) => {
		let filter = props.filter
		Object.assign(filter, {
			page: 1,
			order: (filter.order[0] == orderBy) ? [orderBy, orderHow] : [orderBy, 'ASC']
		})
		props.setSearch(filter)
		axiosItems()
  }

	const onChangeStatus = (id, params) => {
		if(window.confirm("Sicuro di voler cambiare lo stato del numero " + id + "?")) {
			props.updateFields(id, {params}, getFilter(), 'true');
		}
	}

	const onRemove = (id) => {
		if(window.confirm("Sicuro di voler eliminare il numero " + id + "?")) {
			props.remove(id, getFilter(), 'true');
		}
	}

	const onExport = () => {
		props.export(getFilter())
  }

	const getItemStatus = (status) => {
		if( props.statusList )
			for( let [key, obj] of Object.entries(props.statusList) )
				if( obj.key == status )
					return obj.value
		return status
	}

	/**
	 * Render
	 */
	const headCells = [
		{ id: 'id', numeric: false, isSort: true, disablePadding: false, label: 'ID', type: 'text' },
		{ id: 'owner_user_id', numeric: false, isSort: true, disablePadding: false, label: 'Cliente', type: 'autocomplete' , data: (props.customers || []), 
			getOptionLabel: function(option){
				if( option ){
					// return `[${option.id}] ${option.last_name} ${option.first_name}`;
					if( appConfig.clientiTypes.aziende.includes(option.type) ){
						return `[${option.id}] ${option.company}`;
					}
					if( appConfig.clientiTypes.privati.includes(option.type) ){
						return `[${option.id}] ${option.user.last_name} ${option.user.first_name}`;
					}
				}
				return ''
			},
			onChange: function(e, option){
				e.persist()
				e.target.name = 'cliente_id'
				if( option )
					e.target.value = option.id
				else 
					e.target.value = ''
				onFilterChange(e)
			}
		},
		{ id: 'inizio_pratica', numeric: false, isSort: true, disablePadding: false, label: 'Inizio Pratica', type: 'date_range' },
		{ id: 'controparte', numeric: false, isSort: true, disablePadding: false, label: 'Controparte', type: 'text' },
		{ id: 'autorita_giudiziaria', numeric: false, isSort: true, disablePadding: false, label: 'Autorità giudiziaria', type: 'simple_select' , data: (props.autoritaGiudiziariaList || [])},
		{ id: 'sezione', numeric: false, isSort: true, disablePadding: false, label: 'Sezione', type: 'text' },
		{ id: 'foro_competente', numeric: false, isSort: true, disablePadding: false, label: 'Foro competente', type: 'text' },
		{ id: 'rg', numeric: false, isSort: true, disablePadding: false, label: 'R.G.', type: 'text' },
		{ id: 'valore_causa', numeric: false, isSort: true, disablePadding: false, label: 'Valore Causa', type: 'text'},
		{ id: 'data_udienza', numeric: false, isSort: true, disablePadding: false, label: 'Data udienza', type: 'date_range' },
		{ id: 'status', numeric: false, isSort: true, disablePadding: false, label: 'Stato', type: 'select', data: (props.statusList || []), dataOptions: {dataKey: 'key', dataValue: 'value'}},
		{ id: 'archived', numeric: false, isSort: true, disablePadding: false, label: 'Archiviata', type: 'select', data: (appConfig.status.yesno || []), dataOptions: {dataKey:"key", dataValue:"value"}, visible: false},
		// { id: 'esito_causa', numeric: false, isSort: true, disablePadding: false, label: 'Esito causa', type: 'text'},
		{ id: 'last', numeric: false, isSort: false, disablePadding: false, label: '' }
	]

	const renderTableBody = () => {
    return(
      <TableBody>
        {props.items.map(item => (
          <TableRow key={item.id}>
            <TableCell component="th" scope="row">
              {item.id}
            </TableCell>
            <TableCell>
							{item.ownerUser && item.cliente && appConfig.clientiTypes.aziende.includes(item.cliente.type) &&
								`[${item.cliente.id}] ${item.cliente.company}`
							}
							{item.ownerUser && item.cliente && appConfig.clientiTypes.privati.includes(item.cliente.type) &&
								`[${item.cliente.id}] ${item.ownerUser.last_name} ${item.ownerUser.first_name}`
							}
						</TableCell>
						<TableCell>{moment(item.inizio_pratica, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
						<TableCell>{item.controparte}</TableCell>
						<TableCell>{item.autorita_giudiziaria}</TableCell>
						<TableCell>{item.sezione}</TableCell>
						<TableCell>{item.foro_competente}</TableCell>
						<TableCell>{item.rg}</TableCell>
						<TableCell>{item.valore_causa}</TableCell>
						<TableCell>{item.data_udienza && moment(item.data_udienza, moment.ISO_8601).format('DD/MM/YYYY')}</TableCell>
						<TableCell>
              {/* <Button 
                onClick={(id) => this.onChangeStatus(item.id, { status: (item.status == 1) ? 0 : 1 } )}
                >
                <FiberManualRecordIcon 
                  className={"textStatus-" + item.status} 
                  />
              </Button> */}
							<span className={`text-${item.status}`}>
							{getItemStatus(item.status)}
							</span>
            </TableCell>
						{/* <TableCell>{item.esito_causa || ""}</TableCell> */}
            <TableCell>
              <Button color="primary" size="small" variant="contained" href={`${state.path}/${item.id}${props.location.search}`}>
                Apri
              </Button>
              {/* &nbsp;&nbsp;
              <Button color="secondary" size="small" variant="contained" onClick={() => onRemove(item.id)}>
                <DeleteForeverIcon /> Elimina
              </Button> */}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
	}

	let queryParams = qs.parse(props.location.search.replace('?',''))
	return (
		<React.Fragment>
			<div className="page-title">
				Cause {parseInt(queryParams.archived) == 1 ? 'Archiviate' : ''}
			</div>

			{props.exportUrl != "" &&
				<Alert 
					severity="success" 
					message={
						<Button
              aria-label="download"
              color="inherit"
              size="small"
							href={props.exportUrl}
							target="_blank"
            >
              Scarica file <ArrowDownwardIcon fontSize="inherit" />
            </Button>
					}
				/>
			}

			<CardTable 
				actionButtons={
					<React.Fragment>
						<ul className="pulsantiera">
							{(!queryParams.archived || parseInt(queryParams.archived) == 0) &&
							<li>
								<Button size="small" color="primary" variant="contained" href={`${state.path}/0${props.location.search}`}>
									<AddCircleIcon /> Nuovo
								</Button>
							</li>
							}
							<li>
								<Button size="small" color="primary" variant="contained" onClick={e => onExport()}>
									<DescriptionIcon /> Esporta
								</Button>
							</li>
							{/* <Button size="small" color="primary" variant="contained" href={`${this.state.path}/sort`}>
								<SortIcon /> Ordine
							</Button> */}
						</ul>
					</React.Fragment>
				}
				totItems={props.totItems || 0}
				rowItems={props.items.length || 0}
				filter={props.filter}
				renderFilter={true}
				headCells={headCells}
				renderTableBody={() => renderTableBody()}
				onSearch={() => onSearch()}
				onFilterChange={(e) => onFilterChange(e)}
				onOrderChange={(orderBy, orderHow) => onOrderChange(orderBy, orderHow)}
				onChangePage={(e,newPage) => onChangePage(e,newPage)}
				onChangeRowsPerPage={(e) => onChangeRowsPerPage(e)}
				paginationColSpan={11}
			/>
		</React.Fragment>
	);
}

export default CausaList;