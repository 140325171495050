import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  TableCell
} from '@material-ui/core';

import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon
} from '@material-ui/icons';

import CardTable from 'components/UI/Table/CardTable';
import RenderTableHead from 'components/UI/Table/RenderTableHead';


const styles = theme => ({
  table: {
    minWidth: 650,
  },
  visuallyHidden: {}
});


class RoleList extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      path: `${process.env.REACT_APP_URL}/user/roles`
    }
  }

  componentDidMount() {
		this.axiosItems();
	}


	/**
  * Axios
	*/
	getFilter() {
		let _filter = {};
		for( let i in this.props.filter )
			if( this.props.filter[i].value != '' )
				_filter[i] = this.props.filter[i];
		return _filter;
	}
	axiosItems() {
		let _filter = this.getFilter();
		this.props.search(_filter);
  }


  /**
	 * Handle
	 */
  onFilterChange(e) {
    let filter = Object.assign({}, this.props.filter);
		for( let i in filter )
			if( filter[i]['key'] == e.target.name )
				filter[i]['value'] = e.target.value;
    this.props.setSearch(filter);
	}

	onSearch() {
		this.axiosItems();
	}
  
  onChangePage(e, newPage) {
    let filter = this.props.filter;
		Object.assign(filter, {
			page: parseInt(newPage) + 1,
		});
		
		this.props.setSearch(filter);
		this.axiosItems();
  };

  onChangeRowsPerPage(event){
    let filter = this.props.filter;
		Object.assign(filter, {
      page: 1,
      num_per_page: parseInt(event.target.value, 10)
		});
		
		this.props.setSearch(filter);
		this.axiosItems();
  };

	onOrderChange(orderBy, orderHow) {
		let filter = this.props.filter;
		Object.assign(filter, {
			page: 1,
			order: (filter.order[0] == orderBy) ? [orderBy, orderHow] : [orderBy, 'ASC']
		});
		this.props.setSearch(filter);
		this.axiosItems();
  }
  
  onRemove(id) {
		if(window.confirm("Sicuro di voler eliminare il numero " + id + "?")) {
			this.props.remove(id, this.getFilter(), 'true');
		}
	}
  

  /**
  * Render
  */

  renderTableBody() {
    return(
      <TableBody>
        {this.props.items.map(item => (
          <TableRow key={item.id}>
            <TableCell component="th" scope="row">
              {item.id}
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.guard_name}</TableCell>
            <TableCell>
              <Button color="primary" size="small" variant="contained" href={this.state.path + "/" + item.id}>
                <EditIcon /> Apri
              </Button>
              &nbsp;&nbsp;
              <Button color="secondary" size="small" variant="contained" onClick={() => this.onRemove(item.id)}>
                <DeleteForeverIcon /> Elimina
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  render() {
    const { classes } = this.props;

    const headCells = [
      { id: 'id', numeric: false, isSort: true, disablePadding: false, label: 'ID', type: 'text' },
      { id: 'name', numeric: false, isSort: true, disablePadding: false, label: 'Nome', type: 'text' },
      { id: 'guard_name', numeric: false, isSort: true, disablePadding: false, label: 'Guard Name', type: 'text' },
      { id: 'last', numeric: false, isSort: false, disablePadding: false, label: 'Modifica' },
    ];

    return (
      <React.Fragment>
        <div className="page-title">Ruoli</div>

        <CardTable 
					actionButtons={
						<React.Fragment>
							<Button size="small" color="primary" variant="contained" href={this.state.path + '/0'}>
								<AddCircleIcon /> Nuovo
							</Button>
						</React.Fragment>
					}	
					totItems={this.props.totItems || 0}
					rowItems={this.props.items.length || 0}
					filter={this.props.filter}
					headCells={headCells}
					renderTableBody={() => this.renderTableBody()}
					onSearch={() => this.onSearch()}
					onFilterChange={(e) => this.onFilterChange(e)}
					onOrderChange={(orderBy, orderHow) => this.onOrderChange(orderBy, orderHow)}
					onChangePage={(e,newPage) => this.onChangePage(e,newPage)}
					onChangeRowsPerPage={(e) => this.onChangeRowsPerPage(e)}
				/>

        {/* <Card>
          <CardActions style={{justifyContent: 'flex-end'}}>
            <Button size="small" color="primary" variant="contained" href={this.state.path + '/0'}>
              <AddCircleIcon /> Nuovo
            </Button>
          </CardActions>

          <CardContent>
            <Typography variant="subtitle1" gutterBottom>
              trovati {this.props.items.length} di {this.props.totItems}
            </Typography>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <RenderTableHead 
                  orderBy={(this.props.filter && this.props.filter.order[0]) ? this.props.filter.order[0] : 'id'}
                  order={(this.props.filter && this.props.filter.order[1]) ? this.props.filter.order[1] : 'ASC'}
                  headCells={headCells}
                  filter={this.props.filter}
                  onFilterChange={(e) => this.onFilterChange(e)}
                  onOrderChange={(orderBy, orderHow) => this.onOrderChange(orderBy, orderHow)}
                  onSearch={() => this.onSearch()}
                  />

                {this.renderTableBody()}

                <TableFooter>
                  <TableRow>
                  <TablePagination
                    colSpan={4}
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                    component="td"
                    count={this.props.totItems || 0}
                    rowsPerPage={this.props.filter.num_per_page}
                    page={this.props.filter.page - 1}
                    labelRowsPerPage="Righe per pagina"
                    onChangePage={(newPage) => this.onChangePage(newPage)}
                    onChangeRowsPerPage={(e) => this.onChangeRowsPerPage(e)}
                  />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </CardContent>

        </Card> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(RoleList);