import AlertMessageActions from 'actions/AlertMessageActions';
import PageLoaderActions from 'actions/PageLoaderActions';

import BaseAction from 'packages/Base/actions/BaseAction';
import PracticeRecordService from '../services/PracticeRecordService';
import ContravvenzioneAction from '../actions/ContravvenzioneAction';
import CausaAction from '../actions/CausaAction';

export const practiceRecordConsts = {
	SET_LOADER: 'PRACTICE_RECORDS_SET_LOADER',
  INITIALIZE: 'PRACTICE_RECORDS_INITIALIZE',
	SET_SEARCH: 'PRACTICE_RECORDS_SET_SEARCH',
	SEARCHED: 'PRACTICE_RECORDS_SEARCH',
	RESET: 'PRACTICE_RECORDS_RESET',
	LOADED: 'PRACTICE_RECORDS_LOADED',
	CREATED: 'PRACTICE_RECORDS_CREATED',
	UPDATED: 'PRACTICE_RECORDS_UPDATED',
	FIELDS_UPDATED: 'PRACTICE_RECORDS_FIELDS_UPDATED',
	REMOVED: 'PRACTICE_RECORDS_REMOVED',
	// SORT: 'PRACTICE_RECORDS_SORT',
	// SORTED: 'PRACTICE_RECORDS_SORTED',
	LIST_ERROR: 'PRACTICE_RECORDS_LIST_ERROR',
	ITEM_ERROR: 'PRACTICE_RECORDS_ITEM_ERROR',
	// SYNC_CATEGORIES: 'PRACTICE_RECORDS_SYNC_CATEGORIES',
	// SYNC_TAGS: 'PRACTICE_RECORDS_SYNC_TAGS'
}

export default class PracticeRecordAction extends BaseAction {

	constructor() {
		super();
		this.service = new PracticeRecordService();
		this.consts = practiceRecordConsts;
		this.redirect = '/lawyer-one/practice-records';
  }

	initialize() {
		return (dispatch) => {
			this.service.initialize()
			.then(response => {
				dispatch({
          type: this.consts.INITIALIZE,
          types: response.data.types,
				})
			})
			.catch(error => {
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}

	setLoader(value) {
		return (dispatch) => {
			dispatch({
				type: this.consts.SET_LOADER,
				loader: value,
			});
		}
	}
	
	search( filter, count ) {
		return (dispatch) => {
			dispatch(this.setLoader(true));
			this.service.search(filter, count).then( response => {
				dispatch({
					type: this.consts.SEARCHED,
					items: response.data.data,
					totItems: response.data.meta.total
				});
				dispatch(this.setLoader(false));
			}).catch( error => {
				dispatch(this.setLoader(false));
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
	}
  
  create( postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service
			.create(postData).then( response => {
				dispatch({
					type: this.consts.CREATED,
					item: response.data.data
        });
        dispatch(this.search({
          recordable_id: {
            key: 'recordable_id',
            compare: '=',
            value: response.data.data.recordable_id
          },
          recordable_type: {
            key: 'recordable_type',
            compare: '=',
            value: response.data.data.recordable_type
          }
        }))
        dispatch(this.setItem({
          id: 0,
          recordable_id: response.data.data.recordable_id,
          recordable_type: response.data.data.recordable_type,
					date_at: null,
					description: ""
        }))
				dispatch(PageLoaderActions.hide());

				switch( response.data.data.recordable_type ){
					case 'Goomlab\\LawyerOne\\Models\\Contravvenzione':
						var practiceAction = new ContravvenzioneAction()
						dispatch(practiceAction.load(response.data.data.recordable_id))
					break
					case 'Goomlab\\LawyerOne\\Models\\Causa':
						var practiceAction = new CausaAction()
						dispatch(practiceAction.load(response.data.data.recordable_id))
					break
				}
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
  }
  
  update( id, postData, ownProps ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.update(id, postData)
			.then( response => {
				dispatch({
					type: this.consts.UPDATED,
					item: response.data.data,
        });
        dispatch(this.search({
          recordable_id: {
            key: 'recordable_id',
            compare: '=',
            value: response.data.data.recordable_id
          },
          recordable_type: {
            key: 'recordable_type',
            compare: '=',
            value: response.data.data.recordable_type
          }
        }))
        dispatch(this.setItem({
          id: 0,
          recordable_id: response.data.data.recordable_id,
          recordable_type: response.data.data.recordable_type,
          date_at: null,
					description: ""
				}))
				dispatch(PageLoaderActions.hide());

				switch( response.data.data.recordable_type ){
					case 'Goomlab\\LawyerOne\\Models\\Contravvenzione':
						var practiceAction = new ContravvenzioneAction()
						dispatch(practiceAction.load(response.data.data.recordable_id))
					break
					case 'Goomlab\\LawyerOne\\Models\\Causa':
						var practiceAction = new CausaAction()
						dispatch(practiceAction.load(response.data.data.recordable_id))
					break
				}
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.meta.message));
			})
			.catch( error => {
				dispatch(PageLoaderActions.hide());
				if( error.response && error.response.data && error.response.data.meta )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.meta.message));
				else if( error.response && error.response.data && error.response.data.message )
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				else
					dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.status));
			});
		}
  }
  
  removeOnList( id, searchParams, searchCount ) {
		return (dispatch) => {
			dispatch(PageLoaderActions.show());
			this.service.remove(id)
			.then( response => {
				dispatch({
					type: this.consts.REMOVED,
					// item: response.data.item
				});
				dispatch(this.search(searchParams, searchCount));
				dispatch(AlertMessageActions.alertMessageSuccess(response.data.message));
				dispatch(PageLoaderActions.hide());
			})
			.catch( error => {
				dispatch(AlertMessageActions.alertMessageError('Errore: ' + error.response.data.message));
				dispatch(PageLoaderActions.hide());
			});
		}
	}
}