import BaseAction from 'packages/Base/actions/BaseAction';
import PermissionService from '../services/PermissionService';
// import AlertMessageActions from 'actions/AlertMessageActions';
// import PageLoaderActions from 'actions/PageLoaderActions';

export const permissionConsts = {
	RESET: 'PERMISSION_RESET',
	SET_SEARCH: 'PERMISSION_SET_SEARCH',
	SEARCHED: 'PERMISSION_SEARCH',
	LOADED: 'PERMISSION_LOADED',
	CREATED: 'PERMISSION_CREATED',
	UPDATED: 'PERMISSION_UPDATED',
	FIELDS_UPDATED: 'PERMISSION_FIELDS_UPDATED',
	REMOVED: 'PERMISSION_REMOVED',
	LIST_ERROR: 'PERMISSION_LIST_ERROR',
	ITEM_ERROR: 'PERMISSION_ITEM_ERROR',
}

export default class PermissionAction extends BaseAction {

	constructor() {
		super();
		this.service = new PermissionService();
		this.consts = permissionConsts;
		this.redirect = `/user/permissions`; //${process.env.REACT_APP_URL}
  }
  
}