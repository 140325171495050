import { customerConsts as consts} from '../actions/CustomerAction';

let initialState = {
  items: [],
  totItems: 0,
  filter: {
    id: {
      key: 'id',
      compare: '=',
      value: ''
    },
    type: {
      key: 'type',
      compare: '=',
      value: ''
    },
    referent: {
      key: 'referent',
      compare: 'like',
      value: ''
    },
    company: {
      key: 'company',
      compare: 'like',
      value: ''
    },
    city: {
      key: 'city',
      compare: 'like',
      value: ''
    },
    state: {
      key: 'state',
      compare: '=',
      value: ''
    },
    owner_user_id: {
      key: 'owner_user_id',
      compare: '=',
      value: ''
    },
    order: ['id', 'desc'],
    page: 1,
    num_per_page: 25,
  },
  item: {
    id: 0,
    type: "",
    referent: "",
    company: "",
    taxcode: "",
    vat: "",
    country: "Italia",
    state: "",
    zipcode: "",
    city: "",
    address: "",
    phone: "",
    mobile_phone: "",
  },
}

const CustomerReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case consts.INITIALIZE:
      const defaultSelectValue = {
        text: "-",
        id: ""
      }

      return {
        ...state,
        types: action.types,
        customers: action.customers,
        states: action.states
      };
    case consts.RESET:
      return {
        ...state,
        item: initialState.item,
      };
    case consts.SET_SEARCH:
      return {
        ...state,
        filter: action.filter
      };
    case consts.SEARCHED:
      return {
        ...state,
        items: action.items,
        totItems: action.totItems
      };
    case consts.LOADING:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case consts.LOADED:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case consts.CREATED:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case consts.UPDATED:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case consts.CREATED_CUSTOMER:
      return {
        ...state,
        item: action.item,
        mainTab: action.nextStep,
        loading: false
      };
    case consts.UPDATED_CUSTOMER:
      return {
        ...state,
        item: action.item,
        mainTab: action.nextStep,
        loading: false
      };
    case consts.FIELDS_UPDATED:
      return {
        ...state,
        items: action.items,
      };
    case consts.REMOVED:
      return {
        ...state,
        item: {},
      };
    default:
      return state
  }
}

export default CustomerReducer;
